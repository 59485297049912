import { toaster } from 'baseui/toast';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import { AGGREGATED_BROADCASTS, ENGAGEMENT_EXPORTS } from 'shared/consts/broadcast/constants';
import { DOWNLOAD_ERROR_STATUS } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { downloadCSVFile, isValidResponseObject, LOG_STATUS } from 'shared/helpers';

import { i18nHelper } from 'i18nHelper';

import { DownloadAPI } from './api.js';

export interface IDownloadDataProps {
	fileName: string;
	fromDate?: string;
	pageNo?: number;
	pageSize?: number;
	toDate?: string;
	workFlow: string;
	botUserId?: string | number;
	userBotRef: number;
	rowId?: number;
	columns?: IObjProps;
	searchTerm?: string;
	solrQuery?: string;
	conversationId?: string;
	messagedBusiness?: boolean;
	postInteraction?: boolean;
	exportTimeDuration?: number;
	customerId?: number;
	platform?: string;
	date?: string;
	categories?: number[];
	channels?: string[];
	agentAnalyticsWorkflow?: string;
	feedbackReportWorkflow?: string;
}

export const downloadDataAction =
	(downloadFields: IDownloadDataProps, successCb?: any, errorCb?: any) => (dispatch: any) => {
		const {
			fileName,
			fromDate,
			pageNo,
			pageSize,
			toDate,
			workFlow,
			botUserId,
			userBotRef,
			rowId,
			columns,
			searchTerm,
			solrQuery,
			conversationId,
			messagedBusiness,
			postInteraction,
			exportTimeDuration,
			customerId,
			platform,
			date,
			categories,
			channels,
			agentAnalyticsWorkflow,
			feedbackReportWorkflow,
		} = downloadFields;
		const defaultPage = 0;
		const defaultPageSize = 500;

		const data: IObjProps = {
			workflow: workFlow,
			pageNo: pageNo || defaultPage,
			pageSize: pageSize || defaultPageSize,
			fromDate: fromDate || '',
			toDate: toDate || '',
			rowId: rowId || null,
			fileName,
			userBotRef,
			conversationId: conversationId || null,
		};

		if (botUserId) {
			data.botUserId = botUserId;
		}
		if (customerId) {
			data.customerId = customerId;
		}

		if (workFlow === 'CONNECT_USERS') {
			data.columns = columns;
			data.searchTerm = searchTerm;
			data.solrQuery = solrQuery;
		}
		if (workFlow === 'CUSTOM_AUDIENCE') {
			data.columns = columns;
			data.messagedBusiness = messagedBusiness;
			data.postInteraction = postInteraction;
			data.solrQuery = solrQuery;
			data.exportTimeDuration = exportTimeDuration;
			data.searchTerm = '';
		}
		if (workFlow === 'USER_USAGE_HISTORY') {
			data.platform = platform;
		}
		if (workFlow === 'MONTHLY_ACTIVE_USERS') {
			data.date = date;
		}
		if (workFlow === 'AGENT_ANALYTICS_EXPORT') {
			data.categories = categories;
			data.channels = channels;
			data.agentAnalyticsWorkflow = agentAnalyticsWorkflow;
		}
		if (workFlow === 'FEEDBACK_REPORT') {
			data.feedbackReportWorkflow = feedbackReportWorkflow;
		}

		DownloadAPI.downloadData(data).then((response: any) => {
			if (
				response.data &&
				response.data.status &&
				response.data.status.code === 1000 &&
				response.data.responseObject
			) {
				const rowKey = response.data.responseObject;
				let fileNameValue = fileName;
				fileNameValue = fileNameValue.concat('.gz');

				dispatch(pollForExportStatusAction(userBotRef, fileNameValue, rowKey));
				successCb && successCb();
			} else {
				errorCb && errorCb();
			}
		});
	};

export const pollForExportStatusAction = (botRef: number, fileName: string, rowKey: string) => (dispatch: any) => {
	let count = 1;
	let isDownloadTriggered = false;
	const params = {
		row_key: rowKey,
		bot_ref: botRef,
	};

	const interval = setInterval(() => {
		if (count > 10) {
			clearInterval(interval);
		}

		DownloadAPI.downloadExportStatus(params).then((response: any) => {
			if (response.data && response.data === 'SUCCESS') {
				clearInterval(interval);
				if (!isDownloadTriggered) {
					isDownloadTriggered = true;
					dispatch(downloadDataCSVAction(botRef, fileName, rowKey));
				}
			} else if (response.data && response.data === 'DATA_NOT_FOUND') {
				toaster.negative(
					<ENGTToasterContainer
						title={i18nHelper('pages:accountSettings.usageDetailsPage.usageHistory.dataNotFound.title')}
						description={i18nHelper(
							'pages:accountSettings.usageDetailsPage.usageHistory.dataNotFound.description'
						)}
					/>,
					{}
				);
				clearInterval(interval);
			} else if (DOWNLOAD_ERROR_STATUS.indexOf(response.data.status) > -1) {
				clearInterval(interval);
			}
			count++;
		});
	}, 5000);
};

export const addFileNoToFileName = (fileName: string, fileNo: number) => {
	let baseName;
	let ext;
	const dotPos = fileName.indexOf('.');
	if (dotPos !== -1) {
		baseName = fileName.substr(0, dotPos);
		ext = fileName.substr(dotPos, fileName.length);
	} else {
		baseName = fileName;
		ext = '';
	}

	return `${baseName}_${fileNo + 1}${ext}`;
};

export const downloadDataCSVAction = (botRef: number, fileName: string, rowKey: string, fileCount?: number) => () => {
	const params: IObjProps = {
		row_key: rowKey,
		type: 'EXPORT',
		bot_ref: botRef,
	};

	if (fileCount) {
		for (let fileNo = 0; fileNo < fileCount; fileNo++) {
			params.file_no = fileNo;
			DownloadAPI.downloadDataCSV(params).then((response: any) => {
				fileName = addFileNoToFileName(fileName, fileNo);
				const csv = response.data;
				if (csv) {
					downloadCSVFile(csv, fileName);
				}
			});
		}
	} else {
		DownloadAPI.downloadDataCSV(params).then((response: any) => {
			const csv = response.data;
			if (csv) {
				downloadCSVFile(csv, fileName);
			}
		});
	}
};

export const downloadUploadedDataCSVAction = (fileName: string, path: string, mode: string) => () => {
	const params: IObjProps = {
		outputFilePath: path,
		uploadMode: mode,
	};
	DownloadAPI.downloadUploadedData(params).then((response: any) => {
		const csv = response.data;
		if (csv) {
			downloadCSVFile(csv, fileName);
		}
	});
};

export const downloadCampaignUsersDataAction = (payload: any, successCb?: any, errorCb?: any) => (dispatch: any) => {
	DownloadAPI.exportCampaignUsersData(payload).then((response: any) => {
		if (isValidResponseObject(response)) {
			const rowId = response.data.responseObject;
			let fileNameValue = payload.fileName;
			fileNameValue = fileNameValue.concat('.gz');
			dispatch(pollForExportCampaignStatusAction(payload.campaignId, payload.botRef, rowId, fileNameValue));
			successCb && successCb();
		} else {
			errorCb && errorCb();
		}
	});
};

export const downloadBroadcastUsersDataForV2Action =
	(data: IObjProps, successCb?: Function, errorCb?: Function) => (dispatch: any) => {
		DownloadAPI.downloadBroadcastUsersDataV2(data).then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				const rowId = response.data.responseObject;
				let fileNameValue = data.fileName;
				fileNameValue = fileNameValue.concat('.gz');
				dispatch(
					pollForExportBroadcastTargetStatusAction(
						data.broadcastId,
						data.botRef,
						rowId,
						fileNameValue,
						0,
						'',
						data.broadcastVersion
					)
				);
				successCb && successCb();
			} else {
				errorCb && errorCb();
			}
		});
	};

export const downloadBroadcastTargetUsersDataAction =
	(data: IObjProps, successCb?: Function, errorCb?: Function) => (dispatch: any) => {
		DownloadAPI.downloadBroadcastTargetUsers(data).then((response: IObjProps) => {
			if (isValidResponseObject(response)) {
				const rowId = response.data.responseObject;
				let fileNameValue = data.fileName;
				fileNameValue = fileNameValue.concat('.gz');
				dispatch(
					pollForExportBroadcastTargetStatusAction(
						data.broadcastId,
						data.botRef,
						rowId,
						fileNameValue,
						0,
						'',
						data.broadcastVersion
					)
				);
				successCb && successCb();
			} else {
				errorCb && errorCb();
			}
		});
	};

export const pollForExportBroadcastTargetStatusAction =
	(
		broadcastId: string,
		botRef: number,
		rowId: number,
		fileName: string,
		fileCount: number,
		workflow: string,
		broadcastVersion = 'V1'
	) =>
	(dispatch: any) => {
		let count = 1;
		let isDownloadTriggered = false;
		const params: IObjProps = {
			targetApi: broadcastVersion === 'V2' ? 'getBroadcastExportStatusV2' : 'getBroadcastExportStatus',
			rowId,
			...(broadcastVersion === 'V2' && { replaceable_params: { broadcastId } }),
			...(AGGREGATED_BROADCASTS.includes(workflow) && { isAggregatedAnalytics: 'true' }),
		};
		const interval = setInterval(() => {
			if (count > 10) {
				toaster.negative(
					<ENGTToasterContainer
						title={i18nHelper('pages:broadcast.exportHistoryToaster.heading')}
						description={i18nHelper('pages:broadcast.exportHistoryToaster.description')}
					/>,
					{}
				);
				clearInterval(interval);
			}

			DownloadAPI.downloadBroadcastExportStatus(params).then((response: any) => {
				if (isValidResponseObject(response) && response.data.responseObject === 'SUCCESS') {
					clearInterval(interval);
					if (AGGREGATED_BROADCASTS.includes(workflow)) {
						dispatch(
							downloadAggregatedBroadcastUsersDataCSVAction(botRef, workflow, rowId, fileName, fileCount)
						);
					} else if (ENGAGEMENT_EXPORTS.includes(workflow)) {
						dispatch(
							downloadAggregatedBroadcastUsersDataCSVAction(botRef, workflow, rowId, fileName, fileCount)
						);
					} else if (!isDownloadTriggered) {
						isDownloadTriggered = true;
						dispatch(
							downloadBroadcastTargetUsersDataCsvAction(
								broadcastId,
								botRef,
								rowId,
								fileName,
								broadcastVersion
							)
						);
					}
				} else if (DOWNLOAD_ERROR_STATUS.indexOf(response.data.responseObject) > -1) {
					clearInterval(interval);
				}
				count++;
			});
		}, 5000);
	};

export const pollForExportCampaignStatusAction =
	(campaignId: number, botRef: number, rowId: number, fileName: string) => (dispatch: any) => {
		let count = 1;
		let isDownloadTriggered = false;
		const params: IObjProps = {
			targetApi: 'getCampaignExportStatus',
			rowId,
			replaceable_params: {
				campaignId,
			},
		};

		const interval = setInterval(() => {
			if (count > 10) {
				clearInterval(interval);
			}

			DownloadAPI.downloadCampaignExportStatus(params).then((response: any) => {
				if (isValidResponseObject(response) && response.data.responseObject === 'SUCCESS') {
					clearInterval(interval);
					if (!isDownloadTriggered) {
						isDownloadTriggered = true;
						dispatch(downloadCampaignUsersDataCsvAction(campaignId, botRef, rowId, fileName));
					}
				} else if (DOWNLOAD_ERROR_STATUS.indexOf(response.data.responseObject) > -1) {
					clearInterval(interval);
				}
				count++;
			});
		}, 5000);
	};

export const downloadBroadcastTargetUsersDataCsvAction =
	(broadcastId: string, botRef: number, rowId: number, fileName: string, broadcastVersion: string) => () => {
		const params: IObjProps = {
			row_key: rowId,
			broadcast_id: broadcastId,
			bot_ref: botRef,
			broadcast_version: broadcastVersion,
		};
		DownloadAPI.downloadDataCSV(params).then((response: any) => {
			const csv = response.data;
			if (csv) {
				downloadCSVFile(csv, fileName);
			}
		});
	};

export const downloadAggregatedBroadcastUsersDataCSVAction =
	(botRef: number, workflow: string, rowKey: number, fileName: string, fileCount: number) => () => {
		const type = ENGAGEMENT_EXPORTS.includes(workflow) ? workflow : 'AGGREGATED_BROADCAST';

		const params: IObjProps = {
			row_key: rowKey,
			type,
			bot_ref: botRef,
		};

		if (fileCount > 1) {
			for (let fileNo = 0; fileNo < fileCount; fileNo++) {
				params.file_no = fileNo;
				DownloadAPI.downloadDataCSV(params).then((response: any) => {
					const csv = response.data;
					if (csv) {
						downloadCSVFile(csv, `${fileName}_${fileNo}.csv.gz`);
					}
				});
			}
		} else {
			DownloadAPI.downloadDataCSV(params).then((response: any) => {
				const csv = response.data;
				if (csv) {
					if (fileName.includes('.gz')) {
						downloadCSVFile(csv, `${fileName}`);
					} else {
						downloadCSVFile(csv, `${fileName}.csv.gz`);
					}
				}
			});
		}
	};

export const downloadCampaignUsersDataCsvAction =
	(campaignId: number, botRef: number, rowId: number, fileName: string) => () => {
		const params: IObjProps = {
			row_key: rowId,
			campaign_id: campaignId,
			bot_ref: botRef,
		};
		DownloadAPI.downloadDataCSV(params).then((response: any) => {
			const csv = response.data;
			if (csv) {
				downloadCSVFile(csv, fileName);
			}
		});
	};

export const pollForExportCreditHistoryAction = (rowId: number) => (dispatch: any) => {
	let count = 1;
	let isDownloadTriggered = false;
	const params: IObjProps = {
		targetApi: 'getCreditExportHistoryStatus',
		rowId,
	};

	const interval = setInterval(() => {
		if (count > 10) {
			clearInterval(interval);
		}

		DownloadAPI.getCreditExportHistoryStatus(params).then((response: any) => {
			if (isValidResponseObject(response) && response.data.responseObject.status === 'SUCCESS') {
				clearInterval(interval);
				if (!isDownloadTriggered) {
					isDownloadTriggered = true;
					dispatch(
						downloadCSVAction(
							response.data.responseObject.fileToken,
							response.data.responseObject.fileName,
							response.data.responseObject.customerId
						)
					);
				}
			} else if (DOWNLOAD_ERROR_STATUS.indexOf(response.data.responseObject) > -1) {
				clearInterval(interval);
			}
			count++;
		});
	}, 5000);
};

const errorToaster = (errorStatus: string) => {
	if (errorStatus === 'PROCESSING_ERROR') {
		toaster.negative(
			<ENGTToasterContainer
				title={i18nHelper('pages:analytics.livechat.summary.processingError.title')}
				description={i18nHelper('pages:analytics.livechat.summary.processingError.description')}
			/>,
			{}
		);
	} else if (errorStatus === 'DATA_NOT_FOUND') {
		toaster.negative(
			<ENGTToasterContainer
				title={i18nHelper('pages:analytics.livechat.summary.dataNotFound.title')}
				description={i18nHelper('pages:analytics.livechat.summary.dataNotFound.description')}
			/>,
			{}
		);
	} else if (errorStatus === 'UPLOAD_ERROR') {
		toaster.negative(
			<ENGTToasterContainer
				title={i18nHelper('pages:analytics.livechat.summary.uploadError.title')}
				description={i18nHelper('pages:analytics.livechat.summary.uploadError.description')}
			/>,
			{}
		);
	}
};

export const pollForConversationReportHistoryAction = (rowId: number) => (dispatch: any) => {
	let count = 1;
	const isDownloadTriggered = Array(10).fill(false); // considering the max file count as 10
	const params: IObjProps = {
		targetApi: 'getConversationReportHistoryStatus',
		rowId,
	};

	const interval = setInterval(() => {
		if (count > 10) {
			clearInterval(interval);
		}

		DownloadAPI.getConversationReportHistoryStatus(params).then((response: any) => {
			if (isValidResponseObject(response) && response.data.responseObject.status === 'SUCCESS') {
				const fileTokenString = response.data.responseObject?.fileToken;
				const fileTokenArray = fileTokenString?.split(',');
				const fileCount = fileTokenArray.length;
				fileTokenArray.forEach((fileToken: any, fileNo: any) => {
					if (!isDownloadTriggered[fileNo]) {
						isDownloadTriggered[fileNo] = true;
						dispatch(
							downloadCSVAction(
								fileToken,
								response.data.responseObject.fileName,
								response.data.responseObject.customerId
							)
						);
					}
				});
			} else if (DOWNLOAD_ERROR_STATUS.indexOf(response.data.responseObject?.status) > -1) {
				errorToaster(response.data.responseObject.status);
			}

			count++;
		});
	}, 5000);
};

export const getAggregatedAnalyticsAction = (rowId: number) => (dispatch: any) => {
	let count = 1;
	let isDownloadTriggered = false;
	const params: IObjProps = {
		targetApi: 'aggregatedAnalyticsExport',
		replaceable_params: {
			rowId,
		},
	};

	const timer = 5000;
	const maxcount = 10;

	const interval = setInterval(() => {
		if (count > maxcount) {
			clearInterval(interval);
		}

		DownloadAPI.getAggregatedAnalyticsExport(params).then((response: any) => {
			if (isValidResponseObject(response)) {
				const { fileToken, fileName, customerId, status } = response.data.responseObject;
				if (!isDownloadTriggered && status === LOG_STATUS.SUCCESS) {
					isDownloadTriggered = true;
					downloadCSVAction(fileToken, fileName, customerId);
					clearInterval(interval);
				}
			} else if (DOWNLOAD_ERROR_STATUS.indexOf(response.data.responseObject) > -1) {
				clearInterval(interval);
			}
			count++;
		});
	}, timer);
};

const downloadCSVAction = (token: string, fileName: string, customerId: number) => {
	token &&
		DownloadAPI.downloadCSVFile({
			token,
			customerId,
		}).then((response: any) => {
			if (response.data) {
				downloadCSVFile(response.data, fileName);
			}
		});
};

// export option hidden [NOT COMPLETE]
export const downloadUsageHistoryCsvAction =
	(
		// broadcastId: string,
		botRef: number,
		rowId: number,
		fileName: string
	) =>
	() => {
		const params: IObjProps = {
			row_key: rowId,
			// broadcast_id: broadcastId,
			bot_ref: botRef,
		};
		DownloadAPI.downloadDataCSV(params).then((response: any) => {
			const csv = response.data;
			if (csv) {
				downloadCSVFile(csv, fileName);
			}
		});
	};

// export option hidden [NOT COMPLETE]
export const pollForExportUsageHistoryStatusAction =
	(
		// broadcastId: string,
		botRef: number,
		rowId: number,
		fileName: string
	) =>
	(dispatch: any) => {
		const count = 1;
		const isDownloadTriggered = false;
		const params: IObjProps = {
			targetApi: 'getUsageHistoryExportStatus',
			rowId,
			// replaceable_params: {
			// 	broadcastId: broadcastId,
			// },
		};
	};
