import {
	differenceInCalendarDays,
	differenceInDays,
	differenceInHours,
	differenceInMinutes,
	differenceInSeconds,
	differenceInWeeks,
	format as dateFormatter,
} from 'date-fns';
import { default as i18n, default as i18next } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { REACT_APP_SUB_DOMAIN } from 'shared/config/config';
import { isValidDate } from 'shared/helpers';

import { serialize } from './shared/helpers';

const isDevEnvironment: boolean = REACT_APP_SUB_DOMAIN === 'dev';
let locale: any = 'en-US';
let timezone: any = 'Asia/Kolkata';
let timezoneOffset: any;

const getTimeZone = () => {
	const offset = new Date().getTimezoneOffset();

	const o = Math.abs(offset);

	return `UTC${offset < 0 ? '+' : '-'}${`00${Math.floor(o / 60)}`.slice(-2)}:${`00${o % 60}`.slice(-2)}`;
};

export const setLanguageForApp = (resp: any) => {
	if (resp.data) {
		locale = resp.data.locale;

		if (!resp.data.timezone || resp.data.timezone === 'UTC') {
			timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			timezoneOffset = getTimeZone().trim();
			const data = {
				userLocale: locale,
				timezone,
				timezoneOffset,
			};
			const params = {
				targetApi: 'userProfiles',
				customerId: '{{customerId}}',
				userId: '{{userId}}',
			};
			try {
				fetch(`/portal/v2/genericProxyRequest/${serialize(params, '?')}`, {
					method: 'PUT',
					body: JSON.stringify(data),
					headers: {
						'Content-Type': 'application/json;charset=UTF-8',
						'X-CSRFToken': localStorage.getItem('csrfToken') || '',
					},
				});
			} catch (e) {
				console.error('Error when executing userProfiles api in i18n', e);
			}
		} else {
			timezone = resp.data.timezone;
		}

		i18n.changeLanguage(locale);
	}
};

export const formatDateToLongDate = (date: any) => {
	const format = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hourCycle: 'h23',
	};

	return date.toLocaleDateString('en-US', format);
};

export const formatDateToLongDateAndTime = (date: any) => {
	const format = {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hourCycle: 'h23',
	};

	return `${dateFormatter(date, 'do MMM yyyy, HH:mm')}hrs`;
};

const language = i18next.language || 'en-US';

i18n.use(Backend) // load translation using http -> see /public/locales
	.use(initReactI18next) // pass the i18n instance to react
	.init({
		// init i18next
		lng: language,
		ns: ['pages', 'components', 'common', 'errors', 'shopify'],
		fallbackLng: 'en',
		debug: isDevEnvironment,
		whitelist: ['en', 'pt', 'ar'],
		keySeparator: '.',
		backend: {
			loadPath: `${process.env.I18N_HOST}/locales/{{lng}}/{{ns}}.json`,
		},
		returnNull: false,
		nonExplicitSupportedLngs: true,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
			format(value, format, lng) {
				if (format === 'uppercase') {
					return value.toUpperCase();
				}

				if (format === 'messageListLastActiveDateFormatter') {
					const currentDate = new Date();
					const givenDate = new Date(value);
					if (differenceInCalendarDays(currentDate, givenDate) === 0) {
						const date = new Date(value);

						return `${date.toLocaleString(locale, {
							hour: '2-digit',
							minute: '2-digit',
							hourCycle: 'h23',
							timeZone: timezone,
						})}`;
					}
					if (currentDate.getFullYear() === givenDate.getFullYear()) {
						return `${new Intl.DateTimeFormat(locale, {
							month: 'short',
							day: 'numeric',
							timeZone: timezone,
						}).format(new Date(value))}`;
					}

					return `${new Intl.DateTimeFormat(locale, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: timezone,
					}).format(new Date(value))}`;
				}

				if (format === 'messageHeaderLastActiveDateFormatter') {
					if (!isValidDate(value)) {
						return '-';
					}

					const currentDate = new Date();
					const givenDate = new Date(value);

					if (differenceInCalendarDays(currentDate, givenDate) === 0) {
						if (differenceInHours(currentDate, givenDate) === 0) {
							if (differenceInMinutes(currentDate, givenDate) === 0) {
								return 'Few seconds ago';
							}
							if (differenceInMinutes(currentDate, givenDate) === 1) {
								return '1 minute ago';
							}

							return `${differenceInMinutes(currentDate, givenDate)} mins ago`;
						}

						return `${differenceInHours(currentDate, givenDate)} hours ago`;
					}
					if (differenceInCalendarDays(currentDate, givenDate) === 1) {
						return 'Yesterday';
					}
					if (currentDate.getFullYear() === givenDate.getFullYear()) {
						return `${new Intl.DateTimeFormat(locale, {
							month: 'short',
							day: 'numeric',
							timeZone: timezone,
						}).format(new Date(value))}`;
					}

					return `${new Intl.DateTimeFormat(locale, {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
						timeZone: timezone,
					}).format(new Date(value))}`;
				}
				if (format === 'standardDateTimeFormatter') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${new Intl.DateTimeFormat(locale, {
						day: 'numeric',
						month: 'numeric',
						year: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
						timeZone: timezone,
					}).format(date)}`;
				}
				if (format === 'standardTimeFormatter') {
					const valueMod = typeof value === 'string' ? value.replace('+0000', 'Z') : value;
					const date = new Date(valueMod);

					return new Intl.DateTimeFormat(locale, {
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
						timeZone: timezone,
					}).format(date);
				}
				if (format === 'standardDateTimeFormatterWithMonthName') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${new Intl.DateTimeFormat(locale, {
						month: 'long',
						day: 'numeric',
						year: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
						timeZone: timezone,
					}).format(date)}`.replace('at', ',');
				}
				if (format === 'standardDateTimeFormatterWithoutYearAndSecond') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${new Intl.DateTimeFormat(locale, {
						month: 'short',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						timeZone: timezone,
					}).format(date)}`;
				}
				if (format === 'standardDateTimeFormatterWithoutSeconds') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${new Intl.DateTimeFormat(locale, {
						day: 'numeric',
						month: 'numeric',
						year: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						timeZone: timezone,
					}).format(date)}`;
				}
				if (format === 'standardDateWithoutYearFormatter') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${new Intl.DateTimeFormat(locale, {
						month: 'short',
						day: 'numeric',
						timeZone: timezone,
					}).format(date)}`;
				}
				if (format === 'standardDateWithoutYearFormatterWithMonthAheadOfDay') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${new Intl.DateTimeFormat('en-US', {
						month: 'short',
						day: 'numeric',
						timeZone: timezone,
					}).format(date)}`;
				}
				if (format === 'dateTimeInInitialsFormatter') {
					const currentDate = new Date();
					const givenDate = new Date(value);

					if (differenceInWeeks(currentDate, givenDate) > 0) {
						return `${differenceInWeeks(currentDate, givenDate)}w`;
					}
					if (differenceInDays(currentDate, givenDate) > 0) {
						return `${differenceInDays(currentDate, givenDate)}d`;
					}
					if (differenceInHours(currentDate, givenDate) > 0) {
						return `${differenceInHours(currentDate, givenDate)}h`;
					}
					if (differenceInMinutes(currentDate, givenDate) > 0) {
						return `${differenceInMinutes(currentDate, givenDate)}m`;
					}

					return `${differenceInSeconds(currentDate, givenDate)}s`;
				}
				if (format === 'dateTimeWithYearAsWholeAndOthersInTwoDigits') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return dateFormatter(date, 'yyyy-MM-dd HH:mm');
				}
				if (format === 'standardDateWithYearFormatter') {
					const date = new Date(value);

					return `${new Intl.DateTimeFormat(locale, {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
						timeZone: timezone,
					}).format(date)}`;
				}
				if (format === 'standardDateTimeFormatterWithShortMonthName') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${new Intl.DateTimeFormat(locale, {
						month: 'short',
						day: 'numeric',
						year: 'numeric',
						hour: 'numeric',
						minute: 'numeric',
						timeZone: timezone,
					}).format(date)}`.replace('at', ',');
				}
				if (format === 'standardDateTimeFormatterWithShortMonthNameAndTimeAheadOfDate') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${dateFormatter(date, 'HH:mm')} hrs, ${dateFormatter(date, 'd MMM yyyy')}`;
				}
				if (format === 'standardDateFormatterWithShortMonthName') {
					const valueMod = typeof value === 'string' ? value?.replace?.('+0000', 'Z') : value; // support for older safari version
					const date = new Date(valueMod);

					return `${new Intl.DateTimeFormat(locale, {
						month: 'short',
						day: 'numeric',
						year: 'numeric',
						timeZone: timezone,
					}).format(date)}`.replace('at', ',');
				}
				if (format === 'dataTimeFormatterWithMonthName') {
					return dateFormatter(value, 'yyyy-MMMM-dd HH:mm');
				}
				if (format === 'currencyFormatter') {
					return Intl.NumberFormat(locale, {
						style: 'currency',
						currency: 'USD',
					}).format(value);
				}
				if (format === 'numberFormatter') {
					return Intl.NumberFormat(locale).format(value);
				}
				if (format === 'dateFormatter') {
					const date = new Date(value);

					return `${new Intl.DateTimeFormat(locale, {
						hour: 'numeric',
						minute: 'numeric',
						second: 'numeric',
						timeZone: timezone,
					}).format(date)}`;
				}
				if (format === 'standardDateFormatter') {
					const date = new Date(value);

					return `${new Intl.DateTimeFormat(locale, {
						day: 'numeric',
						month: 'numeric',
						year: 'numeric',
						timeZone: timezone,
					}).format(date)}`;
				}
				if (format === 'dateWithSuffixMonthYearFormatter') {
					// Ex: 21st/23rd/25th March 2024
					return dateFormatter(value, 'do MMMM yyyy');
				}

				return value;
			},
		},
	});

export default i18n;
