import DOMPurify, { Config } from 'dompurify';
import { decode } from 'he';
import { parseDigits } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import { REACT_APP_SUB_DOMAIN } from 'shared/config/config';
import {
	ALL_AVAILABLE_PLANS,
	AVATAR_BG_COLORS,
	CHANNEL_TITLE_MAPPING,
	DAYS_TO_MILLISECONDS,
	DEFAULT_LANGUAGE_CODE,
	ENGATI_RESPONSE_CODE,
	ENGATI_ROLES,
	FAVICON_APPLE_TOUCH_SIZES,
	FAVICON_SIZES,
	INTERNAL_TESTING_UTM_CAMPAIGN,
	LLM_FEATURE_TYPES,
	MONTHS,
	NON_ENGLISH_LANGUAGE_CANVAS_CARD_TEXT_MAX_LENGTH,
	PASSWORD_MAXIMUM_CHARACTERS,
} from 'shared/consts/consts';
import { IObjProps, ISelectValue } from 'shared/consts/types';

import { ShopifyOnboardingRoutes } from 'router/EmptyPageRoutes';
import { NoAuthenticationRouteLinks } from 'router/links/NoAuthenticationRouteLinks';
import { NoColumnRouteLinks } from 'router/NoColumnRoutes';

import { deleteFromLs, LS_KEYS, LsKeysMapping, readFromLs } from './consts/localStorageConsts';
import { DEFAULT_LAST_MESSAGE_FILTER_IDS, ENVIRONMENT_TAGS } from './enum';

if (!String.prototype.replaceAll) {
	// eslint-disable-next-line no-extend-native
	String.prototype.replaceAll = function (regex: any, toReplace: any) {
		let isValid = true;

		if (!this) {
			return '';
		}

		try {
			new RegExp(regex);
		} catch (e) {
			isValid = false;
		}

		if (!isValid) {
			return this.split(regex).join(toReplace);
		}

		return this.replace(regex, toReplace);
	};
}

(() => {
	document.addEventListener('wheel', function (event) {
		if ((document as any).activeElement?.type === 'number') {
			(document as any).activeElement.blur?.();
		}
	});
})();

/**
 * eg: https://some.com?param1=val1&param2=val2 -> new URLSearchParam Obj
 * @returns params string from the current location url
 */
export const getUrlParams = (): URLSearchParams => {
	const url = window.location.href;
	const params = url.split('?');

	return params && params[1] ? new URLSearchParams(params[1]) : new URLSearchParams();
};

export const getMaskedPhoneNumber = function (value: string) {
	const formattedVal = value?.replace(/[^A-Z0-9]/gi, '');
	if (/^(\d+){8,}$/.test(formattedVal)) {
		return `*********${formattedVal.slice(formattedVal.length - 3)}`;
	}

	return value;
};

export const addToObject = function (obj: IObjProps, key: string, value: any, index: number) {
	const temp: IObjProps = {};
	let i = 0;

	for (const prop in obj) {
		if (obj.hasOwnProperty(prop)) {
			if (i === index && key && value) {
				temp[key] = value;
			}
			temp[prop] = obj[prop];
			i++;
		}
	}
	if (!index && key && value) {
		temp[key] = value;
	}

	return temp;
};

export const borderRadius = (width?: string) => ({
	borderTopRightRadius: width,
	borderTopLeftRadius: width,
	borderBottomRightRadius: width,
	borderBottomLeftRadius: width,
});

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @returns string
 */
export const capitalize = (str: string, lower = false): string =>
	(lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

export const clearLsOnLogout = (botId?: any, uid?: any) => {
	// update this function with other items to be removed from local storage on logout
	deleteFromLs('csrfToken');
	deleteFromLs('storeDataVersion');
	deleteFromLs('storeData');
	deleteFromLs('loggedInUsercontext');
	deleteFromLs('loggedInUserAccountType');
	deleteFromLs('adminMode');
	deleteFromLs('b_ref');
	deleteFromLs('new_current_page');
	deleteFromLs('all_current_page');
	deleteFromLs('active_current_page');
	deleteFromLs('unassigned_current_page');
	deleteFromLs('allOldConv');
	deleteFromLs('trackBanner');
	deleteFromLs('trackCreditBanner');
	deleteFromLs('showMarkOnlinePrompt');
	deleteFromLs('walletBalanceEnteredLc');
	deleteFromLs(LS_KEYS.ENFORCEMENT_STATUS);
	deleteFromLs('enableOwnerSwap');

	for (const key in localStorage) {
		if (
			key.startsWith('oneviewlite') ||
			key.includes('right_panel_expanded') ||
			key.startsWith('draft_message') ||
			key.startsWith('message_search') ||
			key.startsWith('shortcutQuickFilters')
		) {
			deleteFromLs(key);
		}
	}
	botId && uid && deleteFromLs(`all_${botId}_${uid}`);
	botId && uid && deleteFromLs(`active_${botId}_${uid}`);
	botId && uid && deleteFromLs(`new_${botId}_${uid}`);
	botId && uid && deleteFromLs(`unassigned_${botId}_${uid}`);
	botId && uid && deleteFromLs(`${LS_KEYS.BROADCAST_FILTERS}${botId}_${uid}`);
	uid && deleteFromLs(`pausednotification_${uid}`);
	uid && deleteFromLs(LsKeysMapping(LS_KEYS.COUNTRY_CODE, uid));
};

export const convertBase64EncodedStringtoBlob = (data: string, sliceSize: number, contentType: string) => {
	const byteCharacters = atob(data);
	const byteArrays = [];
	for (let offset = 0, len = byteCharacters.length; offset < len; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	const blob = createBlob(byteArrays, contentType);

	return blob;
};

export function formatDateToFirstDayOfMonth(date: any) {
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const formattedMonth = String(month).padStart(2, '0');

	return `${year}-${formattedMonth}-01`;
}

export const currentMonthYear = () => {
	const currentDate = new Date();
	const defaultMonth = MONTHS[currentDate.getMonth()];
	const defaultYear = currentDate.getFullYear().toString();

	return `${defaultMonth} ${defaultYear}`;
};

export const dateFormatter = (monthFilter: string) => {
	const [month, year] = monthFilter.split(' ');
	const dateObj = new Date(`${month} 1, ${year}`);
	const convertedYear = dateObj.getFullYear();
	const convertedMonth = String(dateObj.getMonth() + 1).padStart(2, '0');

	return `${convertedYear}-${convertedMonth}-01`;
};

export function getDateSixMonthsAgo() {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();
	let startMonth = currentMonth - 6;
	let startYear = currentYear;
	if (startMonth < 0) {
		startMonth += 12;
		startYear--;
	}
	const startDate = new Date(startYear, startMonth, 1);
	const formattedStartDate = formatDateToFirstDayOfMonth(startDate);

	return formattedStartDate;
}

export const convertMilisToMinutes = (milis: string) => Math.round(parseInt(milis) / 60000);

export const convertNestedKeysToArray = (obj: any) => {
	// if obj={ 'a' : { 'x' : '', 'y' : '' }, 'b': ''} ===> [ 'a.x', 'a.y', 'b' ]
	if (typeof obj !== 'object') {
		return [obj];
	}

	let result: any = [];
	if (obj.constructor === Array) {
		obj.map((item) => {
			result = result.concat(convertNestedKeysToArray(item));

			return result;
		});
	} else {
		Object.keys(obj).map((key) => {
			if (Object.keys(obj[key]).length > 0) {
				result.push(key);

				const chunk = convertNestedKeysToArray(obj[key]);
				chunk.map(function (item: any) {
					result.push(`${key}.${item}`);

					return result;
				});
			} else {
				result.push(key);
			}

			return result;
		});
	}

	return result;
};

export const copyToClipboard = (contentToBeCopied: string) => {
	navigator.clipboard.writeText(contentToBeCopied);
};

const createBlob = (data: BlobPart[], contentType: any) =>
	new Blob(data, {
		type: contentType,
	});

export const extractTextFromHtml = (() => {
	const cache: IObjProps = {};

	return (content: string) => {
		if (!content) {
			return '';
		}

		if (cache[content] !== undefined) {
			return cache[content];
		}
		const span = document.createElement('span');
		span.innerHTML = content;

		cache[content] = span.textContent || span.innerText;

		return cache[content];
	};
})();

export const newLineCountFn = (s: any) => {
	const div = document.createElement('div');
	div.innerHTML = s;
	const count = div.childElementCount > 0 ? div.childElementCount - 1 : 0;

	return count;
};

export const debounce = <F extends (...args: any) => any>(func: F, waitFor: number) => {
	let timeout: ReturnType<typeof setTimeout>;

	const debounced = (...args: any) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => func(...args), waitFor);
	};

	return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const downloadCSVFile = (data: any, fileName: string) => {
	const blob = new Blob([data], { type: 'application/octet-stream' });
	downloadData(URL.createObjectURL(blob), fileName);
};

export const downloadData = (href: string, fileName?: string) => {
	const element = document.createElement('a');
	element.setAttribute('href', href);
	element.setAttribute('download', fileName || '');
	element.style.display = 'none';
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
};

export const emailValidation = (email: string) => {
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return emailRegex.test(email);
};
export const websiteValidation = (website: string) => {
	const websiteRegex =
		/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

	return websiteRegex.test(website);
};

export const validateStoreURL = (storeURL: string) => {
	const urlRegex = /^https?:\/\//;

	return urlRegex.test(storeURL);
};

export const lowercaseAlphanumericAndUnderscoreValidation = (templateName: string) => {
	const templateNameRegex = /^[a-z0-9_]+$/;

	return templateNameRegex.test(templateName);
};
export const mobileNumberValidation = (number: string) => {
	const pattern = RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);

	return pattern.test(number);
};
export const attributeNumberTypeValidation = (number: string) => {
	const type = RegExp(/^[1-9]\d*(\.\d+)?$/);

	return type.test(number);
};

export const errorHandler = (error: string) => {
	console.log('error log', error);
};

export const fileDownload = (
	data: any,
	filename = 'sample.xlsx',
	contentType = 'application/octet-stream',
	sliceSize = 512
) => {
	const blob = convertBase64EncodedStringtoBlob(data, sliceSize, contentType);
	downloadData(URL.createObjectURL(blob), filename);
};

export const findEnv = () => {
	const originLocation = window.location.origin;
	let environment = 'p';
	if (originLocation.indexOf('localhost') > -1) {
		environment = 'd';
	}

	if (originLocation.indexOf('dev') > -1 && originLocation.indexOf('engati') > -1) {
		environment = 'd';
	}

	if (originLocation.indexOf('qa') > -1 && originLocation.indexOf('engati') > -1) {
		environment = 'qa';
	}
	console.log('environment variable : ', environment);

	return environment;
};

export const isEmpty = (obj: IObjProps | null | undefined): boolean => (obj ? Object.keys(obj).length === 0 : true);

export const isEmptyArray = (data: Array<any>) => !data || data?.length === 0;

export const isEmptyString = (data: any) => typeof data !== 'string' || data.trim() === '';

export const isUndefined = (obj: string | number | IObjProps | null | undefined): boolean =>
	obj === undefined || obj === null;

export const initialsCanvasData = (user: any, canvasWidth?: number, canvasHeight?: number) => {
	const colors = AVATAR_BG_COLORS;
	const canvas = document.createElement('canvas');
	let name = '';
	let lastChar: any = '';
	canvas.style.display = 'none';
	canvas.width = canvasWidth || 40;
	canvas.height = canvasHeight || 40;
	document.body.appendChild(canvas);

	const context: any = canvas.getContext('2d');
	if (user.id) {
		const id = user.id.toString();
		lastChar = id[id.length - 1];
	}
	context.fillStyle = colors[Math.abs(lastChar % 5)] || '#476ce8';
	context.fillRect(0, 0, canvas.width, canvas.height);
	context.font = `${canvas.height / 3}px Arial`;
	context.fillStyle = '#FFFFFF';
	context.textAlign = 'center';
	context.textBaseline = 'middle';

	if (user.username) {
		name = user.username;
		name = name.replaceAll(/"/gi, '');
	}
	const platformList = ['whatsapp', 'nexmo', 'clickatell', 'kaleyra'];
	let initials = '';
	if (platformList.indexOf(user.platform) >= 0) {
		const { length } = name;
		initials = name[length - 2] + name[length - 1];
	} else {
		const nameArray = name.split(' ');
		for (let i = 0; i < nameArray.length; i++) {
			if (i <= 1) {
				initials += nameArray[i][0];
			}
		}
	}
	context.fillText(initials.toUpperCase(), canvas.width / 2, canvas.height / 2);
	const data = canvas.toDataURL();
	document.body.removeChild(canvas);

	return data;
};

export const setValueInSessionStorage = (sessionKey: string, sessionValue: string) => {
	sessionStorage.setItem(sessionKey, sessionValue);
};
export const getValueFromSessionStorage = (sessionKey: string) => sessionStorage.getItem(sessionKey);

export const deleteKeyFromSessionStorage = (sessionKey: string) => {
	sessionStorage.removeItem(sessionKey);
};

const VALID_IMAGE_FORMATS = ['png', 'jpg', 'jpeg'];

export const isValidImageUrl = (url: string) =>
	VALID_IMAGE_FORMATS.indexOf(
		url?.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?') > -1 ? url.lastIndexOf('?') : url.length)
	) > -1;

export const isMac = () => {
	const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
	const isIOS = !!navigator.platform.match(/(iPhone|iPod|iPad)/i);

	return isMac || isIOS;
};

export const isPlanGreaterOrEqualTo = (customerPlan: string, basePlanToCheck: string) =>
	ALL_AVAILABLE_PLANS.indexOf(basePlanToCheck) > -1 &&
	ALL_AVAILABLE_PLANS.indexOf(customerPlan) >= ALL_AVAILABLE_PLANS.indexOf(basePlanToCheck);

export const labelWithCharacterLimit = (limit: number, label: string) => `${label.substring(0, limit)} . . . `;

export const toTitleCase = (data: string) =>
	data.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});

export const replaceAll = function (target: string, search: string, replacement: string) {
	return target.split(search).join(replacement);
};

export const serialize = (obj: IObjProps, prefix?: string): string => {
	const str = [];
	let iterator;
	for (iterator in obj) {
		if (obj.hasOwnProperty(iterator) && obj[iterator] !== undefined) {
			const value = typeof obj[iterator] === 'object' ? JSON.stringify(obj[iterator]) : obj[iterator];
			str.push(`${encodeURIComponent(iterator)}=${encodeURIComponent(value)}`);
		}
	}

	return prefix ? prefix + str.join('&') : str.join('&');
};

export const throttle = (callBack: () => void, limit: number) => {
	let inThrottle: boolean;

	return function () {
		if (!inThrottle) {
			inThrottle = true;
			setTimeout(() => {
				inThrottle = false;
				callBack();
			}, limit);
		}
	};
};

const isTemplateV2Url = (buttonUrl: string) =>
	buttonUrl.includes('v2/') && buttonUrl.includes('/url') && buttonUrl.includes('/token');

export const getTemplateCTADynamicUrlSlug = (buttonUrl: string) =>
	isTemplateV2Url(buttonUrl) ? '/v2/dynamic' : '/dynamic';

export const getTemplateCTAStaticUrlSlug = (buttonUrl: string) =>
	isTemplateV2Url(buttonUrl) ? '/v2/static' : '/static';

export const getTemplateUrlBasedOnCTAVersion = (url: string) =>
	isTemplateV2Url(url)
		? url.substring(url.indexOf('url/') + 4, url.lastIndexOf('/token'))
		: url.substring(url.indexOf('=') + 1, url.lastIndexOf('&'));

export const getTemplateDynamicUrlBasedOnCTAVersion = (buttonUrl: string) =>
	buttonUrl.includes('/v2/dynamic')
		? `${buttonUrl.substring(buttonUrl.indexOf('url/') + 4, buttonUrl.lastIndexOf('/token'))}{{1}}`
		: `${buttonUrl.substring(buttonUrl.indexOf('=') + 1, buttonUrl.lastIndexOf('&'))}{{1}}`;

export const getTemplateStaticUrlBasedOnCTAVersion = (buttonUrl: string) =>
	buttonUrl.includes('/v2/static')
		? buttonUrl.substring(buttonUrl.indexOf('url/') + 4, buttonUrl.lastIndexOf('/token'))
		: buttonUrl.substring(buttonUrl.indexOf('=') + 1, buttonUrl.lastIndexOf('&'));

export const getBaseUrl = () => `${window.location.protocol}//${window.location.host}`;

export const toDisplayableDateTime = (date: Date) => {
	const data = date.toLocaleString().split(',');
	data[0] = data[0].split('/').reverse().join('-');

	return data.join('');
};

export const getEllipsisedText = (text: string, size: number) =>
	text && text.length > size ? `${text.substring(0, size)} . . . ` : text;

export const getEllipsisedTextByLocale = (text: string, defaultSize: number, locale: string) => {
	let size = defaultSize;
	if (locale && locale !== DEFAULT_LANGUAGE_CODE) {
		size = NON_ENGLISH_LANGUAGE_CANVAS_CARD_TEXT_MAX_LENGTH;
	}

	return text && text.length > size ? `${text.substring(0, size)} . . . ` : text;
};

export const countConversion = (count: any) => {
	if (count == null) {
		return '0';
	}
	if (count < 9999) {
		return Math.round(count).toString();
	}
	if (count < 1000000) {
		return `${Math.round(count / 1000)}K`;
	}
	if (count < 10000000) {
		return `${(count / 1000000).toFixed(2)}M`;
	}
	if (count < 1000000000) {
		return `${Math.round(count / 1000000)}M`;
	}
	if (count < 1000000000000) {
		return `${Math.round(count / 1000000000)}B`;
	}

	return '1T+';
};

/**
 * takes formNames like outer[0].inner[1].nested and returns the error msg for <nested> field
 * @param name form input name
 * @param errors errors object for the form
 * @param isIndex pass isIndex false if the form name is like object['key'] and true if form input name is array[index]
 * @returns err object containing the error msg for a given field
 */
export const getErrorMessage = (name: string, errors: any, isIndex: boolean) => {
	const objNames = name.split('.');
	let err = errors;
	let errorMsg = '';
	objNames.forEach((name: any) => {
		const arrNameRegex = new RegExp(/.*(?=\[)/, 'gm');
		const arrayName = arrNameRegex.exec(name)?.toString() || '';
		const indexRegex = new RegExp(/(?:\[(.*)\])/, 'gm');
		const regexMatch = indexRegex.exec(name) || [];
		const index = isIndex ? parseInt(regexMatch[1]) : regexMatch[1];
		if (arrayName) {
			err = err && err.hasOwnProperty(arrayName) ? err[arrayName][index] : err;
			errorMsg = err && err.message ? err : '';
		}
		if (!arrayName && name) {
			err = err && err.hasOwnProperty(name) ? err[name] : err;
			errorMsg = err && err.message ? err : '';
		}
	});

	return errorMsg;
};
export const uuidGenerator = () => {
	let date = new Date().getTime();

	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (date + Math.random() * 16) % 16 | 0;
		date = Math.floor(date / 16);

		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
};

export const daysToMilliSeconds = (days: number) => days * DAYS_TO_MILLISECONDS;

export const milliSecondsToDays = (milli: number) => milli / DAYS_TO_MILLISECONDS;

export const getFieldLabel = (fieldId: string, fieldOptions: any) =>
	fieldOptions.filter((option: any) => option.id === fieldId).map((option: any) => option.label)[0];

export const hexToRgb = (hex: any, opacity: any) => {
	const hexToRgba = `${hex
		.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m: any, r: any, g: any, b: any) => `#${r}${r}${g}${g}${b}${b}`)
		.substring(1)
		.match(/.{2}/g)
		.map((x: any) => parseInt(x, 16))},${opacity}`;

	return `rgba(${hexToRgba})`;
};

export const getProfileColor = (id: string): any => {
	const lastChar: string = id[id.length - 1];
	const code = lastChar.toLowerCase().charCodeAt(0) - 97 + 1;
	const colorCode = AVATAR_BG_COLORS[Math.abs(code % 5)];

	return colorCode;
};

export const getRoles = () => {
	const { t } = useTranslation(['pages', 'common']);

	const roles = [
		{ id: '1', label: t('pages:accountSettings.teamMembersPage.roles.supervisor') },
		{ id: '2', label: t('pages:accountSettings.teamMembersPage.roles.agent') },
		{ id: '3', label: t('pages:accountSettings.teamMembersPage.roles.botBuilder') },
		{ id: '4', label: t('pages:accountSettings.teamMembersPage.roles.executive') },
		{ id: '5', label: t('pages:accountSettings.teamMembersPage.roles.owner') },
	];

	return roles;
};

export const getFilterRoles = (roleSelected: Array<ISelectValue>) => {
	const roles: string[] = [];

	for (const roleId of roleSelected.map((item) => item.id)) {
		switch (roleId) {
			case '1':
				roles.push(ENGATI_ROLES.ROLE_CONNECT_SUPERVISOR);
				break;
			case '2':
				roles.push(ENGATI_ROLES.ROLE_CONNECT_AGENT);
				break;
			case '3':
				roles.push(ENGATI_ROLES.ROLE_CONNECT_BOT_BUILDER);
				break;

			case '4':
				roles.push(ENGATI_ROLES.ROLE_CONNECT_EXECUTIVE);
				break;

			case '5':
				roles.push(ENGATI_ROLES.ROLE_CONNECT_OWNER);
				break;
		}
	}

	return roles;
};

export const getSystemTheme = () => ({
	isDark() {
		return !!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')?.matches);
	},
	isLight() {
		return !!(window.matchMedia && window.matchMedia('(prefers-color-scheme: light)')?.matches);
	},
});

export const routeToSpecificLink = (url: any) => {
	const link = document.createElement('a');
	link.href = url;
	document.body.appendChild(link);
	link.target = '_blank';
	link.click();
};

/**
 * @param date in dd/MM/YYYY format
 * @returns date in utc format e.g. Thu May 27 2021 00:00:00 GMT+0530
 */
export const convertDatetoUTC = (date: string) => {
	const dateParts: any = date.split('/');
	const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

	return dateObject;
};

/**
 * Validate API Response for responseObject Type
 * @param response Api Response
 */
export const isValidResponseObject = (response: any) =>
	response &&
	response.status === 200 &&
	response.data &&
	response.data.responseObject &&
	response.data.status &&
	response.data.status.code === 1000;

/**
 * Validate API Response for response_obj Type
 * @param response Api Response
 */
export const isValidNestedResponseObj = (response: any) =>
	response &&
	response.status === 200 &&
	response.data &&
	response.data.response_obj &&
	response.data.response_obj.hasOwnProperty('responseObject') &&
	response.data.response_obj.hasOwnProperty('status');

/**
 * Validate API Response for response_obj Type
 * @param response Api Response
 */
export const isValidResponseObj = (response: any) => response && response.data && response.data.response_obj;

/**
 *
 * Validate API Response for responseObject as number Type
 * @param response Api Response
 */

export const isValidResponseObjectForNumberType = (response: any) =>
	response && response.status === 200 && response.data && response.data.status && response.data.status.code === 1000;

export const isValidResponseObjectForUserAPI = (response: any) =>
	response && response.status === 200 && response.data && response.data.status && response.data.code === 1000;

/**
 * Validate API Response for responseObject Type
 * The function differs just a bit from isValidResponseObject as
 * it has statusCode instead of status
 * @param response Api Response
 */
export const isValidResponseObjectHavingStatusCode = (response: IObjProps) =>
	response?.status === 200 && response.data?.responseObject && response.data.statusCode?.code === 1000;

export const isValidResponseObjectWithStatus = (response: IObjProps) =>
	response?.data?.status?.code === ENGATI_RESPONSE_CODE.STATUS_1000;

export const getSizeInMbOrKb = (size: number) => {
	const convertedSize = (size / (1024 * 1024)).toFixed(2);
	if (parseInt(convertedSize) === 0) {
		return `(${(size / 1024).toFixed(2)} KB)`;
	}

	return `(${convertedSize} MB)`;
};

export const getSizeInBytes = (sizeInMB: number) => sizeInMB * 1024 * 1024;

export const validPassword = (password: string) => {
	if (!password.match(/\d/)) {
		return false;
	}
	if (!password.match(/[A-Z]/)) {
		return false;
	}
	if (!password.match(/[a-z]/)) {
		return false;
	}
	if (!password.match(/^.{8,}$/)) {
		return false;
	}
	if (!password.match(/\W|_/)) {
		return false;
	}
	if (password.length > PASSWORD_MAXIMUM_CHARACTERS) {
		return false;
	}

	return true;
};

export const localeMapper = () => ({
	'ar-AE': 'Arabic (United Arab Emirates)',
	'ar-BH': 'Arabic (Bahrain)',
	'ar-DZ': 'Arabic (Algeria)',
	'ar-EG': 'Arabic (Egypt)',
	'ar-IQ': 'Arabic (Iraq)',
	'ar-JO': 'Arabic (Jordan)',
	'ar-KW': 'Arabic (Kuwait)',
	'ar-LB': 'Arabic (Lebanon)',
	'ar-LY': 'Arabic (Libya)',
	'ar-MA': 'Arabic (Morocco)',
	'ar-OM': 'Arabic (Oman)',
	'ar-QA': 'Arabic (Qatar)',
	'ar-SA': 'Arabic (Saudi Arabia)',
	'ar-SD': 'Arabic (Sudan)',
	'ar-SY': 'Arabic (Syria)',
	'ar-TN': 'Arabic (Tunisia)',
	'ar-YE': 'Arabic (Yemen)',
	'be-BY': 'Belarusian (Belarus)',
	'bg-BG': 'Bulgarian (Bulgaria)',
	'ca-ES': 'Catalan (Spain)',
	'cs-CZ': 'Czech (Czech Republic)',
	'da-DK': 'Danish (Denmark)',
	'de-AT': 'German (Austria)',
	'de-CH': 'German (Switzerland)',
	'de-DE': 'German (Germany)',
	'de-LU': 'German (Luxembourg)',
	'el-CY': 'Greek (Cyprus)',
	'el-GR': 'Greek (Greece)',
	'en-AU': 'English (Australia)',
	'en-CA': 'English (Canada)',
	'en-GB': 'English (United Kingdom)',
	'en-IE': 'English (Ireland)',
	'en-IN': 'English (India)',
	'en-MT': 'English (Malta)',
	'en-NZ': 'English (New Zealand)',
	'en-PH': 'English (Philippines)',
	'en-SG': 'English (Singapore)',
	'en-US': 'English (United States)',
	'en-ZA': 'English (South Africa)',
	'es-AR': 'Spanish (Argentina)',
	'es-BO': 'Spanish (Bolivia)',
	'es-CL': 'Spanish (Chile)',
	'es-CO': 'Spanish (Colombia)',
	'es-CR': 'Spanish (Costa Rica)',
	'es-DO': 'Spanish (Dominican Republic)',
	'es-EC': 'Spanish (Ecuador)',
	'es-ES': 'Spanish (Spain)',
	'es-GT': 'Spanish (Guatemala)',
	'es-HN': 'Spanish (Honduras)',
	'es-MX': 'Spanish (Mexico)',
	'es-NI': 'Spanish (Nicaragua)',
	'es-PA': 'Spanish (Panama)',
	'es-PE': 'Spanish (Peru)',
	'es-PR': 'Spanish (Puerto Rico)',
	'es-PY': 'Spanish (Paraguay)',
	'es-SV': 'Spanish (El Salvador)',
	'es-US': 'Spanish (United States)',
	'es-UY': 'Spanish (Uruguay)',
	'es-VE': 'Spanish (Venezuela)',
	'et-EE': 'Estonian (Estonia)',
	'fi-FI': 'Finnish (Finland)',
	'fr-BE': 'French (Belgium)',
	'fr-CA': 'French (Canada)',
	'fr-CH': 'French (Switzerland)',
	'fr-FR': 'French (France)',
	'fr-LU': 'French (Luxembourg)',
	'ga-IE': 'Irish (Ireland)',
	'hi-IN': 'Hindi (India)',
	'hr-HR': 'Croatian (Croatia)',
	'hu-HU': 'Hungarian (Hungary)',
	'in-ID': 'Indonesian (Indonesia)',
	'is-IS': 'Icelandic (Iceland)',
	'it-CH': 'Italian (Switzerland)',
	'it-IT': 'Italian (Italy)',
	'iw-IL': 'Hebrew (Israel)',
	'ja-JP': 'Japanese (Japan)',
	'ko-KR': 'Korean (South Korea)',
	'lt-LT': 'Lithuanian (Lithuania)',
	'lv-LV': 'Latvian (Latvia)',
	'mk-MK': 'Macedonian (Macedonia)',
	'ms-MY': 'Malay (Malaysia)',
	'mt-MT': 'Maltese (Malta)',
	'nl-BE': 'Dutch (Belgium)',
	'nl-NL': 'Dutch (Netherlands)',
	'no-NO': 'Norwegian (Norway)',
	'pl-PL': 'Polish (Poland)',
	'pt-BR': 'Portuguese (Brazil)',
	'pt-PT': 'Portuguese (Portugal)',
	'ro-RO': 'Romanian (Romania)',
	'ru-RU': 'Russian (Russia)',
	'sk-SK': 'Slovak (Slovakia)',
	'sl-SI': 'Slovenian (Slovenia)',
	'sr-BA': 'Serbian (Bosnia & Herzegovina)',
	'sr-ME': 'Serbian (Montenegro)',
	'sr-RS': 'Serbian (Serbia)',
	'sv-SE': 'Swedish (Sweden)',
	'th-TH': 'Thai (Thailand)',
	'tr-TR': 'Turkish (Turkey)',
	'uk-UA': 'Ukrainian (Ukraine)',
	'vi-VN': 'Vietnamese (Vietnam)',
	'zh-CN': 'Chinese (China)',
	'zh-HK': 'Chinese (Hong Kong)',
	'zh-SG': 'Chinese (Simplified, Singapore)',
	'zh-TW': 'Chinese (Taiwan)',
});

export const languageCodeMapper = () => ({
	ar: 'Arabic',
	bg: 'Bulgarian',
	bn: 'Bengali',
	ca: 'Catalan',
	cs: 'Czech',
	da: 'Danish',
	de: 'German',
	el: 'Greek',
	en: 'English',
	es: 'Spanish',
	et: 'Estonian',
	fa: 'Persian',
	fi: 'Finnish',
	fil: 'Filipino',
	fr: 'French',
	gu: 'Gujarati',
	he: 'Hebrew',
	hi: 'Hindi',
	hr: 'Croatian',
	hu: 'Hungarian',
	id: 'Indonesian',
	it: 'Italian',
	ja: 'Japanese',
	jv: 'Javanese',
	kk: 'Kazakh',
	kn: 'Kannada',
	ko: 'Korean',
	ml: 'Malayalam',
	mn: 'Mongolian',
	mr: 'Marathi',
	ms: 'Malay',
	my: 'Burmese',
	ne: 'Nepali',
	nl: 'Dutch',
	no: 'Norwegian',
	pa: 'Punjabi',
	pl: 'Polish',
	ps: 'Pushto',
	pt: 'Portuguese',
	ro: 'Romanian',
	ru: 'Russian',
	sk: 'Slovak',
	so: 'Somali',
	sv: 'Swedish',
	ta: 'Tamil',
	te: 'Telugu',
	th: 'Thai',
	tr: 'Turkish',
	uk: 'Ukrainian',
	ur: 'Urdu',
	vi: 'Vietnamese',
	zh: 'Chinese',
});

export const timezoneMapper = () => ({
	'Etc/GMT+12': '(UTC-12:00) International Date Line West',
	'Etc/GMT+11': '(UTC-11:00) Coordinated Universal Time-11',
	'Pacific/Honolulu': '(UTC-10:00) Hawaii',
	'America/Anchorage': '(UTC-09:00) Alaska',
	'America/Santa_Isabel': '(UTC-08:00) Baja California',
	'America/Los_Angeles': '(UTC-08:00) Pacific Time (US and Canada)',
	'America/Chihuahua': '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
	'America/Phoenix': '(UTC-07:00) Arizona',
	'America/Denver': '(UTC-07:00) Mountain Time (US and Canada)',
	'America/Guatemala': '(UTC-06:00) Central America',
	'America/Chicago': '(UTC-06:00) Central Time (US and Canada)',
	'America/Regina': '(UTC-06:00) Saskatchewan',
	'America/Mexico_City': '(UTC-06:00) Guadalajara, Mexico City, Monterey',
	'America/Bogota': '(UTC-05:00) Bogota, Lima, Quito',
	'America/Indiana/Indianapolis': '(UTC-05:00) Indiana (East)',
	'America/New_York': '(UTC-05:00) Eastern Time (US and Canada)',
	'America/Caracas': '(UTC-04:30) Caracas',
	'America/Halifax': '(UTC-04:00) Atlantic Time (Canada)',
	'America/Asuncion': '(UTC-04:00) Asuncion',
	'America/La_Paz': '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
	'America/Cuiaba': '(UTC-04:00) Cuiaba',
	'America/Santiago': '(UTC-04:00) Santiago',
	'America/St_Johns': '(UTC-03:30) Newfoundland',
	'America/Sao_Paulo': '(UTC-03:00) Brasilia',
	'America/Godthab': '(UTC-03:00) Greenland',
	'America/Cayenne': '(UTC-03:00) Cayenne, Fortaleza',
	'America/Argentina/Buenos_Aires': '(UTC-03:00) Buenos Aires',
	'America/Montevideo': '(UTC-03:00) Montevideo',
	'Etc/GMT+2': '(UTC-02:00) Coordinated Universal Time-2',
	'Atlantic/Cape_Verde': '(UTC-01:00) Cape Verde',
	'Atlantic/Azores': '(UTC-01:00) Azores',
	'Africa/Casablanca': '(UTC+00:00) Casablanca',
	'Atlantic/Reykjavik': '(UTC+00:00) Monrovia, Reykjavik',
	'Europe/London': '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
	'Etc/GMT': '(UTC+00:00) Coordinated Universal Time',
	'Europe/Berlin': '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
	'Europe/Paris': '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
	'Africa/Lagos': '(UTC+01:00) West Central Africa',
	'Europe/Budapest': '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
	'Europe/Warsaw': '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
	'Africa/Windhoek': '(UTC+01:00) Windhoek',
	'Europe/Istanbul': '(UTC+02:00) Athens, Bucharest, Istanbul',
	'Europe/Kiev': '(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
	'Africa/Cairo': '(UTC+02:00) Cairo',
	'Asia/Damascus': '(UTC+02:00) Damascus',
	'Asia/Amman': '(UTC+02:00) Amman',
	'Africa/Johannesburg': '(UTC+02:00) Harare, Pretoria',
	'Asia/Jerusalem': '(UTC+02:00) Jerusalem',
	'Asia/Beirut': '(UTC+02:00) Beirut',
	'Asia/Baghdad': '(UTC+03:00) Baghdad',
	'Europe/Minsk': '(UTC+03:00) Minsk',
	'Asia/Riyadh': '(UTC+03:00) Kuwait, Riyadh',
	'Africa/Nairobi': '(UTC+03:00) Nairobi',
	'Asia/Tehran': '(UTC+03:30) Tehran',
	'Europe/Moscow': '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
	'Asia/Tbilisi': '(UTC+04:00) Tbilisi',
	'Asia/Yerevan': '(UTC+04:00) Yerevan',
	'Asia/Dubai': '(UTC+04:00) Abu Dhabi, Muscat',
	'Asia/Baku': '(UTC+04:00) Baku',
	'Indian/Mauritius': '(UTC+04:00) Port Louis',
	'Asia/Kabul': '(UTC+04:30) Kabul',
	'Asia/Tashkent': '(UTC+05:00) Tashkent',
	'Asia/Karachi': '(UTC+05:00) Islamabad, Karachi',
	'Asia/Colombo': '(UTC+05:30) Sri Jayewardenepura Kotte',
	'Asia/Kolkata': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
	'Asia/Calcutta': '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
	'Asia/Kathmandu': '(UTC+05:45) Kathmandu',
	'Asia/Almaty': '(UTC+06:00) Astana',
	'Asia/Dhaka': '(UTC+06:00) Dhaka',
	'Asia/Yekaterinburg': '(UTC+06:00) Yekaterinburg',
	'Asia/Yangon': '(UTC+06:30) Yangon',
	'Asia/Bangkok': '(UTC+07:00) Bangkok, Hanoi, Jakarta',
	'Asia/Novosibirsk': '(UTC+07:00) Novosibirsk',
	'Asia/Krasnoyarsk': '(UTC+08:00) Krasnoyarsk',
	'Asia/Ulaanbaatar': '(UTC+08:00) Ulaanbaatar',
	'Asia/Shanghai': '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
	'Australia/Perth': '(UTC+08:00) Perth',
	'Asia/Singapore': '(UTC+08:00) Kuala Lumpur, Singapore',
	'Asia/Taipei': '(UTC+08:00) Taipei',
	'Asia/Irkutsk': '(UTC+09:00) Irkutsk',
	'Asia/Seoul': '(UTC+09:00) Seoul',
	'Asia/Tokyo': '(UTC+09:00) Osaka, Sapporo, Tokyo',
	'Australia/Darwin': '(UTC+09:30) Darwin',
	'Australia/Adelaide': '(UTC+09:30) Adelaide',
	'Australia/Hobart': '(UTC+10:00) Hobart',
	'Asia/Yakutsk': '(UTC+10:00) Yakutsk',
	'Australia/Brisbane': '(UTC+10:00) Brisbane',
	'Pacific/Port_Moresby': '(UTC+10:00) Guam, Port Moresby',
	'Australia/Sydney': '(UTC+10:00) Canberra, Melbourne, Sydney',
	'Asia/Vladivostok': '(UTC+11:00) Vladivostok',
	'Pacific/Guadalcanal': '(UTC+11:00) Solomon Islands, New Caledonia',
	'Etc/GMT-12': '(UTC+12:00) Coordinated Universal Time+12',
	'Pacific/Fiji': '(UTC+12:00) Fiji, Marshall Islands',
	'Asia/Magadan': '(UTC+12:00) Magadan',
	'Pacific/Auckland': '(UTC+12:00) Auckland, Wellington',
	'Pacific/Tongatapu': "(UTC+13:00) Nuku'alofa",
	'Pacific/Apia': '(UTC+13:00) Samoa',
});

export const timeZoneToCountryCode: any = () => ({
	'Pacific/Niue': {
		id: '+683',
		label: '🇳🇺 +683',
	},
	'Pacific/Pago_Pago': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	HST: {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Pacific/Honolulu': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Pacific/Rarotonga': {
		id: '+682',
		label: '🇨🇰 +682',
	},
	'Pacific/Tahiti': {
		id: '+689',
		label: '🇵🇫 +689',
	},
	'Pacific/Marquesas': {
		id: '+689',
		label: '🇵🇫 +689',
	},
	'America/Adak': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Pacific/Gambier': {
		id: '+689',
		label: '🇵🇫 +689',
	},
	'America/Anchorage': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Juneau': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Metlakatla': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Nome': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Sitka': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Yakutat': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Pacific/Pitcairn': {
		id: '+64',
		label: '🇳🇿 +64',
	},
	'America/Dawson': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Dawson_Creek': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Fort_Nelson': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Hermosillo': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Mazatlan': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Phoenix': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Whitehorse': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	MST: {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Los_Angeles': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Tijuana': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Vancouver': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	PST8PDT: {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Bahia_Banderas': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Belize': {
		id: '+501',
		label: '🇧🇿 +501',
	},
	'America/Chihuahua': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Costa_Rica': {
		id: '+506',
		label: '🇨🇷 +506',
	},
	'America/El_Salvador': {
		id: '+503',
		label: '🇸🇻 +503',
	},
	'America/Guatemala': {
		id: '+502',
		label: '🇬🇹 +502',
	},
	'America/Managua': {
		id: '+505',
		label: '🇳🇮 +505',
	},
	'America/Merida': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Mexico_City': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Monterrey': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Ojinaga': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Regina': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Swift_Current': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Tegucigalpa': {
		id: '+504',
		label: '🇭🇳 +504',
	},
	'America/Boise': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Cambridge_Bay': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Denver': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Edmonton': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Inuvik': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Yellowknife': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	MST7MDT: {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Pacific/Galapagos': {
		id: '+593',
		label: '🇪🇨 +593',
	},
	'America/Bogota': {
		id: '+57',
		label: '🇨🇴 +57',
	},
	'America/Eirunepe': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Guayaquil': {
		id: '+593',
		label: '🇪🇨 +593',
	},
	'America/Lima': {
		id: '+51',
		label: '🇵🇪 +51',
	},
	'America/Rio_Branco': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Chicago': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Indiana/Knox': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Indiana/Tell_City': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Matamoros': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Menominee': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/North_Dakota/Beulah': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/North_Dakota/Center': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/North_Dakota/New_Salem': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Rankin_Inlet': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Resolute': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Winnipeg': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	CST6CDT: {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Cancun': {
		id: '+52',
		label: '🇲🇽 +52',
	},
	'America/Jamaica': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Panama': {
		id: '+507',
		label: '🇵🇦 +507',
	},
	'Pacific/Easter': {
		id: '+56',
		label: '🇨🇱 +56',
	},
	'America/Barbados': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Martinique': {
		id: '+596',
		label: '🇲🇶 +596',
	},
	'America/Puerto_Rico': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Santo_Domingo': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Asuncion': {
		id: '+595',
		label: '🇵🇾 +595',
	},
	'America/Boa_Vista': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Campo_Grande': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Caracas': {
		id: '+58',
		label: '🇻🇪 +58',
	},
	'America/Cuiaba': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Guyana': {
		id: '+592',
		label: '🇬🇾 +592',
	},
	'America/La_Paz': {
		id: '+591',
		label: '🇧🇴 +591',
	},
	'America/Manaus': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Porto_Velho': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Havana': {
		id: '+53',
		label: '🇨🇺 +53',
	},
	'America/Detroit': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Fort_Wayne': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Grand_Turk': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Indiana/Marengo': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Indiana/Petersburg': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Indiana/Vevay': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Indiana/Vincennes': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Indiana/Winamac': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Iqaluit': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Kentucky/Louisville': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Kentucky/Monticello': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/New_York': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Pangnirtung': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Toronto': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Glace_Bay': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Goose_Bay': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Halifax': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Moncton': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Atlantic/Bermuda': {
		id: '+1',
		label: '🇧🇲 +1',
	},
	'America/Port-au-Prince': {
		id: '+509',
		label: '🇭🇹 +509',
	},
	'America/Thule': {
		id: '+299',
		label: '🇬🇱 +299',
	},
	'America/Araguaina': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Argentina/Buenos_Aires': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/Catamarca': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/Cordoba': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/Jujuy': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/La_Rioja': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/Mendoza': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/Rio_Gallegos': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/Salta': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/San_Juan': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/San_Luis': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/Tucuman': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Argentina/Ushuaia': {
		id: '+54',
		label: '🇦🇷 +54',
	},
	'America/Bahia': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Belem': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Fortaleza': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Maceio': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Recife': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Santarem': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Sao_Paulo': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'America/Cayenne': {
		id: '+594',
		label: '🇬🇫 +594',
	},
	'America/Montevideo': {
		id: '+598',
		label: '🇺🇾 +598',
	},
	'America/Paramaribo': {
		id: '+597',
		label: '🇸🇷 +597',
	},
	'America/Punta_Arenas': {
		id: '+56',
		label: '🇨🇱 +56',
	},
	'America/Santiago': {
		id: '+56',
		label: '🇨🇱 +56',
	},
	'Atlantic/Stanley': {
		id: '+500',
		label: '🇫🇰 +500',
	},
	'America/St_Johns': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Godthab': {
		id: '+299',
		label: '🇬🇱 +299',
	},
	'America/Scoresbysund': {
		id: '+299',
		label: '🇬🇱 +299',
	},
	'America/Miquelon': {
		id: '+508',
		label: '🇵🇲 +508',
	},
	'America/Noronha': {
		id: '+55',
		label: '🇧🇷 +55',
	},
	'Atlantic/South_Georgia': {
		id: '+500',
		label: '🇬🇸 +500',
	},
	'Atlantic/Cape_Verde': {
		id: '+238',
		label: '🇨🇻 +238',
	},
	'Atlantic/Azores': {
		id: '+351',
		label: '🇵🇹 +351',
	},
	'Africa/Abidjan': {
		id: '+225',
		label: '🇨🇮 +225',
	},
	'Africa/Bissau': {
		id: '+245',
		label: '🇬🇼 +245',
	},
	'Africa/Monrovia': {
		id: '+231',
		label: '🇿🇦 +231',
	},
	'Africa/Sao_Tome': {
		id: '+239',
		label: '🇸🇹 +239',
	},
	'Africa/Casablanca': {
		id: '+212',
		label: '🇲🇦 +212',
	},
	'Europe/London': {
		id: '+44',
		label: '🇬🇬 +44',
	},
	'Africa/Algiers': {
		id: '+213',
		label: '🇩🇿 +213',
	},
	'Africa/Tunis': {
		id: '+216',
		label: '🇹🇳 +216',
	},
	'Europe/Dublin': {
		id: '+353',
		label: '🇮🇪 +353',
	},
	'Africa/Lagos': {
		id: '+234',
		label: '🇳🇬 +234',
	},
	'Africa/Ndjamena': {
		id: '+235',
		label: '🇿🇦 +235',
	},
	'Atlantic/Canary': {
		id: '+34',
		label: '🇮🇨 +34',
	},
	'Africa/Ceuta': {
		id: '34',
		label: '🇪🇦 34',
	},
	'Atlantic/Faroe': {
		id: '+298',
		label: '🇫🇴 +298',
	},
	'Atlantic/Madeira': {
		id: '+351',
		label: '🇵🇹 +351',
	},
	'Europe/Lisbon': {
		id: '+351',
		label: '🇵🇹 +351',
	},
	'Africa/Juba': {
		id: '+211',
		label: '🇿🇦 +211',
	},
	'Africa/Khartoum': {
		id: '+249',
		label: '🇿🇦 +249',
	},
	'Africa/Maputo': {
		id: '+258',
		label: '🇲🇿 +258',
	},
	'Africa/Windhoek': {
		id: '+264',
		label: '🇳🇦 +264',
	},
	'Europe/Andorra': {
		id: '+376',
		label: '🇦🇩 +376',
	},
	'Europe/Belgrade': {
		id: '+381',
		label: '🇷🇸 +381',
	},
	'Europe/Berlin': {
		id: '+49',
		label: '🇩🇪 +49',
	},
	'Europe/Brussels': {
		id: '+32',
		label: '🇧🇪 +32',
	},
	'Europe/Budapest': {
		id: '+36',
		label: '🇭🇺 +36',
	},
	'Europe/Gibraltar': {
		id: '+350',
		label: '🇬🇮 +350',
	},
	'Europe/Madrid': {
		id: '+34',
		label: '🇮🇨 +34',
	},
	'Europe/Malta': {
		id: '+356',
		label: '🇲🇹 +356',
	},
	'Europe/Paris': {
		id: '+33',
		label: '🇫🇷 +33',
	},
	'Europe/Prague': {
		id: '+420',
		label: '🇨🇿 +420',
	},
	'Europe/Rome': {
		id: '+39',
		label: '🇮🇹 +39',
	},
	'Europe/Tirane': {
		id: '+355',
		label: '🇪🇺 +355',
	},
	'Europe/Vienna': {
		id: '+43',
		label: '🇦🇹 +43',
	},
	'Europe/Warsaw': {
		id: '+48',
		label: '🇵🇱 +48',
	},
	'Europe/Zurich': {
		id: '+41',
		label: '🇨🇭 +41',
	},
	'Africa/Cairo': {
		id: '+20',
		label: '🇪🇬 +20',
	},
	'Africa/Tripoli': {
		id: '+218',
		label: '🇱🇾 +218',
	},
	'Europe/Kaliningrad': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Europe/Kirov': {
		id: '+7',
		label: '🇪🇺 +7',
	},
	'Europe/Volgograd': {
		id: '+7',
		label: '🇪🇺 +7',
	},
	'Europe/Moscow': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Europe/Simferopol': {
		id: '+7',
		label: '🇪🇺 +7',
	},
	'Europe/Astrakhan': {
		id: '+7',
		label: '🇪🇺 +7',
	},
	'Europe/Samara': {
		id: '+7',
		label: '🇪🇺 +7',
	},
	'Europe/Saratov': {
		id: '+7',
		label: '🇪🇺 +7',
	},
	'Europe/Ulyanovsk': {
		id: '+7',
		label: '🇪🇺 +7',
	},
	'Asia/Aqtau': {
		id: '+7',
		label: '🇰🇿 +7',
	},
	'Asia/Aqtobe': {
		id: '+7',
		label: '🇰🇿 +7',
	},
	'Asia/Atyrau': {
		id: '+7',
		label: '🇰🇿 +7',
	},
	'Asia/Oral': {
		id: '+7',
		label: '🇰🇿 +7',
	},
	'Asia/Qyzylorda': {
		id: '+7',
		label: '🇰🇿 +7',
	},
	'Asia/Yekaterinburg': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Almaty': {
		id: '+7',
		label: '🇰🇿 +7',
	},
	'Asia/Omsk': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Qostanay': {
		id: '+7',
		label: '🇰🇿 +7',
	},
	'Asia/Barnaul': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Krasnoyarsk': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Novokuznetsk': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Novosibirsk': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Tomsk': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Irkutsk': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Chita': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Khandyga': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Yakutsk': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Ust-Nera': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Vladivostok': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Magadan': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Sakhalin': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Srednekolymsk': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Anadyr': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Asia/Kamchatka': {
		id: '+7',
		label: '🇷🇺 +7',
	},
	'Africa/Johannesburg': {
		id: '+27',
		label: '🇿🇦 +27',
	},
	'Asia/Amman': {
		id: '+962',
		label: '🇯🇴 +962',
	},
	'Asia/Baghdad': {
		id: '+964',
		label: '🇮🇶 +964',
	},
	'Asia/Damascus': {
		id: '+963',
		label: '🇸🇾 +963',
	},
	'Asia/Riyadh': {
		id: '+966',
		label: '🇸🇦 +966',
	},
	'Africa/Nairobi': {
		id: '+254',
		label: '🇰🇪 +254',
	},
	'Asia/Beirut': {
		id: '+961',
		label: '🇱🇧 +961',
	},
	'Asia/Famagusta': {
		id: '+90',
		label: '🇨🇾 +90',
	},
	'Asia/Qatar': {
		id: '+974',
		label: '🇶🇦 +974',
	},
	'Asia/Gaza': {
		id: '+970',
		label: '🇵🇸 +970',
	},
	'Asia/Hebron': {
		id: '+970',
		label: '🇵🇸 +970',
	},
	'Asia/Nicosia': {
		id: '+357',
		label: '🇨🇾 +357',
	},
	'Europe/Athens': {
		id: '+30',
		label: '🇬🇷 +30',
	},
	'Europe/Bucharest': {
		id: '+40',
		label: '🇷🇴 +40',
	},
	'Europe/Chisinau': {
		id: '+373',
		label: '🇲🇩 +373',
	},
	'Europe/Helsinki': {
		id: '+358',
		label: '🇫🇮 +358',
	},
	'Europe/Kiev': {
		id: '+380',
		label: '🇺🇦 +380',
	},
	'Europe/Riga': {
		id: '+371',
		label: '🇱🇻 +371',
	},
	'Europe/Sofia': {
		id: '+359',
		label: '🇧🇬 +359',
	},
	'Europe/Tallinn': {
		id: '+372',
		label: '🇪🇪 +372',
	},
	'Europe/Vilnius': {
		id: '+370',
		label: '🇱🇹 +370',
	},
	'Europe/Istanbul': {
		id: '+90',
		label: '🇹🇷 +90',
	},
	'Europe/Minsk': {
		id: '+375',
		label: '🇧🇾 +375',
	},
	'Asia/Jerusalem': {
		id: '+972',
		label: '🇮🇱 +972',
	},
	'Asia/Tehran': {
		id: '+98',
		label: '🇮🇷 +98',
	},
	'Asia/Baku': {
		id: '+944',
		label: '🇦🇿 +944',
	},
	'Asia/Dubai': {
		id: '+971',
		label: '🇦🇪 +971',
	},
	'Asia/Tbilisi': {
		id: '+995',
		label: '🇬🇪 +995',
	},
	'Asia/Yerevan': {
		id: '+374',
		label: '🇦🇲 +374',
	},
	'Indian/Mauritius': {
		id: '+230',
		label: '🇲🇺 +230',
	},
	'Asia/Kabul': {
		id: '+93',
		label: '🇦🇫 +93',
	},
	'Asia/Ashgabat': {
		id: '+993',
		label: '🇹🇲 +993',
	},
	'Asia/Dushanbe': {
		id: '+992',
		label: '🇹🇯 +992',
	},
	'Asia/Tashkent': {
		id: '+998',
		label: '🇺🇿 +998',
	},
	'Asia/Samarkand': {
		id: '+998',
		label: '🇺🇿 +998',
	},
	'Indian/Maldives': {
		id: '+960',
		label: '🇲🇻 +960',
	},
	'Asia/Karachi': {
		id: '+92',
		label: '🇵🇰 +92',
	},
	'Asia/Colombo': {
		id: '+94',
		label: '🇱🇰 +94',
	},
	'Asia/Kolkata': {
		id: '+91',
		label: '🇮🇳 +91',
	},
	'Asia/Kathmandu': {
		id: '+977',
		label: '🇳🇵 +977',
	},
	'Asia/Bishkek': {
		id: '+996',
		label: '🇰🇬 +996',
	},
	'Asia/Dhaka': {
		id: '+880',
		label: '🇧🇩 +880',
	},
	'Asia/Thimphu': {
		id: '+975',
		label: '🇧🇹 +975',
	},
	'Asia/Urumqi': {
		id: '+86',
		label: '🇨🇳 +86',
	},
	'Indian/Chagos': {
		id: '+246',
		label: '🇮🇴 +246',
	},
	'Asia/Rangoon': {
		id: '+95',
		label: '🇲🇲 +95',
	},
	'Asia/Bangkok': {
		id: '+66',
		label: '🇹🇭 +66',
	},
	'Asia/Ho_Chi_Minh': {
		id: '+84',
		label: '🏴󠁶󠁮󠁳󠁧󠁿 +84',
	},
	'Asia/Hovd': {
		id: '+976',
		label: '🇲🇳 +976',
	},
	'Asia/Jakarta': {
		id: '+62',
		label: '🇮🇩 +62',
	},
	'Asia/Pontianak': {
		id: '+62',
		label: '🇮🇩 +62',
	},
	'Asia/Makassar': {
		id: '+62',
		label: '🇮🇩 +62',
	},
	'Asia/Jayapura': {
		id: '+62',
		label: '🇮🇩 +62',
	},
	'Australia/Perth': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Asia/Brunei': {
		id: '+673',
		label: '🇧🇳 +673',
	},
	'Asia/Choibalsan': {
		id: '+976',
		label: '🇲🇳 +976',
	},
	'Asia/Ulaanbaatar': {
		id: '+976',
		label: '🇲🇳 +976',
	},
	'Asia/Kuala_Lumpur': {
		id: '+60',
		label: '🇲🇾 +60',
	},
	'Asia/Macau': {
		id: '+853',
		label: '🇲🇴 +853',
	},
	'Asia/Shanghai': {
		id: '+86',
		label: '🇨🇳 +86',
	},
	'Asia/Taipei': {
		id: '+886',
		label: '🇹🇼 +886',
	},
	'Asia/Hong_Kong': {
		id: '+852',
		label: '🇭🇰 +852',
	},
	'Asia/Manila': {
		id: '+63',
		label: '🇵🇭 +63',
	},
	'Australia/Eucla': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Asia/Dili': {
		id: '+670',
		label: '🇹🇱 +670',
	},
	'Asia/Tokyo': {
		id: '+81',
		label: '🇯🇵 +81',
	},
	'Asia/Pyongyang': {
		id: '+850',
		label: '🇰🇵 +850',
	},
	'Asia/Seoul': {
		id: '+82',
		label: '🇰🇷 +82',
	},
	'Pacific/Palau': {
		id: '+680',
		label: '🇵🇼 +680',
	},
	'Australia/Adelaide': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Australia/Broken_Hill': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Australia/Darwin': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Australia/Brisbane': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Australia/Hobart': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Australia/Lindeman': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Australia/Melbourne': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Australia/Sydney': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Australia/Lord_Howe': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Antarctica/Macquarie': {
		id: '+672',
		label: '🇦🇺 +672',
	},
	'Pacific/Guam': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Pacific/Port_Moresby': {
		id: '+675',
		label: '🇵🇬 +675',
	},
	'Pacific/Bougainville': {
		id: '+675',
		label: '🇵🇬 +675',
	},
	'Antarctica/Casey': {
		id: '+672',
		label: '🇦🇺 +672',
	},
	'Pacific/Efate': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Pacific/Guadalcanal': {
		id: '+677',
		label: '🇸🇧 +677',
	},
	'Pacific/Kosrae': {
		id: '+691',
		label: '🇫🇲 +691',
	},
	'Pacific/Norfolk': {
		id: '+672',
		label: '🇳🇫 +672',
	},
	'Pacific/Noumea': {
		id: '+687',
		label: '🇳🇨 +687',
	},
	'Pacific/Auckland': {
		id: '+64',
		label: '🇳🇿 +64',
	},
	'Pacific/Kwajalein': {
		id: '+692',
		label: '🇲🇭 +692',
	},
	'Pacific/Nauru': {
		id: '+674',
		label: '🇳🇷 +674',
	},
	'Pacific/Tarawa': {
		id: '+686',
		label: '🇰🇮 +686',
	},
	'Pacific/Enderbury': {
		id: '+686',
		label: '🇰🇮 +686',
	},
	'Pacific/Kiritimati': {
		id: '+686',
		label: '🇰🇮 +686',
	},
	'Pacific/Chatham': {
		id: '+64',
		label: '🇳🇿 +64',
	},
	'Pacific/Apia': {
		id: '+685',
		label: '🇼🇸 +685',
	},
	'Pacific/Fakaofo': {
		id: '+690',
		label: '🇹🇰 +690',
	},
	'Pacific/Tongatapu': {
		id: '+676',
		label: '🇹🇴 +676',
	},
	'Pacific/Midway': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Creston': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Atikokan': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Cayman': {
		id: '+1',
		label: '🇰🇾 +1',
	},
	'America/Rainy_River': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Anguilla': {
		id: '+1',
		label: '🇰🇾 +1',
	},
	'America/Antigua': {
		id: '+1',
		label: '🇦🇬 +1',
	},
	'America/Aruba': {
		id: '+297',
		label: '🇦🇼 +297',
	},
	'America/Blanc-Sablon': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Curacao': {
		id: '+599',
		label: '🇨🇼 +599',
	},
	'America/Dominica': {
		id: '+1',
		label: '🇩🇲 +1',
	},
	'America/Grenada': {
		id: '+1',
		label: '🇬🇩 +1',
	},
	'America/Guadeloupe': {
		id: '+590',
		label: '🇬🇵 +590',
	},
	'America/Indiana/Indianapolis': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Kralendijk': {
		id: '+599',
		label: '🇧🇶 +599',
	},
	'America/Lower_Princes': {
		id: '+599',
		label: '🇧🇶 +599',
	},
	'America/Marigot': {
		id: '+590',
		label: '🇫🇷 +590',
	},
	'America/Montserrat': {
		id: '+1',
		label: '🇲🇸 +1',
	},
	'America/Nassau': {
		id: '+1',
		label: '🇧🇸 +1',
	},
	'America/Nipigon': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Port_of_Spain': {
		id: '+1',
		label: '🇹🇹 +1',
	},
	'America/St_Barthelemy': {
		id: '+590',
		label: '🇫🇷 +590',
	},
	'America/St_Kitts': {
		id: '+1',
		label: '🇰🇳 +1',
	},
	'America/St_Lucia': {
		id: '+1',
		label: '🇱🇨 +1',
	},
	'America/St_Thomas': {
		id: '+1',
		label: '🇻🇮 +1',
	},
	'America/St_Vincent': {
		id: '+1',
		label: '🇻🇨 +1',
	},
	'America/Thunder_Bay': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'America/Nuuk': {
		id: '+299',
		label: '🇬🇱 +299',
	},
	'Africa/Accra': {
		id: '+233',
		label: '🇬🇭 +233',
	},
	'Africa/Bamako': {
		id: '+223',
		label: '🇲🇱 +223',
	},
	'Africa/Banjul': {
		id: '+220',
		label: '🇬🇲 +220',
	},
	'Africa/Dakar': {
		id: '+221',
		label: '🇸🇳 +221',
	},
	'Africa/Freetown': {
		id: '+232',
		label: '🇸🇱 +232',
	},
	'Africa/Lome': {
		id: '+228',
		label: '🇹🇬 +228',
	},
	'Africa/Nouakchott': {
		id: '+222',
		label: '🇲🇷 +222',
	},
	'Africa/Ouagadougou': {
		id: '+226',
		label: '🇧🇫 +226',
	},
	'America/Danmarkshavn': {
		id: '+299',
		label: '🇬🇱 +299',
	},
	'Atlantic/Reykjavik': {
		id: '+354',
		label: '🇮🇸 +354',
	},
	'Atlantic/St_Helena': {
		id: '+290',
		label: '🇸🇭 +290',
	},
	'Africa/Bangui': {
		id: '+236',
		label: '🇨🇫 +236',
	},
	'Africa/Brazzaville': {
		id: '+242',
		label: '🇨🇬 +242',
	},
	'Africa/Kinshasa': {
		id: '+243',
		label: '🇨🇩 +243',
	},
	'Africa/Libreville': {
		id: '+241',
		label: '🇬🇦 +241',
	},
	'Africa/Luanda': {
		id: '+244',
		label: '🇦🇴 +244',
	},
	'Africa/Malabo': {
		id: '+240',
		label: '🇬🇶 +240',
	},
	'Africa/Niamey': {
		id: '+227',
		label: '🇳🇪 +227',
	},
	'Europe/Guernsey': {
		id: '+44',
		label: '🇬🇬 +44',
	},
	'Europe/Isle_of_Man': {
		id: '+44',
		label: '🇮🇲 +44',
	},
	'Europe/Jersey': {
		id: '+44',
		label: '🇯🇪 +44',
	},
	'Africa/Blantyre': {
		id: '+265',
		label: '🇲🇼 +265',
	},
	'Africa/Bujumbura': {
		id: '+257',
		label: '🇧🇮 +257',
	},
	'Africa/Gaborone': {
		id: '+267',
		label: '🇧🇼 +267',
	},
	'Africa/Harare': {
		id: '+263',
		label: '🇿🇼 +263',
	},
	'Africa/Kigali': {
		id: '+250',
		label: '🇷🇼 +250',
	},
	'Africa/Lubumbashi': {
		id: '+243',
		label: '🇨🇩 +243',
	},
	'Africa/Lusaka': {
		id: '+260',
		label: '🇿🇲 +260',
	},
	'Africa/Maseru': {
		id: '+266',
		label: '🇱🇸 +266',
	},
	'Africa/Mbabane': {
		id: '+268',
		label: '🇸🇿 +268',
	},
	'Arctic/Longyearbyen': {
		id: '+47',
		label: '🇳🇴 +47',
	},
	'Europe/Amsterdam': {
		id: '+31',
		label: '🇳🇱 +31',
	},
	'Europe/Bratislava': {
		id: '+421',
		label: '🇸🇰 +421',
	},
	'Europe/Busingen': {
		id: '+41',
		label: '🇨🇭 +41',
	},
	'Europe/Copenhagen': {
		id: '+45',
		label: '🇩🇰 +45',
	},
	'Europe/Ljubljana': {
		id: '+386',
		label: '🇸🇮 +386',
	},
	'Europe/Luxembourg': {
		id: '+352',
		label: '🇱🇺 +352',
	},
	'Europe/Monaco': {
		id: '+377',
		label: '🇲🇨 +377',
	},
	'Europe/Oslo': {
		id: '+47',
		label: '🇳🇴 +47',
	},
	'Europe/Podgorica': {
		id: '+382',
		label: '🇲🇪 +382',
	},
	'Europe/San_Marino': {
		id: '+378',
		label: '🇸🇲 +378',
	},
	'Europe/Sarajevo': {
		id: '+387',
		label: '🇧🇦 +387',
	},
	'Europe/Skopje': {
		id: '+389',
		label: '🇲🇰 +389',
	},
	'Europe/Stockholm': {
		id: '+46',
		label: '🇸🇪 +46',
	},
	'Europe/Vaduz': {
		id: '+423',
		label: '🇱🇮 +423',
	},
	'Europe/Vatican': {
		id: '+379',
		label: '🇻🇦 +379',
	},
	'Europe/Zagreb': {
		id: '+385',
		label: '🇭🇷 +385',
	},
	'Africa/Addis_Ababa': {
		id: '+251',
		label: '🇪🇹 +251',
	},
	'Africa/Asmara': {
		id: '+291',
		label: '🇪🇷 +291',
	},
	'Africa/Dar_es_Salaam': {
		id: '+255',
		label: '🇹🇿 +255',
	},
	'Africa/Djibouti': {
		id: '+253',
		label: '🇩🇯 +253',
	},
	'Africa/Kampala': {
		id: '+256',
		label: '🇺🇬 +256',
	},
	'Africa/Mogadishu': {
		id: '+252',
		label: '🇸🇴 +252',
	},
	'Asia/Aden': {
		id: '+967',
		label: '🇾🇪 +967',
	},
	'Asia/Bahrain': {
		id: '+973',
		label: '🇧🇭 +973',
	},
	'Asia/Kuwait': {
		id: '+965',
		label: '🇰🇼 +965',
	},
	'Europe/Kyiv': {
		id: '+380',
		label: '🇺🇦 +380',
	},
	'Europe/Mariehamn': {
		id: '+358',
		label: '🇫🇮 +358',
	},
	'Europe/Uzhgorod': {
		id: '+380',
		label: '🇺🇦 +380',
	},
	'Europe/Zaporozhye': {
		id: '+380',
		label: '🇺🇦 +380',
	},
	'Indian/Comoro': {
		id: '+269',
		label: '🇰🇲 +269',
	},
	'Indian/Mayotte': {
		id: '+262',
		label: '🇾🇹 +262',
	},
	'Asia/Muscat': {
		id: '+968',
		label: '🇴🇲 +968',
	},
	'Indian/Mahe': {
		id: '+248',
		label: '🇸🇨 +248',
	},
	'Indian/Reunion': {
		id: '+262',
		label: '🇷🇪 +262',
	},
	'Asia/Yangon': {
		id: '+95',
		label: '🇲🇲 +95',
	},
	'Indian/Cocos': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Asia/Phnom_Penh': {
		id: '+855',
		label: '🇰🇭 +855',
	},
	'Asia/Vientiane': {
		id: '+856',
		label: '🇱🇦 +856',
	},
	'Indian/Christmas': {
		id: '+61',
		label: '🇦🇺 +61',
	},
	'Asia/Kuching': {
		id: '+60',
		label: '🇲🇾 +60',
	},
	'Asia/Singapore': {
		id: '+65',
		label: '🇸🇬 +65',
	},
	'Pacific/Chuuk': {
		id: '+691',
		label: '🇫🇲 +691',
	},
	'Pacific/Saipan': {
		id: '+1',
		label: '🇲🇵 +1',
	},
	'Pacific/Pohnpei': {
		id: '+691',
		label: '🇫🇲 +691',
	},
	'Pacific/Fiji': {
		id: '+679',
		label: '🇫🇯 +679',
	},
	'Pacific/Funafuti': {
		id: '+688',
		label: '🇹🇻 +688',
	},
	'Pacific/Majuro': {
		id: '+692',
		label: '🇲🇭 +692',
	},
	'Pacific/Wake': {
		id: '+1',
		label: '🇺🇸 +1',
	},
	'Pacific/Wallis': {
		id: '+681',
		label: '🇼🇫 +681',
	},
	'Africa/Conakry': {
		id: '+224',
		label: '🇬🇳 +224',
	},
	'Africa/Douala': {
		id: '+237',
		label: '🇨🇲 +237',
	},
	'Africa/Porto-Novo': {
		id: '+229',
		label: '🇧🇯 +229',
	},
	'Pacific/Kanton': {
		id: '+686',
		label: '🇰🇮 +686',
	},
});

export const LLMModels = () => [
	{
		id: 'gpt-3.5-turbo',
		label: 'gpt-3.5',
	},
	{
		id: 'gpt-4',
		label: 'gpt-4',
	},
];

export const countryCodeMap = () => [
	{
		id: 'AF',
		label: 'Afghanistan',
	},
	{
		id: 'AX',
		label: 'Aland Islands',
	},
	{
		id: 'AL',
		label: 'Albania',
	},
	{
		id: 'DZ',
		label: 'Algeria',
	},
	{
		id: 'AS',
		label: 'American Samoa',
	},
	{
		id: 'AD',
		label: 'Andorra',
	},
	{
		id: 'AO',
		label: 'Angola',
	},
	{
		id: 'AI',
		label: 'Anguilla',
	},
	{
		id: 'AG',
		label: 'Antigua and Barbuda',
	},
	{
		id: 'AR',
		label: 'Argentina',
	},
	{
		id: 'AM',
		label: 'Armenia',
	},
	{
		id: 'AW',
		label: 'Aruba',
	},
	{
		id: 'AC',
		label: 'Ascension',
	},
	{
		id: 'AU',
		label: 'Australia',
	},
	{
		id: 'AT',
		label: 'Austria',
	},
	{
		id: 'AZ',
		label: 'Azerbaijan',
	},
	{
		id: 'BS',
		label: 'Bahamas',
	},
	{
		id: 'BH',
		label: 'Bahrain',
	},
	{
		id: 'BD',
		label: 'Bangladesh',
	},
	{
		id: 'BB',
		label: 'Barbados',
	},
	{
		id: 'BY',
		label: 'Belarus',
	},
	{
		id: 'BE',
		label: 'Belgium',
	},
	{
		id: 'BZ',
		label: 'Belize',
	},
	{
		id: 'BJ',
		label: 'Benin',
	},
	{
		id: 'BM',
		label: 'Bermuda',
	},
	{
		id: 'BT',
		label: 'Bhutan',
	},
	{
		id: 'BO',
		label: 'Bolivia',
	},
	{
		id: 'BA',
		label: 'Bosnia and Herzegovina',
	},
	{
		id: 'BW',
		label: 'Botswana',
	},
	{
		id: 'BR',
		label: 'Brazil',
	},
	{
		id: 'IO',
		label: 'British Indian Ocean Territory',
	},
	{
		id: 'VG',
		label: 'British Virgin Islands',
	},
	{
		id: 'BN',
		label: 'Brunei',
	},
	{
		id: 'BG',
		label: 'Bulgaria',
	},
	{
		id: 'BF',
		label: 'Burkina Faso',
	},
	{
		id: 'BI',
		label: 'Burundi',
	},
	{
		id: 'KH',
		label: 'Cambodia',
	},
	{
		id: 'CM',
		label: 'Cameroon',
	},
	{
		id: 'CA',
		label: 'Canada',
	},
	{
		id: 'CV',
		label: 'Cape Verde',
	},
	{
		id: 'BQ',
		label: 'Caribbean Netherlands',
	},
	{
		id: 'KY',
		label: 'Cayman Islands',
	},
	{
		id: 'CF',
		label: 'Central African Republic',
	},
	{
		id: 'TD',
		label: 'Chad',
	},
	{
		id: 'CL',
		label: 'Chile',
	},
	{
		id: 'CN',
		label: 'China',
	},
	{
		id: 'CX',
		label: 'Christmas Island',
	},
	{
		id: 'CC',
		label: 'Cocos Islands',
	},
	{
		id: 'CO',
		label: 'Colombia',
	},
	{
		id: 'KM',
		label: 'Comoros',
	},
	{
		id: 'CK',
		label: 'Cook Islands',
	},
	{
		id: 'CR',
		label: 'Costa Rica',
	},
	{
		id: 'HR',
		label: 'Croatia',
	},
	{
		id: 'CU',
		label: 'Cuba',
	},
	{
		id: 'CW',
		label: 'Curacao',
	},
	{
		id: 'CY',
		label: 'Cyprus',
	},
	{
		id: 'CZ',
		label: 'Czech Republic',
	},
	{
		id: 'CD',
		label: 'Democratic Republic of the Congo',
	},
	{
		id: 'DK',
		label: 'Denmark',
	},
	{
		id: 'DJ',
		label: 'Djibouti',
	},
	{
		id: 'DM',
		label: 'Dominica',
	},
	{
		id: 'DO',
		label: 'Dominican Republic',
	},
	{
		id: 'TL',
		label: 'East Timor',
	},
	{
		id: 'EC',
		label: 'Ecuador',
	},
	{
		id: 'EG',
		label: 'Egypt',
	},
	{
		id: 'SV',
		label: 'El Salvador',
	},
	{
		id: 'GQ',
		label: 'Equatorial Guinea',
	},
	{
		id: 'ER',
		label: 'Eritrea',
	},
	{
		id: 'EE',
		label: 'Estonia',
	},
	{
		id: 'ET',
		label: 'Ethiopia',
	},
	{
		id: 'FK',
		label: 'Falkland Islands',
	},
	{
		id: 'FO',
		label: 'Faroe Islands',
	},
	{
		id: 'FJ',
		label: 'Fiji',
	},
	{
		id: 'FI',
		label: 'Finland',
	},
	{
		id: 'FR',
		label: 'France',
	},
	{
		id: 'GF',
		label: 'French Guiana',
	},
	{
		id: 'PF',
		label: 'French Polynesia',
	},
	{
		id: 'GA',
		label: 'Gabon',
	},
	{
		id: 'GM',
		label: 'Gambia',
	},
	{
		id: 'GE',
		label: 'Georgia',
	},
	{
		id: 'DE',
		label: 'Germany',
	},
	{
		id: 'GH',
		label: 'Ghana',
	},
	{
		id: 'GI',
		label: 'Gibraltar',
	},
	{
		id: 'GR',
		label: 'Greece',
	},
	{
		id: 'GL',
		label: 'Greenland',
	},
	{
		id: 'GD',
		label: 'Grenada',
	},
	{
		id: 'GU',
		label: 'Guam',
	},
	{
		id: 'GP',
		label: 'Guadeloupe',
	},
	{
		id: 'GT',
		label: 'Guatemala',
	},
	{
		id: 'GG',
		label: 'Guernsey',
	},
	{
		id: 'GN',
		label: 'Guinea',
	},
	{
		id: 'GW',
		label: 'Guinea-Bissau',
	},
	{
		id: 'GY',
		label: 'Guyana',
	},
	{
		id: 'HT',
		label: 'Haiti',
	},
	{
		id: 'HN',
		label: 'Honduras',
	},
	{
		id: 'HK',
		label: 'Hong Kong',
	},
	{
		id: 'HU',
		label: 'Hungary',
	},
	{
		id: 'IS',
		label: 'Iceland',
	},
	{
		id: 'IN',
		label: 'India',
	},
	{
		id: 'ID',
		label: 'Indonesia',
	},
	{
		id: 'IR',
		label: 'Iran',
	},
	{
		id: 'IQ',
		label: 'Iraq',
	},
	{
		id: 'IE',
		label: 'Ireland',
	},
	{
		id: 'IM',
		label: 'Isle of Man',
	},
	{
		id: 'IL',
		label: 'Israel',
	},
	{
		id: 'IT',
		label: 'Italy',
	},
	{
		id: 'CI',
		label: 'Ivory Coast',
	},
	{
		id: 'JM',
		label: 'Jamaica',
	},
	{
		id: 'JP',
		label: 'Japan',
	},
	{
		id: 'JE',
		label: 'Jersey',
	},
	{
		id: 'JO',
		label: 'Jordan',
	},
	{
		id: 'KZ',
		label: 'Kazakhstan',
	},
	{
		id: 'KE',
		label: 'Kenya',
	},
	{
		id: 'KI',
		label: 'Kiribati',
	},
	{
		id: 'XK',
		label: 'Kosovo',
	},
	{
		id: 'KW',
		label: 'Kuwait',
	},
	{
		id: 'KG',
		label: 'Kyrgyzstan',
	},
	{
		id: 'LA',
		label: 'Laos',
	},
	{
		id: 'LV',
		label: 'Latvia',
	},
	{
		id: 'LB',
		label: 'Lebanon',
	},
	{
		id: 'LS',
		label: 'Lesotho',
	},
	{
		id: 'LR',
		label: 'Liberia',
	},
	{
		id: 'LY',
		label: 'Libya',
	},
	{
		id: 'LI',
		label: 'Liechtenstein',
	},
	{
		id: 'LT',
		label: 'Lithuania',
	},
	{
		id: 'LU',
		label: 'Luxembourg',
	},
	{
		id: 'MO',
		label: 'Macau',
	},
	{
		id: 'MK',
		label: 'Macedonia',
	},
	{
		id: 'MG',
		label: 'Madagascar',
	},
	{
		id: 'MW',
		label: 'Malawi',
	},
	{
		id: 'MY',
		label: 'Malaysia',
	},
	{
		id: 'MV',
		label: 'Maldives',
	},
	{
		id: 'ML',
		label: 'Mali',
	},
	{
		id: 'MT',
		label: 'Malta',
	},
	{
		id: 'MH',
		label: 'Marshall Islands',
	},
	{
		id: 'MQ',
		label: 'Martinique',
	},
	{
		id: 'MR',
		label: 'Mauritania',
	},
	{
		id: 'MU',
		label: 'Mauritius',
	},
	{
		id: 'YT',
		label: 'Mayotte',
	},
	{
		id: 'MX',
		label: 'Mexico',
	},
	{
		id: 'FM',
		label: 'Micronesia',
	},
	{
		id: 'MD',
		label: 'Moldova',
	},
	{
		id: 'MC',
		label: 'Monaco',
	},
	{
		id: 'MN',
		label: 'Mongolia',
	},
	{
		id: 'ME',
		label: 'Montenegro',
	},
	{
		id: 'MS',
		label: 'Montserrat',
	},
	{
		id: 'MA',
		label: 'Morocco',
	},
	{
		id: 'MZ',
		label: 'Mozambique',
	},
	{
		id: 'MM',
		label: 'Myanmar',
	},
	{
		id: 'NA',
		label: 'Namibia',
	},
	{
		id: 'NR',
		label: 'Nauru',
	},
	{
		id: 'NP',
		label: 'Nepal',
	},
	{
		id: 'NL',
		label: 'Netherlands',
	},
	{
		id: 'NC',
		label: 'New Caledonia',
	},
	{
		id: 'NZ',
		label: 'New Zealand',
	},
	{
		id: 'NI',
		label: 'Nicaragua',
	},
	{
		id: 'NE',
		label: 'Niger',
	},
	{
		id: 'NG',
		label: 'Nigeria',
	},
	{
		id: 'NU',
		label: 'Niue',
	},
	{
		id: 'NF',
		label: 'Norfolk Island',
	},
	{
		id: 'KP',
		label: 'North Korea',
	},
	{
		id: 'MP',
		label: 'Northern Mariana Islands',
	},
	{
		id: 'NO',
		label: 'Norway',
	},
	{
		id: 'OM',
		label: 'Oman',
	},
	{
		id: 'PK',
		label: 'Pakistan',
	},
	{
		id: 'PW',
		label: 'Palau',
	},
	{
		id: 'PS',
		label: 'Palestine',
	},
	{
		id: 'PA',
		label: 'Panama',
	},
	{
		id: 'PG',
		label: 'Papua New Guinea',
	},
	{
		id: 'PY',
		label: 'Paraguay',
	},
	{
		id: 'PE',
		label: 'Peru',
	},
	{
		id: 'PH',
		label: 'Philippines',
	},
	{
		id: 'PL',
		label: 'Poland',
	},
	{
		id: 'PT',
		label: 'Portugal',
	},
	{
		id: 'PR',
		label: 'Puerto Rico',
	},
	{
		id: 'QA',
		label: 'Qatar',
	},
	{
		id: 'CG',
		label: 'Republic of the Congo',
	},
	{
		id: 'RE',
		label: 'Reunion',
	},
	{
		id: 'RO',
		label: 'Romania',
	},
	{
		id: 'RU',
		label: 'Russia',
	},
	{
		id: 'RW',
		label: 'Rwanda',
	},
	{
		id: 'BL',
		label: 'Saint Barthelemy',
	},
	{
		id: 'SH',
		label: 'Saint Helena',
	},
	{
		id: 'KN',
		label: 'Saint Kitts and Nevis',
	},
	{
		id: 'LC',
		label: 'Saint Lucia',
	},
	{
		id: 'MF',
		label: 'Saint Martin',
	},
	{
		id: 'PM',
		label: 'Saint Pierre and Miquelon',
	},
	{
		id: 'VC',
		label: 'Saint Vincent and the Grenadines',
	},
	{
		id: 'WS',
		label: 'Samoa',
	},
	{
		id: 'SM',
		label: 'San Marino',
	},
	{
		id: 'ST',
		label: 'Sao Tome and Principe',
	},
	{
		id: 'SA',
		label: 'Saudi Arabia',
	},
	{
		id: 'SN',
		label: 'Senegal',
	},
	{
		id: 'RS',
		label: 'Serbia',
	},
	{
		id: 'SC',
		label: 'Seychelles',
	},
	{
		id: 'SL',
		label: 'Sierra Leone',
	},
	{
		id: 'SG',
		label: 'Singapore',
	},
	{
		id: 'SX',
		label: 'Sint Maarten',
	},
	{
		id: 'SK',
		label: 'Slovakia',
	},
	{
		id: 'SI',
		label: 'Slovenia',
	},
	{
		id: 'SB',
		label: 'Solomon Islands',
	},
	{
		id: 'SO',
		label: 'Somalia',
	},
	{
		id: 'ZA',
		label: 'South Africa',
	},
	{
		id: 'KR',
		label: 'South Korea',
	},
	{
		id: 'SS',
		label: 'South Sudan',
	},
	{
		id: 'ES',
		label: 'Spain',
	},
	{
		id: 'LK',
		label: 'Sri Lanka',
	},
	{
		id: 'SD',
		label: 'Sudan',
	},
	{
		id: 'SR',
		label: 'Suriname',
	},
	{
		id: 'SJ',
		label: 'Svalbard and Jan Mayen',
	},
	{
		id: 'SZ',
		label: 'Swaziland',
	},
	{
		id: 'SE',
		label: 'Sweden',
	},
	{
		id: 'CH',
		label: 'Switzerland',
	},
	{
		id: 'SY',
		label: 'Syria',
	},
	{
		id: 'TW',
		label: 'Taiwan',
	},
	{
		id: 'TJ',
		label: 'Tajikistan',
	},
	{
		id: 'TZ',
		label: 'Tanzania',
	},
	{
		id: 'TH',
		label: 'Thailand',
	},
	{
		id: 'TG',
		label: 'Togo',
	},
	{
		id: 'TK',
		label: 'Tokelau',
	},
	{
		id: 'TO',
		label: 'Tonga',
	},
	{
		id: 'TT',
		label: 'Trinidad and Tobago',
	},
	{
		id: 'TN',
		label: 'Tunisia',
	},
	{
		id: 'TR',
		label: 'Turkey',
	},
	{
		id: 'TM',
		label: 'Turkmenistan',
	},
	{
		id: 'TC',
		label: 'Turks and Caicos Islands',
	},
	{
		id: 'TV',
		label: 'Tuvalu',
	},
	{
		id: 'VI',
		label: 'U.S. Virgin Islands',
	},
	{
		id: 'UG',
		label: 'Uganda',
	},
	{
		id: 'UA',
		label: 'Ukraine',
	},
	{
		id: 'AE',
		label: 'United Arab Emirates',
	},
	{
		id: 'GB',
		label: 'United Kingdom',
	},
	{
		id: 'US',
		label: 'United States',
	},
	{
		id: 'UY',
		label: 'Uruguay',
	},
	{
		id: 'UZ',
		label: 'Uzbekistan',
	},
	{
		id: 'VU',
		label: 'Vanuatu',
	},
	{
		id: 'VA',
		label: 'Vatican',
	},
	{
		id: 'VE',
		label: 'Venezuela',
	},
	{
		id: 'VN',
		label: 'Vietnam',
	},
	{
		id: 'WF',
		label: 'Wallis and Futuna',
	},
	{
		id: 'EH',
		label: 'Western Sahara',
	},
	{
		id: 'YE',
		label: 'Yemen',
	},
	{
		id: 'ZM',
		label: 'Zambia',
	},
	{
		id: 'ZW',
		label: 'Zimbabwe',
	},
];

/**
 * Validate API Response of language configuration api for responseObject Type
 * @param response Api Response
 */
export const isValidLanguageConfigurationResponse = (response: any) =>
	response.data &&
	response.data.response_obj &&
	response.data.response_obj.status &&
	response.data.response_obj.status.code === ENGATI_RESPONSE_CODE.STATUS_1000;

export const convertJsonToHtml = function (obj: any, path: any, responseFieldTypeMap: any, objectValueMap: any) {
	if (obj.constructor === Array) {
		responseFieldTypeMap[path] = 'ARRAY';
		for (const i in obj) {
			const newPath = path == null ? i : `${path}.${i}`;
			if (typeof obj[i] === 'object' && obj[i] != null) {
				convertJsonToHtml(obj[i], newPath, responseFieldTypeMap, objectValueMap);
			} else {
				responseFieldTypeMap[newPath] = typeof obj[i];
			}
		}
	} else {
		for (const k in obj) {
			const newPath = path == null ? k : `${path}.${k}`;
			const newKey = `<span class='object-key' json-path = '${newPath}'>${k}</span>`;
			obj[newKey] = obj[k];
			if (typeof obj[newKey] === 'object' && obj[newKey] != null) {
				objectValueMap[newPath] = JSON.stringify(obj[k]);
				responseFieldTypeMap[newPath] = 'OBJECT';
				delete obj[k];
				convertJsonToHtml(obj[newKey], newPath, responseFieldTypeMap, objectValueMap);
			} else {
				responseFieldTypeMap[newPath] = typeof obj[k];
				delete obj[k];
			}
		}
	}
};

export const setDefaultFavIcon = (favIconUrl: string) => {
	if (!favIconUrl) {
		return;
	}
	FAVICON_SIZES.forEach((size: number) => {
		const iconElement: any = document.getElementById(`portal-fav-icon-${size}`);

		if (iconElement) {
			iconElement.href = `${favIconUrl}/favicon-${size}x${size}.png`;
		}
	});

	FAVICON_APPLE_TOUCH_SIZES.forEach((size: number) => {
		const iconElement: any = document.getElementById(`apple-touch-icon-${size}`);

		if (iconElement) {
			iconElement.href = `${favIconUrl}/apple-icon-${size}x${size}.png`;
		}
	});

	const androidIconElement: any = document.getElementById('android-branding-icon');

	if (androidIconElement) {
		androidIconElement.href = `${favIconUrl}/android-icon-192x192.png`;
	}
};

export enum SUPPORT_BOTS {
	WHATSAPP_BOT = '#whatsAppWidgetSpan',
	ENGATI_BOT = '#engtWrapper',
}

export enum CONVERSATION_MESSAGES_DURATION {
	DAYS_90 = '90',
	DAYS_400 = '400',
}

export const getConversationMessageDuration = (conversationDuration: string, t: any) => {
	switch (conversationDuration) {
		case CONVERSATION_MESSAGES_DURATION.DAYS_400:
			return '13 months';
		default:
			return '90 days';
	}
};

export const checkForSupportBot = (supportBot: string, isSmallDesktopScreen: boolean) => {
	const engatiSupportBot: any = document.querySelector(supportBot);
	const path: string = window.location.pathname;

	if (engatiSupportBot) {
		const excludedPathsToShowBot: Array<string> = [
			...Object.values(NoAuthenticationRouteLinks),
			...Object.values(ShopifyOnboardingRoutes),
		];

		for (let i = 0; i < excludedPathsToShowBot.length; i++) {
			if (path.indexOf(excludedPathsToShowBot[i]) > -1) {
				engatiSupportBot.style.display = 'none';

				return true;
			}
		}

		if (
			path.indexOf(NoColumnRouteLinks.noAccess) > -1 ||
			path.indexOf(NoColumnRouteLinks.noPageFound) > -1 ||
			path.indexOf(NoColumnRouteLinks.verify) > -1 ||
			isSmallDesktopScreen
		) {
			(window as any)?.EngtChat?.close?.();
			engatiSupportBot.style.display = 'none';

			return true;
		}

		engatiSupportBot.style.display = null;

		return true;
	}

	return false;
};

export const generateErrorFileName = (fileName: string) => `${fileName.split('.')[0]}_error.csv`;

export const updatePageTitle = (title?: string) => {
	const titleElement: any = document.getElementsByTagName('title');

	if (titleElement && titleElement.length && title) {
		titleElement[0].innerHTML = title;
	}
};

export const isDevEnvironment = (): boolean =>
	REACT_APP_SUB_DOMAIN === 'qa' ||
	REACT_APP_SUB_DOMAIN === 'dev' ||
	REACT_APP_SUB_DOMAIN === 'localhost' ||
	((window.location.hostname.split('.')[1] === 'quinbox' || window.location.hostname.split('.')[1] === 'quindrive') &&
		window.location.hostname.split('.')[0] !== 'app') ||
	process.env.NODE_ENV === 'development';

export const isEngatiDomain = (): boolean => {
	const engatiSubdomains = ['qa', 'dev', 'localhost', 'app'];

	return engatiSubdomains.includes(REACT_APP_SUB_DOMAIN);
};

export const validateUrl = (url: string) => {
	const urlRegex =
		/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

	return urlRegex.test(url);
};

export const getDateRangedMorningTillNight = (from: Date, to: Date): { from: string; to: string } => {
	let fromDate: string | Date = new Date(from);
	let toDate: string | Date = new Date(to);
	toDate.setHours(23, 59, 59);
	fromDate = new Date(fromDate.toUTCString().slice(0, -4));
	toDate = new Date(toDate.toUTCString().slice(0, -4));

	const startDate = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`;
	const endDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`;
	const startTime = `${fromDate.getHours()}:${fromDate.getMinutes()}:${fromDate.getSeconds()}`;
	const endTime = `${toDate.getHours()}:${toDate.getMinutes()}:${toDate.getSeconds()}`;

	return {
		from: `${startDate}T${startTime}Z`,
		to: `${endDate}T${endTime}Z`,
	};
};

export const templateLanguageCodeMapper = () => ({
	af: 'Afrikaans',
	sq: 'Albanian',
	ar: 'Arabic',
	az: 'Azerbaijani',
	bn: 'Bengali',
	bg: 'Bulgarian',
	ca: 'Catalan',
	zh_CN: 'Chinese (CHN)',
	zh_HK: 'Chinese (HKG)',
	zh_TW: 'Chinese (TAI)',
	hr: 'Croatian',
	cs: 'Czech',
	da: 'Danish',
	nl: 'Dutch',
	en_US: 'English',
	et: 'Estonian',
	fil: 'Filipino',
	fi: 'Finnish',
	fr: 'French',
	de: 'German',
	el: 'Greek',
	gu: 'Gujarati',
	ha: 'Hausa',
	he: 'Hebrew',
	hi: 'Hindi',
	hu: 'Hungarian',
	id: 'Indonesian',
	ga: 'Irish',
	it: 'Italian',
	ja: 'Japanese',
	kn: 'Kannada',
	kk: 'Kazakh',
	ko: 'Korean',
	lo: 'Lao',
	lv: 'Latvian',
	lt: 'Lithuanian',
	mk: 'Macedonian',
	ms: 'Malay',
	ml: 'Malayalam',
	mr: 'Marathi',
	nb: 'Norwegian',
	fa: 'Persian',
	pl: 'Polish',
	pt_BR: 'Portuguese (BR)',
	pt_PT: 'Portuguese (POR)',
	pa: 'Punjabi',
	ro: 'Romanian',
	ru: 'Russian',
	sr: 'Serbian',
	sk: 'Slovak',
	sl: 'Slovenian',
	es: 'Spanish',
	es_AR: 'Spanish (ARG)',
	es_ES: 'Spanish (SPA)',
	es_MX: 'Spanish (MEX)',
	sw: 'Swahili',
	sv: 'Swedish',
	ta: 'Tamil',
	te: 'Telugu',
	th: 'Thai',
	tr: 'Turkish',
	uk: 'Ukrainian',
	ur: 'Urdu',
	uz: 'Uzbek',
	vi: 'Vietnamese',
	zu: 'Zulu',
});

export const waLanguageCodeMapper = () => ({
	af: 'Afrikaans',
	sq: 'Albanian',
	ar: 'Arabic',
	az: 'Azerbaijani',
	bn: 'Bengali',
	bg: 'Bulgarian',
	ca: 'Catalan',
	zh_CN: 'Chinese (CHN)',
	zh_HK: 'Chinese (HKG)',
	zh_TW: 'Chinese (TAI)',
	hr: 'Croatian',
	cs: 'Czech',
	da: 'Danish',
	nl: 'Dutch',
	en: 'English',
	en_GB: 'English (UK)',
	en_US: 'English (US)',
	et: 'Estonian',
	fil: 'Filipino',
	fi: 'Finnish',
	fr: 'French',
	de: 'German',
	el: 'Greek',
	gu: 'Gujarati',
	ha: 'Hausa',
	he: 'Hebrew',
	hi: 'Hindi',
	hu: 'Hungarian',
	id: 'Indonesian',
	ga: 'Irish',
	it: 'Italian',
	ja: 'Japanese',
	kn: 'Kannada',
	kk: 'Kazakh',
	ko: 'Korean',
	lo: 'Lao',
	lv: 'Latvian',
	lt: 'Lithuanian',
	mk: 'Macedonian',
	ms: 'Malay',
	ml: 'Malayalam',
	mr: 'Marathi',
	nb: 'Norwegian',
	fa: 'Persian',
	pl: 'Polish',
	pt_BR: 'Portuguese (BR)',
	pt_PT: 'Portuguese (POR)',
	pa: 'Punjabi',
	ro: 'Romanian',
	ru: 'Russian',
	sr: 'Serbian',
	sk: 'Slovak',
	sl: 'Slovenian',
	es: 'Spanish',
	es_AR: 'Spanish (ARG)',
	es_ES: 'Spanish (SPA)',
	es_MX: 'Spanish (MEX)',
	sw: 'Swahili',
	sv: 'Swedish',
	ta: 'Tamil',
	te: 'Telugu',
	th: 'Thai',
	tr: 'Turkish',
	uk: 'Ukrainian',
	ur: 'Urdu',
	uz: 'Uzbek',
	vi: 'Vietnamese',
	zu: 'Zulu',
});

export const waRtlLanguageCodeMapper = () => ({
	ar: 'Arabic',
	he: 'Hebrew',
	fa: 'Persian',
	ur: 'Urdu',
});

export const attachQueryParameters = () => {
	const params = getUrlParams();
	const paramsMap = new Map();
	const queryString: Array<string> = [];
	paramsMap.set('utm_campaign', params.get('utm_campaign'));
	paramsMap.set('utm_source', params.get('utm_source'));
	paramsMap.set('utm_medium', params.get('utm_medium'));
	paramsMap.set('visitor_id', params.get('visitor_id'));
	paramsMap.set('gclid', params.get('gclid'));
	paramsMap.set('isPreview', params.get('isPreview'));

	for (const [key, value] of Array.from(paramsMap.entries())) {
		if (paramsMap.get(key)) {
			queryString.push(`${key}=${value}`);
		}
	}

	return `?${queryString.join('&')}`;
};

export const SMS_PLATFORMS = ['sms_clickatell', 'sms_twilio'];

export enum WHATSAPP_PROVIDERS {
	'dialog360' = '360Dialog',
	'kaleyra' = 'Kaleyra',
	'nexmo' = 'Vonage (Nexmo)',
	'twilio' = 'Twilio',
	'clickatell' = 'Clickatell',
	'whatsAppCloud' = 'WhatsApp Cloud',
	'whatsAppNative' = 'WhatsApp Native',
	'airtel' = 'Airtel',
}

export enum PLATFORM {
	WHATSAPP = 'whatsapp',
	DIALOG360 = 'dialog360',
	WHATSAPP_CLOUD = 'whatsAppCloud',
}

const getHeaderExcludedHeight = () => {
	if (window.innerWidth < 1135) {
		return `calc(${getResponsiveVH()} - var(--engt-app-header-height))`;
	}

	return getResponsiveVH();
};

export const getBannerExcludedPageHeight = (bannerHeight: number, excessHeightToExclude?: string) => {
	if (excessHeightToExclude) {
		return `calc( ${getHeaderExcludedHeight()} - var(--engt-app-banner-height) - ${excessHeightToExclude} )`;
	}

	return `calc( ${getHeaderExcludedHeight()} - var(--engt-app-banner-height) )`;
};

export const updateCSRFToken = (token?: string) => {
	const csrfMetaTag: any = document.querySelector('meta[name="csrf-token"]');
	if (csrfMetaTag && token) {
		csrfMetaTag.content = token;
	}
};

export const isValidJson = (json: any) => {
	try {
		if (!isNaN(parseInt(json))) {
			return false;
		}
		JSON.parse(json);
	} catch (e) {
		return false;
	}

	return true;
};

export const CHANNELS_WITH_CHAT_WINDOW_LIMIT = [
	'nexmo',
	'clickatel',
	'twilio',
	'kaleyra',
	'dialog360',
	'facebook',
	'instagram',
	'whatsapp',
];

export const CHANNELS_WITH_7_DAYS_CHAT_WINDOW_LIMIT = ['facebook', 'instagram'];

export const CHANNELS_WITH_COMMENTS_SUPPORT = ['instagram', 'facebook'];

export const ANALYTICS_CSS = {
	minHeight: '2rem',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingRight: '0.5rem',
	paddingLeft: '0.5rem',
	marginBottom: '0.3125rem',
	fontSize: '0.875rem',
	lineHeight: '1rem',
	border: '0.0625rem',
	borderStyle: 'solid',
	borderTopLeftRadius: '0.5rem',
	borderTopRightRadius: '0.5rem',
	borderBottomLeftRadius: '0.5rem',
	borderBottomRightRadius: '0.5rem',
	boxShadow: ' 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.08)',
	backdropFilter: 'blur(0.25rem)',
	gap: '0.5rem',
};

export const CARD_CSS = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'space-between',
	justifyContent: 'flex-start',
	paddingRight: '1.125rem',
	paddingLeft: '1.125rem',
	paddingTop: '0.75rem',
	paddingBottom: '0.75rem',
	fontSize: '0.875rem',
	lineHeight: '1rem',
	borderStyle: 'none',
	borderTopLeftRadius: '0.5rem',
	borderTopRightRadius: '0.5rem',
	borderBottomLeftRadius: '0.5rem',
	borderBottomRightRadius: '0.5rem',
	boxShadow: ' 0rem 0.0625rem 0.125rem rgba(0, 0, 0, 0.08)',
	backdropFilter: 'blur(0.25rem)',
	boxSizing: 'border-box',
};

export enum RESTORE_VERSION {
	RESTORE_LIVE = 'LIVE',
	RESTORE_DRAFT = 'DRAFT',
	RESTORE_LIVE_DRAFT = 'LIVE_AND_DRAFT',
}

export const getBotRef = (bot: any, isLiveDraftEntitlement: boolean) =>
	bot?.draft_bot_ref ? (isLiveDraftEntitlement ? bot.draft_bot_ref : bot.live_bot_ref) : bot.bot_ref;

export enum BOT_MODE {
	'LIVE' = 'LIVE',
	'DRAFT' = 'DRAFT',
	'MASTER' = 'MASTER',
	'BACKUP' = 'BACKUP',
}

export const isValidDate = (d: any) => !isNaN(d) && d instanceof Date;

export const getProperDate = (date: Date): string =>
	`${date.getFullYear()}-${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
		date.getDate() <= 9 ? `0${date.getDate()}` : date.getDate()
	}`;

export const getResponsiveVH = (() => {
	const root: any = document.querySelector(':root');
	root.style.setProperty('--engt-app-height', `${window.innerHeight}px`);

	window.addEventListener('resize', () => {
		const headerBar = document.getElementById('engtHeader');
		const portalBanner = document.getElementById('portal-banner');

		if (headerBar) {
			root.style.setProperty('--engt-app-header-height', `${headerBar.clientHeight}px`);
		}
		if (portalBanner) {
			root.style.setProperty('--engt-app-banner-height', `${portalBanner.clientHeight}px`);
		}

		root.style.setProperty('--engt-app-height', `${window.innerHeight}px`);
	});

	return (vh?: number) => {
		const height = 'var(--engt-app-height)';

		return `calc(${height} * ${(vh ?? 100) / 100})`;
	};
})();

export const isSafari = () => navigator.vendor.indexOf('Apple') >= 0;

export const safariVersion = () => {
	try {
		const version = navigator.userAgent?.split('Version/')?.[1]?.split(' Safari/')[0] || '0';

		return parseFloat(version);
	} catch (e) {
		console.error('Error getting safari version number');

		return 0;
	}
};

export const highestCoordinateValue = (nodeMap: any, xCoordinate: boolean, yCoordinate: boolean) => {
	let highestValue = 10000;
	if (xCoordinate) {
		Object.keys(nodeMap).map((key: any) => {
			if (highestValue < nodeMap[key].xCoordinate) {
				highestValue = nodeMap[key].xCoordinate;
			}
		});
	} else {
		Object.keys(nodeMap).map((key: any) => {
			if (highestValue < nodeMap[key].yCoordinate) {
				highestValue = nodeMap[key].yCoordinate;
			}
		});
	}

	return highestValue > 10000 ? highestValue + 500 : highestValue;
};

export const convertSizeIntoMb = (size: any) => {
	if (size) {
		const convertedSize = (size / (1024 * 1024)).toFixed(2);
		if (convertedSize === '0.00') {
			return '(0.01 MB)';
		}

		return `(${convertedSize} MB)`;
	}

	return '';
};

export enum NODE_CATEGORIES {
	display_info = 'Display Info',
	data_input = 'Data Input',
	process_and_flow = 'Process and Flow',
	integrations = 'Integrations',
}
export enum CATEGORY_COLORS {
	display_info = '#EE5D0B',
	data_input = '#77CCD1',
	process_and_flow = '#A1D946',
	integrations = '#FFAC0A',
}

export const getNodeColorByCategory = (category: string) => {
	switch (category) {
		case NODE_CATEGORIES.display_info:
			return CATEGORY_COLORS.display_info;
		case NODE_CATEGORIES.data_input:
			return CATEGORY_COLORS.data_input;
		case NODE_CATEGORIES.process_and_flow:
			return CATEGORY_COLORS.process_and_flow;
		case NODE_CATEGORIES.integrations:
			return CATEGORY_COLORS.integrations;
		default:
			return '#FFAC0A';
	}
};

export const validateFileName = (fileName: string) => fileName.replaceAll(/[/\\]/g, '_');

export const getNearestTime = (date: Date) => {
	const modifiedDate = date || new Date();
	const minutes = 15;
	const ms = 1000 * 60 * minutes;

	return new Date(Math.ceil(modifiedDate.getTime() / ms) * ms);
};

export const getTimeFromDate = (date: Date | string = '') => {
	const data = isValidDate(new Date(date)) ? new Date(date) : new Date();
	let minutes = `${data.getMinutes()}`;
	let hours = `${data.getHours()}`;

	if (parseInt(hours) < 10) {
		hours = `0${hours}`;
	}
	if (parseInt(minutes) < 10) {
		minutes = `0${minutes}`;
	}

	return `${hours}:${minutes}`;
};

export const setTimeToDate = (time = '00:00', date: Date | string = '') => {
	const modifiedDate = isValidDate(new Date(date)) ? new Date(date) : new Date();
	const timeValue = time.split(':');

	modifiedDate.setHours(timeValue?.[0] ? parseInt(timeValue[0]) : 0);
	modifiedDate.setMinutes(timeValue?.[1] ? parseInt(timeValue[1]) : 0);

	return modifiedDate;
};

export const convertSecondsToHHMMSSFormat = (seconds: any) => {
	const t: any = new Date(1970, 0, 1);
	t.setSeconds(seconds);
	let s = t.toTimeString().substr(0, 8);
	if (seconds > 86399) {
		s = Math.floor((t - Date.parse('1/1/70')) / 3600000) + s.substr(2);
	}

	return s;
};

export const timeDiffLessThan24Hours = (data: Date) => Date.now() - new Date(data).getTime() > 60 * 60 * 24 * 1000;

export const channelTitleFromMapping = (channels: Array<string>) =>
	channels.map((channel: string) => CHANNEL_TITLE_MAPPING[channel]);

export const contentEditablePlaceholder = (questionId: string) => {
	const ele: any = document.getElementById(questionId);

	const placeholder: any = ele?.getAttribute('data-placeholder');

	if (ele) {
		ele.innerHTML === '' && (ele.innerHTML = placeholder);
	}

	const focusListener = (e: any) => {
		const value = e.target.innerHTML;

		e.target.style.color = 'inherit';
		e.target.style.fontSize = '1rem';

		value === placeholder && (e.target.innerHTML = '');
	};

	const blurListener = (e: any) => {
		const value = e.target.innerHTML;

		if (value === '') {
			e.target.innerHTML = placeholder;
			e.target.style.color = '#AFAFAF';
			e.target.style.fontSize = '0.875rem';
		}
	};

	if (ele) {
		ele.addEventListener('focus', focusListener);
		ele.addEventListener('blur', blurListener);
	}

	return () => {
		ele && ele.removeEventListener('focus', focusListener);
		ele && ele.removeEventListener('blur', blurListener);
	};
};

export enum CALL_TYPE {
	C2E = 'C2E',
}

export const getTimeZone = () => {
	const now = new Date().toString();
	const timeZone = now
		.replace(/.*[(](.*)[)].*/, '$1')
		.split(/\s/)
		.reduce((response, word) => (response += word.slice(0, 1)), '');

	return timeZone;
};

export const DEFAULT_LAST_MESSAGE_FILTER = (t: any) => [
	{
		label: t('components:messageFilter.bot'),
		id: DEFAULT_LAST_MESSAGE_FILTER_IDS.BOT,
	},
	{
		label: t('components:messageFilter.agent'),
		id: DEFAULT_LAST_MESSAGE_FILTER_IDS.AGENT,
	},
	{
		label: t('components:messageFilter.user'),
		id: DEFAULT_LAST_MESSAGE_FILTER_IDS.USER,
	},
];

export const toSnakeCase = (text: string) => {
	const upperToHyphenLower = (match: string, offset: number) => (offset > 0 ? '_' : '') + match.toLowerCase();

	return text.replace(/[A-Z]/g, upperToHyphenLower);
};

export const toCamelCase = (text: string) => {
	const hyphenLowerToUpper = (match: string) => match.charAt(1).toUpperCase();

	return text.replace(/_[a-z]/g, hyphenLowerToUpper);
};

export const toSpaceSeperatedPascalCase = (text: string) => {
	const underscoreLowerToSpacedUpper = (match: string) => ` ${match.charAt(1).toUpperCase()}`;
	text = text.toLowerCase();

	return text.charAt(0).toUpperCase() + text.substring(1).replace(/_\w/g, underscoreLowerToSpacedUpper);
};

export enum CampaignTimings {
	direct = 'DIRECT',
	flow = 'FLOW',
	immediate = 'IMMEDIATE',
	delay = 'DELAY',
	any = 'ANY',
	days = 'DAYS',
}

export const getFileFormats = () => ['.pdf', 'application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];

export const timeIntervalExceedsThreeHours = (data: Date) =>
	Date.now() - new Date(data).getTime() >= 60 * 60 * 3 * 1000;

const MILLISECONDS_IN_DAY = 86400000;

export const timeIntervalExceedsFiveDays = (data: string) =>
	Date.now() - new Date(data).getTime() >= 5 * MILLISECONDS_IN_DAY;

export const timeIntervalExceedsSevenDays = (data: string) =>
	Date.now() - new Date(data).getTime() >= 7 * MILLISECONDS_IN_DAY;

export const showBrowserNotifPermissionModal = (): Promise<{ status: boolean }> =>
	new Promise((resolve) => {
		const Notification =
			window.Notification || (window as any).mozNotification || (window as any).webkitNotification;
		if (Notification.permission !== 'granted') {
			Notification.requestPermission(function (permission) {
				if ('permissions' in navigator) {
					navigator.permissions.query({ name: 'notifications' }).then(function (notifyPermission) {
						notifyPermission.onchange = function () {
							if (notifyPermission.state === 'granted') {
								resolve({ status: true });
							} else {
								resolve({ status: false });
							}
						};
					});
				}
			});
		} else {
			resolve({ status: true });
		}
	});

export const isWindows = () => {
	const { userAgent } = navigator;
	const isWindowsOS = /Windows/.test(userAgent);

	return isWindowsOS;
};

export const getCaseInsensitiveRegex = (word: string) => new RegExp(word, 'i');

export enum templateMessageformFieldIds {
	ID = 'messageTemplate',
	BODY_TEXT = 'bodyText',
	BUTTONS = 'buttons',
	HEADER_CHECKBOX = 'headerCheckbox',
	BODY_CHECKBOX = 'bodyCheckbox',
	LTO_HEADER = 'ltoHeader',
	LTO_OFFER_SECTION = 'ltoOfferSection',
	LTO_OFFER_TITLE = 'ltoOfferTitle',
	LTO_OFFER_CODE = 'ltoOfferCode',
	LTO_EXPIRATION_TOGGLE = 'ltoExpirationToggle',
	FOOTER_CHECKBOX = 'footerCheckbox',
	BUTTONS_CHECKBOX = 'buttonsCheckbox',
	BUTTON_TYPE = 'type',
	BUTTON_LABEL = 'label',
	CAROUSEL_TEXT_MESSAGE = 'carouselTextMessage',
	CAROUSEL_CARD_HEADER = 'carouselCardHeader',
	CAROUSEL_BODY_TEXT = 'carouselBodyText',
	CAROUSEL_BUTTONS = 'carouselButtons',
	BUTTON_VALUE = 'value',
	CATEGORY = 'category',
	FOOTER_INPUT = 'footerInput',
	TEXT_HEADER_INPUT = 'headerInputValue',
	HEADER_TYPE = 'headerType',
	LANGUAGE = 'language',
	CONTENT = 'content',
	TARGETED_CHANNELS = 'targetedChannels',
	TEMPLATE_NAME = 'templateName',
	TEMPLATE_TYPE = 'templateType',
	ENABLE_OPT_OUT = 'enbaleOptOut',
	CALCULATE_ANALYTICS = 'calculateAnalytics',
	CAROUSEL_COMPONENTS = 'carouselComponents',
	PARAMETERS = 'parameters',
	WHATSAPP_CLOUD_MEDIA = 'whatsappCloudMedia',
}

export enum LOG_STATUS {
	FAILURE = 'FAILURE',
	SUCCESS = 'SUCCESS',
	OK = 'OK',
	TIMEOUT = 'Timeout',
	SSL_ERROR = 'SSL',
	DOMAIN_INCORRECT = 'Domain',
	ERROR = 'ERROR',
	CL_ERROR = 'Client API Error',
	SSL_HANDSHAKE_ERROR = 'SSL Error',
}
export const MAX_ALLOWED_CHARS_IN_RESPONSE_BODY = 50_000;
export const MAX_ALLOWED_CHARS_IN_REQUEST_URL = 50;

export const getExtension = (d: any) => {
	const lastIndexOf = d?.name?.lastIndexOf('.');
	const extension = d?.name?.substring(lastIndexOf);

	return extension.toLowerCase();
};

export const setTemplateDataForD360 = (setTemplates: any, fetchedTemplatesData: any) => {
	setTemplates(
		fetchedTemplatesData?.map(({ name, components, namespace, language }: IObjProps) => ({
			name,
			components,
			namespace,
			language,
		}))
	);
};

export const getCountryCode = (botTimeZone: any, uid: any) => {
	const getCountryCodeMapper = timeZoneToCountryCode();
	if (readFromLs(LsKeysMapping(LS_KEYS.COUNTRY_CODE, uid))) {
		return JSON.parse(readFromLs(LsKeysMapping(LS_KEYS.COUNTRY_CODE, uid)) || '');
	}
	if (getCountryCodeMapper[botTimeZone]) {
		return [getCountryCodeMapper[botTimeZone]];
	}

	return [{ id: '+1', label: '🇺🇸 +1' }];
};

export const additionalBotDetailsTimezone = [
	{
		id: 'Etc/GMT+12',
		label: 'Etc/GMT+12 (GMT -12:00)',
		offset: 720,
	},
	{
		id: 'Etc/GMT+11',
		label: 'Etc/GMT+11 (GMT -11:00)',
		offset: 660,
	},
	{
		id: 'Etc/GMT+10',
		label: 'Etc/GMT+10 (GMT -10:00)',
		offset: 600,
	},
	{
		id: 'HST',
		label: 'HST - HST (GMT -10:00)',
		offset: 600,
	},
	{
		id: 'Etc/GMT+9',
		label: 'Etc/GMT+9 (GMT -09:00)',
		offset: 540,
	},
	{
		id: 'Etc/GMT+8',
		label: 'Etc/GMT+8 (GMT -08:00)',
		offset: 480,
	},
	{
		id: 'Etc/GMT+7',
		label: 'Etc/GMT+7 (GMT -07:00)',
		offset: 420,
	},
	{
		id: 'MST',
		label: 'MST - MST (GMT -07:00)',
		offset: 420,
	},
	{
		id: 'PST8PDT',
		label: 'PDT - PST8PDT (GMT -07:00)',
		offset: 420,
	},
	{
		id: 'Etc/GMT+6',
		label: 'Etc/GMT+6 (GMT -06:00)',
		offset: 360,
	},
	{
		id: 'MST7MDT',
		label: 'MDT - MST7MDT (GMT -06:00)',
		offset: 360,
	},
	{
		id: 'CST6CDT',
		label: 'CDT - CST6CDT (GMT -05:00)',
		offset: 300,
	},
	{
		id: 'EST',
		label: 'EST - EST (GMT -05:00)',
		offset: 300,
	},
	{
		id: 'Etc/GMT+5',
		label: 'Etc/GMT+5 (GMT -05:00)',
		offset: 300,
	},
	{
		id: 'America/Fort_Wayne',
		label: 'EDT - America/Fort Wayne (GMT -04:00)',
		offset: 240,
	},
	{
		id: 'EST5EDT',
		label: 'EDT - EST5EDT (GMT -04:00)',
		offset: 240,
	},
	{
		id: 'Etc/GMT+4',
		label: 'Etc/GMT+4 (GMT -04:00)',
		offset: 240,
	},
	{
		id: 'Etc/GMT+3',
		label: 'Etc/GMT+3 (GMT -03:00)',
		offset: 180,
	},
	{
		id: 'America/Godthab',
		label: 'America/Godthab (GMT -02:00)',
		offset: 120,
	},
	{
		id: 'Etc/GMT+1',
		label: 'Etc/GMT+1 (GMT -01:00)',
		offset: 60,
	},
	{
		id: 'Etc/GMT-0',
		label: 'GMT - Etc/GMT-0 (GMT +00:00)',
		offset: 0,
	},
	{
		id: 'Etc/UTC',
		label: 'UTC - Etc/UTC (GMT +00:00)',
		offset: 0,
	},
	{
		id: 'Etc/GMT-1',
		label: 'Etc/GMT-1 (GMT +01:00)',
		offset: -60,
	},
	{
		id: 'WET',
		label: 'WEST - WET (GMT +01:00)',
		offset: -60,
	},
	{
		id: 'CET',
		label: 'CEST - CET (GMT +02:00)',
		offset: -120,
	},
	{
		id: 'Etc/GMT-2',
		label: 'Etc/GMT-2 (GMT +02:00)',
		offset: -120,
	},
	{
		id: 'MET',
		label: 'MEST - MET (GMT +02:00)',
		offset: -120,
	},
	{
		id: 'EET',
		label: 'EEST - EET (GMT +03:00)',
		offset: -180,
	},
	{
		id: 'Europe/Kiev',
		label: 'EEST - Europe/Kiev (GMT +03:00)',
		offset: -180,
	},
	{
		id: 'Etc/GMT-3',
		label: 'Etc/GMT-3 (GMT +03:00)',
		offset: -180,
	},
	{
		id: 'Etc/GMT-4',
		label: 'Etc/GMT-4 (GMT +04:00)',
		offset: -240,
	},
	{
		id: 'Etc/GMT-5',
		label: 'Etc/GMT-5 (GMT +05:00)',
		offset: -300,
	},
	{
		id: 'Etc/GMT-6',
		label: 'Etc/GMT-6 (GMT +06:00)',
		offset: -360,
	},
	{
		id: 'Asia/Rangoon',
		label: 'Asia/Rangoon (GMT +06:30)',
		offset: -390,
	},
	{
		id: 'Etc/GMT-8',
		label: 'Etc/GMT-8 (GMT +08:00)',
		offset: -480,
	},
	{
		id: 'Etc/GMT-9',
		label: 'Etc/GMT-9 (GMT +09:00)',
		offset: -540,
	},
	{
		id: 'Etc/GMT-11',
		label: 'Etc/GMT-11 (GMT +11:00)',
		offset: -660,
	},
	{
		id: 'Etc/GMT-12',
		label: 'Etc/GMT-12 (GMT +12:00)',
		offset: -720,
	},
	{
		id: 'Etc/GMT-13',
		label: 'Etc/GMT-13 (GMT +13:00)',
		offset: -780,
	},
	{
		id: 'Pacific/Enderbury',
		label: 'Pacific/Enderbury (GMT +13:00)',
		offset: -780,
	},
	{
		id: 'Etc/GMT-14',
		label: 'Etc/GMT-14 (GMT +14:00)',
		offset: -840,
	},
];

export const isMobile = () => {
	const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

	return regex.test(navigator.userAgent);
};

export const getDeviceBrowser = () => {
	const { userAgent } = navigator;

	if (userAgent.match(/chrome|chromium|crios/i)) {
		return 'Chrome';
	}
	if (userAgent.match(/firefox|fxios/i)) {
		return 'Firefox';
	}
	if (userAgent.match(/safari/i)) {
		return 'Safari';
	}
	if (userAgent.match(/opr/i)) {
		return 'Opera';
	}
	if (userAgent.match(/edg/i)) {
		return 'Edge';
	}

	return 'No browser detection';
};

export const getEnvironment = () => {
	const originLocation = window.location.origin;
	if (originLocation.indexOf('localhost') > -1 || originLocation.indexOf('dev.engati') > -1) {
		return 'dev';
	}
	if (originLocation.indexOf('qa.engati') > -1) {
		return 'qa';
	}
	if (originLocation.indexOf('app.engati') > -1) {
		return 'app';
	}

	return 'p';
};

export const getEnvironmentTag = () => {
	const originLocation = window.location.origin;

	if (
		originLocation.indexOf('localhost') > -1 ||
		originLocation.indexOf('dev.engati') > -1 ||
		originLocation.indexOf('qa.engati') > -1
	) {
		return ENVIRONMENT_TAGS.STAGING;
	}

	if (originLocation.indexOf('app.engati') > -1) {
		return ENVIRONMENT_TAGS.PRODUCTION;
	}

	// For WL partners it will always return ENVIRONMENT_TAGS.PRODUCTION to show only PRODUCTION ready integrations
	return ENVIRONMENT_TAGS.PRODUCTION;
};
export const removeLeadingZeros = (number: string) => number.replace(/^0+/, '');

export const parsePhoneNumber = (number: string) => {
	if (number) {
		return parseDigits(removeLeadingZeros(number));
	}
};

export const isTestEmail = (email: string) => email?.includes('entest') || email?.includes('engati');

export const isActualCustomer = (utmCampaign: string) => utmCampaign && utmCampaign !== INTERNAL_TESTING_UTM_CAMPAIGN;

export const generateRandomNumber = (max: number, min: number) => Math.round(Math.random() * (max - min + 1)) + min;

export const getPunctuation = (options: any[], index: number, t: any) => {
	switch (index) {
		case options.length - 2:
			return ` ${t('common:and')} `;
		case options.length - 1:
			return '';
		default:
			return ', ';
	}
};

export const decodeAndSanitizeInput = (inputValue: any, config?: Config) => {
	if (!inputValue) {
		return inputValue;
	}
	const decodedInput = decode(inputValue.toString());
	const sanitizedInput = config
		? (DOMPurify.sanitize(decodedInput, config) as string)
		: DOMPurify.sanitize(decodedInput);

	return decode(sanitizedInput);
};

export const getPhoneNumberFromString = (numberString: string) => numberString.replace(/\D/g, '');

export const isLlmFeatureBasicOrAdvance = (llmFeatureName: string) =>
	llmFeatureName === LLM_FEATURE_TYPES.BASIC || llmFeatureName === LLM_FEATURE_TYPES.ADVANCE;
