import { IObjProps } from 'shared/consts/types';

export const redirectFromModalAlert = (supportEmailOrLink: IObjProps) => {
	if (supportEmailOrLink?.type === 'email') {
		window.location.href = `mailto:${supportEmailOrLink.value}`;
	}
	if (supportEmailOrLink?.type === 'link') {
		window.open(supportEmailOrLink.value, '_blank')?.focus();
	}
};
