import { memo } from 'react';
import { useStyletron } from 'baseui';
import { KIND, SIZE } from 'baseui/button';
import { useTranslation } from 'react-i18next';

import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import Divider from 'components/UserProfileHeader/component/Divider';

import { DOC_LINKS_V2 } from 'shared/consts/DockLinks';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

const WatchTutorialBtn = () => {
	const [css, theme]: [any, any] = useStyletron();
	const { t } = useTranslation(['common', 'pages']);

	const { isSmallDesktopScreen } = useResponsiveSize();

	const watchTutorialBtnCss = {
		fontWeight: 600,
		fontSize: '0.75rem',
		lineHeight: '1rem',
		color: theme.colors.accent,
		backgroundColor: `${theme.colors.buttonFadedHightlight} !important`,
		paddingTop: '0.438rem',
		paddingRight: '0.75rem',
		paddingBottom: '0.313rem',
		paddingLeft: '0.75rem',
		borderRadius: '0.25rem',
		cursor: 'pointer',
		marginLeft: `${isSmallDesktopScreen ? '1rem' : '2.5rem'}  !important`,
	};

	const onClickWatchTutorial = () => {
		window.open(DOC_LINKS_V2.train.platformTutorials, '_blank');
	};

	return (
		<>
			<ENGTButton
				size={SIZE.compact}
				kind={KIND.tertiary}
				label={t(`common:watchTutorials`)}
				className={css(watchTutorialBtnCss)}
				onClick={onClickWatchTutorial}
			/>
			<Divider />
		</>
	);
};

export default memo(WatchTutorialBtn);
