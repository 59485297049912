import BaseService from 'services/baseService';
import { REACT_APP_API_GATEWAY_BASE_URL } from 'shared/config/config';
import { serialize } from 'shared/helpers';

const genericProxyRequestUrl = '/v2/genericProxyRequest/';
const getAllIntegrationsUrl = '/getIntegrations';
const getIntegrationAccountDetailsUrl = '/getIntegrationAccountDetails';
const getConnectedFlowUrl = '/getConnectedFlow';

const createGoogeAdminAccountApi = new BaseService('OAuthBegin/GOOGLE_ADMIN');
const genericProxyApi = new BaseService(genericProxyRequestUrl);
const getAllIntegrationsApi = new BaseService(getAllIntegrationsUrl);
const getIntegrationAccountDetailsApi = new BaseService(getIntegrationAccountDetailsUrl);
const getConnectedFlowApi = new BaseService(getConnectedFlowUrl);
const deleteIntegryAccountApi = new BaseService(`${genericProxyRequestUrl}?targetApi=deleteIntegryAccount`);
const apiGatetWayGeneralProxyApi = new BaseService(REACT_APP_API_GATEWAY_BASE_URL);

export const CloudIntegrationsApi = {
	saveShopifyIntegration(params, data) {
		return genericProxyApi.postRequest(data, serialize(params, '?'));
	},
	saveWoocommerceIntegration(params, data) {
		return genericProxyApi.postRequest(data, serialize(params, '?'));
	},
	saveShiprocketIntegration(params, data) {
		return genericProxyApi.postRequest(data, serialize(params, '?'));
	},
	saveRazorpayIntegration(params, data) {
		return genericProxyApi.postRequest(data, serialize(params, '?'));
	},
	getShopifyIntegrationAccounts(params) {
		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	getWoocommerceIntegrationAccounts(params) {
		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	getShiprocketIntegrationAccounts(params) {
		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	getRazorpayIntegrationAccounts(params) {
		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	getAllIntegrations(params) {
		return getAllIntegrationsApi.getRequest(serialize(params, '?'));
	},
	updateShopifyIntegration(params, data) {
		return genericProxyApi.patchRequest(data, serialize(params, '?'));
	},
	deleteShopifyIntegration(params) {
		return genericProxyApi.deleteRequest(serialize(params, '?'));
	},
	updateWoocommerceIntegration(params, data) {
		return genericProxyApi.patchRequest(data, serialize(params, '?'));
	},
	deleteWoocommerceIntegration(params) {
		return genericProxyApi.deleteRequest(serialize(params, '?'));
	},
	deleteShiprocketIntegration(params) {
		return genericProxyApi.deleteRequest(serialize(params, '?'));
	},
	deleteRazorpayIntegration(params) {
		return genericProxyApi.deleteRequest(serialize(params, '?'));
	},
	updateRazorpayAccount(params, data) {
		return genericProxyApi.putRequest(data, serialize(params, '?'));
	},
	getIntegrationAccountDetails(payload) {
		return getIntegrationAccountDetailsApi.putRequest(payload);
	},
	updateZendeskDefaultAccount(payload) {
		const defaultIntegrationAccount = new BaseService('markDefault');

		return defaultIntegrationAccount.patchRequest(payload);
	},
	updateAgentAccountConfiguration(payload) {
		const updateAIntegrationStatus = new BaseService('updateIntegrationStatus');

		return updateAIntegrationStatus.patchRequest(payload);
	},
	updateBotMappedIntegrationAccount(payload) {
		const params = {
			targetApi: 'upsertBotMappedIntegrationAccount',
		};

		return genericProxyApi.putRequest(payload, serialize(params, '?'));
	},
	saveIntegrationAccount(payload) {
		const saveUserAccount = new BaseService('saveUserAccount');

		return saveUserAccount.putRequest(payload);
	},
	deleteIntegrationAccount(params) {
		const deleteIntegrationAccount = new BaseService('deleteAccount');

		return deleteIntegrationAccount.deleteRequest(serialize(params, '?'));
	},
	updateIntegrationAccount(payload) {
		const upadteIntegrationAccount = new BaseService('updateAccount');

		return upadteIntegrationAccount.putRequest(payload);
	},
	getConnectedFlow(params) {
		return getConnectedFlowApi.getRequest(serialize(params, '?'));
	},
	saveGoogleSheetsAccount() {
		const createGoogeSheetAccount = new BaseService('OAuthBegin/GOOGLE_SHEETS');

		return createGoogeSheetAccount.getRequest();
	},
	saveGoogleCalendarAccount() {
		const createGoogeCalendarAccount = new BaseService('OAuthBegin/GOOGLE_CALENDAR');

		return createGoogeCalendarAccount.getRequest();
	},
	saveGoogleAdminAccount() {
		return createGoogeAdminAccountApi.getRequest();
	},
	saveGorgiasAccount(params) {
		const createGorgiasAccount = new BaseService('OAuthBegin/GORGIAS');

		return createGorgiasAccount.getRequest(serialize(params, '?'));
	},
	saveSalesforceAcccount() {
		const salesforceOauthUrl = new BaseService('OAuthBegin/SALESFORCE');

		return salesforceOauthUrl.getRequest();
	},
	saveGA4Account() {
		const createGA4Account = new BaseService('OAuthBegin/GA4');

		return createGA4Account.getRequest();
	},
	sendPaymentRequestMail(params, data) {
		return genericProxyApi.postRequest(data, serialize(params, '?'));
	},
	updateIntegrationCustomerConfigMap(params, data) {
		return genericProxyApi.postRequest(data, serialize(params, '?'));
	},
	getExternalIntegrations(params) {
		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	getConnectedUsers(params) {
		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	getHashId(params) {
		return genericProxyApi.getRequest(serialize(params, '?'));
	},
	sendCustomerAuth(data) {
		const params = {
			targetApi: 'customerAuth',
		};
		return genericProxyApi.postRequest(data, serialize(params, '?'));
	},
	deleteIntegryAccount(data) {
		return deleteIntegryAccountApi.deleteRequestWithBody(data);
	},
	getEnabledIntegrations(params) {
		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	getSegments() {
		const params = { targetApi: 'integrationSegments' };
		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	getCategory() {
		const params = { targetApi: 'integrationCategory' };
		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	getIntegrationDetails(params) {
		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
	getConnectedBotsInfo(botRefs) {
		const botRefsList = Array.isArray(botRefs) ? botRefs.join(',') : '';

		const params = {
			targetApi: 'getBotsAutoTranslateStatus',
			botRefs: botRefsList,
		};
		return apiGatetWayGeneralProxyApi.getRequest(serialize(params, '?'));
	},
};
