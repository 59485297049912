import { WA_ENGATI_TSP } from 'shared/consts/consts';

import { RootState } from 'store/rootReducer';

export const customerIdSelector = (state: RootState) => state.User.botAdmin.data.cid;

export const userId = (state: RootState) => state.User.botAdmin.data.uid;

export const exportbroadcastLevelAnalytics = (state: RootState) =>
	state.User.botAdmin.data.export_broadcast_level_analytics;

export const isMessageSearchEnabled = (state: RootState) => state.User.botAdmin.data.is_message_search_enabled;

export const isRecentMessageFirstSort = (state: RootState) => state.User.botAdmin.data.enable_recent_message_first_sort;

export const categoriesData = (state: RootState) => state.User.categoriesMap;

export const botAdminValue = (state: RootState) => state.User.botAdmin.data;

export const userName = (state: RootState) => state.User.profile.data.name;

export const phoneNumberSelector = (state: RootState) => state.User.profile.data.userPhoneNumber;

export const editAllowedForAdditionalAttr = (state: RootState) =>
	state.User.security.data.is_agent_edit_custom_attributes;

export const isEngatiBrandSelector = (state: RootState) => state.User.botAdmin.data.is_engati_brand;

export const brandingPlatformNameSelector = (state: RootState) => state.User.botAdmin.data?.branding_platform_name;

export const enableWaSandboxBroadcastTrial = (state: RootState) =>
	state.User.botAdmin.data.enable_wa_sandbox_broadcast_trial;

export const supportEmailOrLinkData = (state: RootState) => state.User.botAdmin.data.support_email;

export const userPhoneNumber = (state: RootState) => state.User.profile.data.userPhoneNumber;

export const currentPlan = (state: RootState) => state.User.botAdmin.data.customerPlan;

export const isMetaSelectedAsWhatsappTSP = (state: RootState) =>
	state.User.botAdmin.data.wa_engati_tsp === WA_ENGATI_TSP.META;
