import { toaster } from 'baseui/toast';
import { NavigateFunction } from 'react-router-dom';

import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';

import {
	ACCOUNT_TYPES,
	DEFAULT_LANGUAGE_CODE,
	ENGATI_RESPONSE_CODE,
	ENGATI_ROLES,
	MESSAGE_SENDER_MAP,
	MESSAGE_STATE_MAP,
	MESSAGE_STATES,
	MESSAGE_TAB_HEADERS_MAP,
	MESSAGE_TYPE_MAP,
	SCROLL_TYPE,
	TRANSLATED_LAST_MESSAGE_STATE,
	TRANSLATION_STATUS,
	WHATSAPP_PLATFORMS,
} from 'shared/consts/consts';
import { IEmailAttachmentsData, IGetUserMessagesAPIProps, IObjProps } from 'shared/consts/types';
import { CHANNELS, SORTING } from 'shared/enum';
import {
	BOT_MODE,
	CHANNELS_WITH_7_DAYS_CHAT_WINDOW_LIMIT,
	CHANNELS_WITH_CHAT_WINDOW_LIMIT,
	CHANNELS_WITH_COMMENTS_SUPPORT,
	initialsCanvasData,
	isEmpty,
	isEmptyString,
	isValidResponseObject,
	isValidResponseObjectForUserAPI,
	uuidGenerator,
} from 'shared/helpers';

import { jitsiActions } from 'store/App/Jitsi';
import { removeNotificationAction } from 'store/App/Notifications';
import { setEndUserTranslationLanguageAction } from 'store/Message';
import { messageDetailsAPI } from 'store/Message/messageDetails/api';
import { openErrorSavingModalAction, openInfoModalAction } from 'store/Modal/actions';
import { RootState } from 'store/rootReducer';
import { conversationReadEmitter, currentTab, liveChatStatusChange, starredConversationEmitter } from 'store/Socket';

import { i18nHelper } from 'i18nHelper';

import { messageListActions } from '../messageList/actions';
import { messageListAPI } from '../messageList/api';
import { actions } from '../store';
import {
	fetchLastMessageAction,
	getDraftMessage,
	getFacetsSortFromLs,
	getLiteFacetsSortFromLs,
	getLiteFacetsSortFromLsMyConversation,
	getLiteQuickFiltersFromLS,
	getTranslatedMessagesAction,
	getUserMessagesAction,
	removeMessageCountForIdAction,
	removeSelectedUserFromListAction,
	resolveConversationAction,
	setFacetsSortInLs,
	setSelectedUserAction,
	setTranslationStatusOfMessagesAction,
	setUserListAction,
	updateLastTranslatedMessageStateAction,
	updateLastVisitedRowAction,
	updateMessagesPageNoAction,
	updateMessagesWithTransaltedTextAction,
	updateSelectedUserInUserListAction,
	updateUserInUserListAction,
} from '..';

import { messageOverviewAPI } from './api.js';

const INPUT_MSG_TYPE = {
	TEXT: 'TEXT',
	FILE: 'FILE',
	IMAGE: 'IMAGE',
	OPTIONS: 'OPTIONS',
	TEMPLATE: 'TEMPLATE',
	VIDEO: 'VIDEO',
	AUDIO: 'AUDIO',
};

const TEMPLATE_MESSAGE = 'TEMPLATE_MESSAGE';

const DIALOG360 = 'dialog360';

const {
	addUserMessage,
	setSelectedUser,
	getAgentsByCategory,
	getAgentsByCategoryLoading,
	getLiveChatConfig,
	getLiveChatUsers,
	getQuickReplies,
	getUsersSolarQuery,
	getUserMessages,
	setBringToView,
	setEmailTranscript,
	setStateMessageOverview,
	setUserMessages,
	setLastUserMessage,
	updateLastUserMessage,
	setPathVariables,
	setTranslateIntegration,
	setTranslationStatusOfMessages,
	setTranslationStatusOfAgent,
	setEndUserTranslationLanguage,
	setIsAutoTranslateEnabledInLiveChat,
	setMessagePositionAppend,
	setMessagePositionPrepend,
	updateLastTranslatedMessageState,
	updatePageNo,
	updateLastVisitedRow,
	updateFirstVisitedRow,
	setAgentId,
	setSmartAssistEnablement,
	setSmartAssistOpen,
	setSelectedUserData,
	setUserAttributes,
	setUserAttributesLoading,
	setUserAttributesError,
} = actions;

const isMasterAdmin = localStorage.getItem('loggedInUserAccountType') === ACCOUNT_TYPES.MASTER_ADMIN;

export const processLastSentMessage = (data: IObjProps) => {
	let message = data.message || data.messageContent;
	const messageType = data.messagetype || data.messageType;
	try {
		message = JSON.parse(message);
	} catch {
		message = message;
	}
	switch (messageType) {
		case MESSAGE_TYPE_MAP.broadcast:
			const templateMessage = i18nHelper('components:messagesPreview.templateNotification');

			return templateMessage;
		case MESSAGE_TYPE_MAP.options:
			const text = message?.[0]?.['text'] || '-';

			return text;
		case MESSAGE_TYPE_MAP.carousel:
			return message?.[0]?.title || '-';
		case MESSAGE_TYPE_MAP.agentParticipation:
			return message.PARTICIPATION_MESSAGE;
		case MESSAGE_TYPE_MAP.email:
			let subject = message['subject'];
			subject = subject.split('RE:').pop();

			return subject;
		case MESSAGE_TYPE_MAP.emailReceived:
			let { emailSubject } = data;
			emailSubject = emailSubject?.split('RE:').pop();

			return emailSubject;
		case MESSAGE_TYPE_MAP.identity:
			return message.title || '-';
		case MESSAGE_TYPE_MAP.slider:
			return `Slider Node [${message.min_label.value} - ${message.max_label.value}]`;
		case MESSAGE_TYPE_MAP.webView:
			return message.url_prefix_msg || message.url;
		case MESSAGE_TYPE_MAP.feedBack:
			return message || '-';
		case MESSAGE_TYPE_MAP.storyReceived:
			return 'Instagram Story';
		default:
			return message;
	}
};

export type pair = {
	key: string;
	value?: string;
};

const appendMessageByType = function (type: string, currentTime: any, data: IObjProps) {
	const {
		agent_msg_details,
		fileSize,
		message,
		message_id,
		message_text,
		linked_response,
		translatedMessage,
		isTranslated,
		fetchUserMessage,
		live_chat_respond,
		messageStatus,
		platform,
	} = data || {};
	const { TEXT, FILE, IMAGE, OPTIONS, TEMPLATE, VIDEO, AUDIO } = INPUT_MSG_TYPE;

	let messageData: any = {};
	if ([TEXT, FILE, IMAGE, OPTIONS, TEMPLATE, VIDEO, AUDIO].indexOf(type) > -1) {
		messageData = {
			message,
			messagetype: type,
			messageText: message_text,
			fileSize,
			timestamp: currentTime,
			sender: 'bot',
			is_agent_reply: true,
			messageId: message_id,
			agent_email: agent_msg_details.agent_email,
			translatedMessage,
			isTranslated,
			fetchUserMessage,
			messageStatus,
			platform,
		};
		if (type === TEMPLATE) {
			messageData.platform = DIALOG360;
		}
		if (type === IMAGE) {
			messageData.isNewMessage = true;
			messageData.liveChatRespond = live_chat_respond;
			messageData.message = live_chat_respond[live_chat_respond.length - 1]?.message;
			if (data?.group_id) {
				messageData['group_id'] = data.group_id;
			}
		}
	}
	if (linked_response) {
		messageData['replyId'] = linked_response.linked_event_id;
	}

	return messageData;
};

const processMessageByType = function (indata: IObjProps, userPlatform: any) {
	const processedMessages: Array<IObjProps> = [];
	const { TEXT, OPTIONS, TEMPLATE, IMAGE } = INPUT_MSG_TYPE;
	const platform = userPlatform;

	if (indata.type === OPTIONS) {
		const messageText = { ...indata };
		const messageOptions = { ...indata };
		messageText.message = messageText.message.title;
		messageText.type = TEXT;
		messageOptions.message = JSON.stringify(messageOptions.message.optionDTOS);
		messageOptions.type = OPTIONS;
		processedMessages.push({
			messageData: messageText,
			messageType: TEXT,
		});
		processedMessages.push({
			messageData: messageOptions,
			messageType: OPTIONS,
		});
	} else if (indata.type === TEMPLATE_MESSAGE) {
		if (platform === DIALOG360) {
			indata['messageStatus'] = MESSAGE_STATES.SENDING;
			indata['platform'] = DIALOG360;
		}
		processedMessages.push({
			messageData: indata,
			messageType: TEMPLATE,
		});
	} else if (indata.type === IMAGE && platform !== CHANNELS.WEB) {
		const updatedInData = { ...indata };
		delete updatedInData.live_chat_respond;
		indata.live_chat_respond.forEach((data: IObjProps) => {
			if (data.type === IMAGE) {
				updatedInData['live_chat_respond'] = [{ ...data }];
				updatedInData['group_id'] = indata?.group_id;
			} else if (data.type === TEXT) {
				updatedInData['message'] = data.message;
				updatedInData['type'] = data.type;
				delete updatedInData.live_chat_respond;
			}
			updatedInData['message_id'] = data.message_id;
			if (platform === DIALOG360) {
				updatedInData['messageStatus'] = MESSAGE_STATES.SENDING;
				updatedInData['platform'] = DIALOG360;
			}
			processedMessages.push({
				messageData: { ...updatedInData },
				messageType: data.type,
			});
		});
	} else {
		if (platform === DIALOG360) {
			indata['messageStatus'] = MESSAGE_STATES.SENDING;
			indata['platform'] = DIALOG360;
		}
		processedMessages.push({
			messageData: indata,
			messageType: indata.type,
		});
	}

	return processedMessages;
};

const appendEmailMessage = function (attachments: Array<IEmailAttachmentsData>, data: any) {
	const { message: messageSent, message_id: messageId } = data;
	const {
		senderName,
		supportEmail,
		conversationId,
		contentToken,
		emailSnippet,
		msgId,
		msgReferences,
		inReplyTo,
		subject,
		senderEmail,
		bcc,
		to,
	} = messageSent;
	const messageData: IObjProps = {
		messagetype: MESSAGE_TYPE_MAP.email,
		emailNotificationData: {
			bcc,
			senderEmail,
			to,
			supportEmail,
			conversationId,
			msgId,
			msgReferences,
			inReplyTo,
			senderName,
			subject,
			ccEmails: [],
			fromEmail: `${supportEmail} <${supportEmail}>`,
			toEmails: [],
			id: conversationId,
			username: senderName,
			emailAttachments: attachments,
			contentToken,
			emailSnippet,
		},
		timestamp: new Date().toISOString(),
		sender: MESSAGE_SENDER_MAP.bot,
		is_agent_reply: true,
		event_id: messageId,
	};
	for (let i = 0; i < messageSent.cc.length; i++) {
		messageData.emailNotificationData.ccEmails.push(messageSent.cc[i].address);
	}
	messageData.emailNotificationData.toEmails.push(messageSent.senderEmail);

	return messageData;
};

const updateUserWithChatWindowLimit = function (
	selectedUser: IObjProps,
	messages: any,
	dispatch: any,
	isWhiteLabelPartner: boolean
) {
	if (CHANNELS_WITH_CHAT_WINDOW_LIMIT.indexOf(selectedUser.platform) > -1) {
		const updatedSelectedUser = { ...selectedUser };
		for (let i = messages.length - 1; i >= 0; i--) {
			if (messages[i].sender === 'user') {
				const currentTime = new Date().getTime();
				const lastUserMessageTime = new Date(messages[i].timestamp).getTime();
				updatedSelectedUser.showChatWindowLimitClosed =
					CHANNELS_WITH_7_DAYS_CHAT_WINDOW_LIMIT.includes(selectedUser.platform) && !isWhiteLabelPartner
						? Math.floor((currentTime - lastUserMessageTime) / (1000 * 60 * 60)) >= 24 * 7
						: Math.floor((currentTime - lastUserMessageTime) / (1000 * 60 * 60)) >= 24;
				break;
			}
		}
		if (messages.length) {
			updatedSelectedUser.showNoDirectMessageBanner = showNoDirectMessageBanner(updatedSelectedUser, messages);
		}

		dispatch(setSelectedUserAction(updatedSelectedUser));
	}
};

const showNoDirectMessageBanner = (selectedUser: IObjProps, messages: any) => {
	if (CHANNELS_WITH_COMMENTS_SUPPORT.indexOf(selectedUser.platform) > -1) {
		return !messages.find(
			(message: any) => message.sender === 'user' && message.messagetype !== MESSAGE_TYPE_MAP.COMMENT_RECEIVED
		);
	}

	return false;
};

export var conversationHistory: { name: string; value: Array<pair> }[];
export let whatsappCart: [];

const conversationHistoryLogsAndWhatsappCartIfApplicable = (
	messageData: IObjProps[],
	getState: () => RootState,
	userData: any
) => {
	const sortedMessageData = [...messageData];
	sortedMessageData.sort(function (a: IObjProps, b: IObjProps): any {
		return Date.parse(b.timestamp) - Date.parse(a.timestamp);
	});

	const conversationHistoryLogs = new Map();
	let dateKey;
	let timeKey;
	whatsappCart = [];

	const isWhatsAppCommerceEnabled =
		userData.platform === DIALOG360 && getState().Bot.configData.is_wa_commerce_configured;
	let productCartMessageFetched = false;
	for (let i = 0; i < sortedMessageData.length; i++) {
		if (
			!productCartMessageFetched &&
			isWhatsAppCommerceEnabled &&
			sortedMessageData[i].messagetype === 'CART_RECEIVED'
		) {
			whatsappCart = JSON.parse(sortedMessageData[i].message).productItems;
			productCartMessageFetched = true;
		}

		if (sortedMessageData[i].messagetype === 'AGENT_PARTICIPATION_STATUS') {
			let conversationLogs: Array<pair> = [];
			dateKey = i18nHelper('common:standardDateWithYearFormatter', {
				date: sortedMessageData[i].timestamp,
			});

			timeKey = i18nHelper('common:standardDateTimeFormatter', {
				date: sortedMessageData[i].timestamp,
			});

			const log = { key: timeKey, value: JSON.parse(sortedMessageData[i].message).PARTICIPATION_MESSAGE };
			if (conversationHistoryLogs.has(dateKey)) {
				conversationLogs = conversationHistoryLogs.get(dateKey);
			} else {
				conversationHistoryLogs.set(dateKey, conversationLogs);
			}
			conversationLogs.push(log);
		}
	}
	conversationHistory = Array.from(conversationHistoryLogs, ([name, value]) => ({ name, value }));
};

const extractMessages = function (messages: any, isUserMessagesDataAppended: boolean, firstVisitedRow: any) {
	let extractedMessages = [];

	if (isUserMessagesDataAppended) {
		if (messages[messages.length - 1]?.event_id !== firstVisitedRow) {
			for (let index = messages.length - 1; index >= 0; index--) {
				if (messages[index].event_id == firstVisitedRow) {
					break;
				}
				extractedMessages.push(messages[index]);
			}

			extractedMessages.reverse();
		}
	} else {
		extractedMessages = messages;
	}

	return extractedMessages;
};

const updateMessagePaginationDetails = function (
	messages: any,
	lastVisitedRow: any,
	firstVisitedRow: any,
	pageNo: any,
	pageSize: number,
	dispatch: any
) {
	dispatch(updateLastVisitedRowAction(messages.length >= pageSize ? lastVisitedRow : null));
	dispatch(updateMessagesPageNoAction(lastVisitedRow === null ? 1 : pageNo + 1));
	dispatch(updateFirstVisitedRow(firstVisitedRow));
};

const setFirstTimeConversation = (selectedUser: any) => {
	const userResponseKey =
		selectedUser.platform == 'email'
			? `key_${selectedUser.conversation_id}_response`
			: `key_${selectedUser.user_id}_response`;

	if (!localStorage.getItem(userResponseKey)) {
		localStorage.setItem(userResponseKey, 'true');
	} else if (localStorage.getItem(userResponseKey) === 'true') {
		localStorage.setItem(userResponseKey, 'false');
	}
};

export const messageOverviewActions = {
	addQuickReplyAction: (replytext: string, title?: string) => (dispatch: any, getState: () => RootState) => {
		const { role } = getState().User.botAdmin.data.admin_mode_access;

		return messageOverviewAPI.addQuickReply(role, replytext, title).then((response: any) => {
			if (response.data && response.data.status && response.data.status.code === 1000) {
				toaster.positive(
					<ENGTToasterContainer
						title={i18nHelper('common:success')}
						description={i18nHelper('components:addQuickReplyModal.successToaster')}
					/>,
					{}
				);
			} else if (response?.data?.status?.code === 1050) {
				dispatch(
					openInfoModalAction(
						i18nHelper('common:error'),
						i18nHelper('pages:configure.liveChat.quickReplies.duplicateQuickReplyToaster')
					)
				);
			} else if (response?.data?.status?.code === 1023) {
				dispatch(
					openInfoModalAction(
						i18nHelper('common:error'),
						i18nHelper('pages:configure.liveChat.quickReplies.textTooBigError')
					)
				);
			} else if (response?.data?.status?.code === 1041) {
				dispatch(
					openInfoModalAction(
						i18nHelper('common:error'),
						i18nHelper('pages:configure.liveChat.quickReplies.titleTooBigError')
					)
				);
			} else {
				dispatch(openErrorSavingModalAction());
			}
		});
	},
	assignConversationAction:
		(
			history: NavigateFunction,
			selectedAgent: any,
			categoryId: any,
			onErrorCB: any,
			onClose?: any,
			userBotRef?: any,
			userCode?: any,
			onSuccesCB?: any,
			onSuccessRedirection?: any
		) =>
		(dispatch: any, getState: () => RootState) => {
			const adminName = getState().User.botAdmin.data.name;
			const { uid } = getState().User.botAdmin.data;
			const user = getState().Message.selectedUser;
			const isNeutralScreenEnabled = getState().User.botAdmin.data.enable_neutral_screen;
			const isSimplifiedOneViewEnabled = getState().User.botAdmin.data.is_simplified_one_view_enabled;
			const { conversation_id, cs_request_id, platform } = user;

			let state = 'ASSIGNED';
			if (selectedAgent[0].id === uid) {
				state = 'ENGAGING';
			}

			const data = {
				cs_request_id,
				agent_id: uid,
				new_agent_id: selectedAgent[0].id,
				target_state: state,
				initiator_name: adminName,
				platform,
				category_id: categoryId,
				user_code: userCode,
				user_bot_ref: userBotRef,
				bot_id: getState().Bot.data.bot_ref,
			};
			messageOverviewAPI.assignConversation(data).then((response: any) => {
				const jsonResult = response.data;
				if (jsonResult && jsonResult.status && jsonResult.status.code === 1000) {
					if (selectedAgent[0].id === uid) {
						!getState().User.botAdmin.data.is_simplified_one_view_enabled &&
							dispatch(removeSelectedUserFromListAction(user));
						onClose && onClose();
						onSuccessRedirection && onSuccessRedirection();
						onSuccesCB && onSuccesCB();
					} else {
						onClose && onClose();
						onSuccesCB && onSuccesCB();
					}
				} else if (jsonResult && jsonResult.status && jsonResult.status.code === 1003) {
					// TODO: show toaster/modal displaying ('The current conversation has already been assigned to an agent.')
					onClose && onClose();
					dispatch(removeSelectedUserFromListAction(user));
				} else {
					onErrorCB && onErrorCB();
					onClose && onClose();
				}
			});
		},
	getQuickRepliesAction: (pageNo: number) => (dispatch: any, getState: () => RootState) => {
		const { role } = getState().User.botAdmin.data.admin_mode_access;

		messageOverviewAPI.getQuickReplies(role, pageNo).then((response: any) => {
			if (response.data && response.data.status && response.data.status.code === 1000) {
				dispatch(
					getQuickReplies({
						page: pageNo,
						...response.data.responseObject,
					})
				);
			}
		});
	},

	getUserMessagesAction:
		(apiParams: IGetUserMessagesAPIProps, callback?: Function) => (dispatch: any, getState: () => RootState) => {
			const {
				usersData,
				isUserMessagesDataAppended = false,
				isUserMessagesDataPrepended = false,
				fetchOnlyLastMessage = false,
				isSolrQuery = false,
				isActiveTab = false,
				cancelToken,
				scrollType,
			} = apiParams;
			if (!fetchOnlyLastMessage) {
				dispatch(
					setStateMessageOverview({
						key: 'userMessages',
						loading: !(isUserMessagesDataAppended || isUserMessagesDataPrepended),
						error: false,
					})
				);
				dispatch(setMessagePositionAppend(isUserMessagesDataAppended));
				dispatch(setMessagePositionPrepend(isUserMessagesDataPrepended));
			}
			const isMessageSearchEnabled = getState().User.botAdmin.data.is_message_search_enabled;
			const { selectedUser } = getState().Message;

			const addNewMessagesIndicator = (
				messages: any,
				isUserMessagesDataAppended: boolean,
				messageSearch?: boolean
			) => {
				const unreadMessagesCount = getState().Message.messageCountMap?.[usersData.conversation_id];
				const conversationPlatform = getState().Message.selectedUser.platform;
				const isWhatsAppUser = WHATSAPP_PLATFORMS.includes(conversationPlatform);

				if (unreadMessagesCount > 0 && unreadMessagesCount <= messages.length) {
					messages.splice(messages.length - unreadMessagesCount, 0, {
						isNewMessagesIndicator: true,
						totalMessagesWhenLoaded: messages.length,
						timestamp: new Date().toISOString(),
					});
				}

				messages.forEach((message: any, index: number) => (message.index = index));

				let newMessagesList: any[] = [];
				let searchedMessageId = '';
				if (
					scrollType === SCROLL_TYPE.BOTTOM ||
					scrollType === SCROLL_TYPE.CUSTOM_BOTTOM ||
					(scrollType === undefined && isUserMessagesDataAppended)
				) {
					messages = messageSearch ? messages.reverse() : messages;
					newMessagesList = [...getState().Message.userMessages?.data, ...messages];
				} else {
					newMessagesList = [...messages, ...getState().Message.userMessages?.data];
					if (scrollType === SCROLL_TYPE.CUSTOM_TOP) {
						searchedMessageId = messages[messages.length - 1]?.messageId;
					}
				}
				const uniqueMessages = newMessagesList.filter(
					(obj, index) => index === newMessagesList.findIndex((o) => obj.event_id === o.event_id)
				);
				let hasNoUITemplateMessage = uniqueMessages;
				if (isWhatsAppUser) {
					hasNoUITemplateMessage = uniqueMessages.filter((word) => word?.event_id !== undefined);
				}
				conversationHistoryLogsAndWhatsappCartIfApplicable(
					messageSearch ? hasNoUITemplateMessage : newMessagesList,
					getState,
					usersData
				);
				dispatch(getUserMessages(hasNoUITemplateMessage));
				searchedMessageId && searchedMessageId !== '' && dispatch(setBringToView(searchedMessageId));
			};

			if (fetchOnlyLastMessage) {
				dispatch(fetchLastMessageAction(usersData, isSolrQuery, cancelToken));

				return;
			}

			let isTranslationSet = false;
			if (
				isActiveTab &&
				getState().Message.autoTranslationConfigs.isTranslateIntegrationEnabled &&
				getState().Message.autoTranslationConfigs.isAutoTranslateEnabledInLiveChat &&
				getState().Bot.configData.enable_auto_translation &&
				getState().Message.autoTranslationConfigs.translationStatusOfAgent &&
				getState().Message.selectedUser.platform !== 'email'
			) {
				isTranslationSet = true;
			}

			const params = {
				targetApi: 'fetchUserMessages',
			};

			const user_conversation_id: any = {};
			user_conversation_id[usersData?.user_id] = usersData.conversation_id;
			let newDataForMessageSearch = {};
			if (!usersData.message_digest) {
				newDataForMessageSearch = {
					user_conversation_id: { [usersData.user_id]: usersData.conversation_id },
					time_stamp: null,
					page: null,
					page_no: isUserMessagesDataAppended ? 1 : getState().Message.messagesPagination.pageNo,
					last_visited_row: isUserMessagesDataAppended
						? null
						: getState().Message.messagesPagination.lastVisitedRow,
					page_size: isTranslationSet ? 50 : 250,
				};
			} else if (
				scrollType &&
				scrollType !== '' &&
				scrollType !== SCROLL_TYPE.CUSTOM_BOTTOM &&
				scrollType !== SCROLL_TYPE.CUSTOM_TOP
			) {
				newDataForMessageSearch = {
					last_visited_row:
						(scrollType === SCROLL_TYPE.BOTTOM || scrollType === SCROLL_TYPE.CUSTOM_BOTTOM
							? localStorage.getItem('message_search_last_row')
							: localStorage.getItem('message_search_first_row')) === 'null'
							? null
							: scrollType === SCROLL_TYPE.BOTTOM || scrollType === SCROLL_TYPE.CUSTOM_BOTTOM
								? localStorage.getItem('message_search_last_row')
								: localStorage.getItem('message_search_first_row'),
					page_size: 50,
					scroll_direction: scrollType,
					user_conversation_id,
				};
			} else {
				newDataForMessageSearch = {
					message_detail: {
						time_stamp: usersData.message_timestamp,
						user_code: usersData.user_code,
						message_digest: usersData.message_digest,
					},
					scroll_direction: scrollType?.substring(scrollType.indexOf('_') + 1) || SCROLL_TYPE.TOP,
					page_size: 50,
					user_conversation_id,
				};
			}

			messageOverviewAPI
				.getUserMessages(params, newDataForMessageSearch, { cancelToken })
				.then((response: any) => {
					if (
						isValidResponseObject(response) &&
						Object.keys(response.data.responseObject.all_msg_data).length > 0
					) {
						const newSelectedUser = getState().Message.selectedUser;
						const { all_msg_data, page_no, last_visited_row } = response.data.responseObject;
						const isMessageSearchEnabled = getState().User.botAdmin.data.is_message_search_enabled;
						const userId = Object.keys(all_msg_data)[0];
						const { conversationReadStatusMap } = getState().Message;
						let messages = Array.isArray(all_msg_data[userId]) ? all_msg_data[userId] : [];
						const firstVisitedRow = messages[messages.length - 1]?.event_id;

						messages =
							extractMessages(messages, isUserMessagesDataAppended, firstVisitedRow).length > 0
								? extractMessages(messages, isUserMessagesDataAppended, firstVisitedRow)
								: messages;

						updateMessagePaginationDetails(
							messages,
							last_visited_row,
							firstVisitedRow,
							page_no,
							isTranslationSet ? 50 : 250,
							dispatch
						);

						// If user is active and has translate integration account linked with bot then auto-translate messages
						if (isTranslationSet) {
							messages.forEach((message: any, index: number) => {
								message.index = index;
								message.isTranslated = false;
								message.translatedMessage = message.message;
							});

							dispatch(updateLastTranslatedMessageStateAction(TRANSLATED_LAST_MESSAGE_STATE.TO_PROCESS));
							const allMessagesList = [...messages, ...getState().Message.userMessages.data];
							dispatch(getTranslatedMessagesAction(allMessagesList, 0, messages.length - 1));
						} else if (scrollType) {
							if (scrollType === SCROLL_TYPE.BOTTOM || scrollType === SCROLL_TYPE.CUSTOM_BOTTOM) {
								localStorage.setItem(
									'message_search_last_row',
									response.data.responseObject.last_visited_row
								);
							} else {
								localStorage.setItem(
									'message_search_first_row',
									response.data.responseObject.last_visited_row
								);
								if (localStorage.getItem('message_search_last_row') === null) {
									localStorage.setItem(
										'message_search_last_row',
										response.data.responseObject.last_visited_row
									);
								}
							}
							addNewMessagesIndicator(messages, isUserMessagesDataAppended, true);
						} else {
							addNewMessagesIndicator(messages, isUserMessagesDataAppended);
						}

						// Update lastActiveAt and lastMessages for selected user
						if (
							newSelectedUser.conversation_id === usersData.conversation_id &&
							messages.length > 0 &&
							scrollType !== SCROLL_TYPE.CUSTOM_BOTTOM &&
							scrollType !== SCROLL_TYPE.BOTTOM
						) {
							const updatedSelectedUser = { ...newSelectedUser };
							const lastMessageFromMessagesList = messages[messages.length - 1];
							if (newSelectedUser.user_online && !newSelectedUser.is_agent_reply) {
								updatedSelectedUser.last_active_at = lastMessageFromMessagesList.timestamp;
							}
							if (newSelectedUser.platform !== 'email') {
								dispatch(
									updateLastUserMessage({
										id: newSelectedUser.conversation_id,
										message: processLastSentMessage(lastMessageFromMessagesList),
										lastActiveAt:
											getState().Message?.lastMessage[usersData?.conversation_id]?.lastActiveAt,
									})
								);
							}
							dispatch(updateSelectedUserInUserListAction(updatedSelectedUser));
							updateUserWithChatWindowLimit(
								newSelectedUser,
								messages,
								dispatch,
								!getState().User.botAdmin.data.is_engati_brand
							);
						}
						if (
							conversationReadStatusMap.hasOwnProperty(newSelectedUser.conversation_id) &&
							!conversationReadStatusMap?.[newSelectedUser.conversation_id] &&
							!isMasterAdmin
						) {
							dispatch(conversationReadEmitter(newSelectedUser.conversation_id));
						}
						dispatch(removeMessageCountForIdAction(newSelectedUser.conversation_id));
						callback?.();
					} else {
						dispatch(
							setStateMessageOverview({
								key: 'userMessages',
								data: [],
								loading: false,
								error: true,
							})
						);
					}

					if (isMessageSearchEnabled && scrollType === SCROLL_TYPE.CUSTOM_TOP) {
						dispatch(
							getUserMessagesAction({
								usersData,
								isUserMessagesDataAppended: true,
								isUserMessagesDataPrepended: false,
								scrollType: SCROLL_TYPE.CUSTOM_BOTTOM,
							})
						);
					}
				});
		},

	fetchLastMessageAction:
		(usersData: IObjProps, isSolrQuery?: boolean, cancelToken?: any) =>
		(dispatch: any, getState: () => RootState) => {
			const oldLastMessageState = getState().Message.lastMessage || {};
			const conversationIdUserIdMap: any = {};
			const enableRecentMessageFirstSort = getState().User.botAdmin.data.enable_recent_message_first_sort;
			const isSimplifiedOneViewEnabled = getState().User.botAdmin.data.is_simplified_one_view_enabled;
			usersData.content.forEach((user: IObjProps) => {
				conversationIdUserIdMap[user.conversation_id] = user.user_id;
			});
			const tab = currentTab();

			let filters: any;
			if (isSimplifiedOneViewEnabled) {
				const quickFilters: any = dispatch(getLiteQuickFiltersFromLS());
				if (quickFilters?.myConversations) {
					filters = dispatch(getLiteFacetsSortFromLsMyConversation());
				} else {
					filters = dispatch(getLiteFacetsSortFromLs());
				}
			} else {
				filters = dispatch(getFacetsSortFromLs(tab || ''));
			}

			const data = {
				conversation_id_user_id: conversationIdUserIdMap,
				time_stamp: null,
				page: 'allConversationsConnect',
			};

			const params = {
				targetApi: 'fetchUserLastMessages',
			};

			messageOverviewAPI.getUserMessages(params, data, { cancelToken }).then((response: any) => {
				if (
					response.data?.status?.code === 1000 &&
					response.data.responseObject &&
					Object.keys(response.data.responseObject).length > 0
				) {
					const userMsgdata = response.data.responseObject;
					const lastMessageMap: any = {};

					userMsgdata.forEach((user: IObjProps) => {
						const currentTime: any = new Date();
						const lastAgentMessageTime: any = new Date(user?.lastActiveAt);

						if (
							!isEmpty(user) &&
							Math.floor((currentTime - lastAgentMessageTime) / (1000 * 60 * 60 * 24)) >= 90
						) {
							lastMessageMap[user.conversationId] = {
								content: '-',
								showBanner: true,
								showInUI: true,
								...user,
							};
						} else if (!isEmpty(user)) {
							lastMessageMap[user.conversationId] = {
								content: processLastSentMessage(user),
								showBanner: false,
								showInUI: true,
								...user,
							};
						} else {
							lastMessageMap[user.conversationId] = {
								content: '-',
								showBanner: false,
								showInUI: false,
								...user,
							};
						}
					});

					if (enableRecentMessageFirstSort && filters?.sort === SORTING.recentFirst) {
						const newUsersData = JSON.parse(JSON.stringify(usersData));
						newUsersData.content.forEach((user: IObjProps) => {
							user.last_message_time = lastMessageMap[user?.conversation_id]?.lastActiveAt;
						});
						newUsersData.content.sort(
							(x: IObjProps, y: IObjProps) =>
								new Date(y.last_message_time).valueOf() - new Date(x.last_message_time).valueOf()
						);
						usersData = JSON.parse(JSON.stringify(newUsersData));
					}
					dispatch(setLastUserMessage({ ...oldLastMessageState, ...lastMessageMap }));
				} else {
					dispatch(
						setStateMessageOverview({
							key: 'userMessages',
							data: [],
							loading: false,
							error: true,
						})
					);
				}

				if (!isSolrQuery) {
					dispatch(getLiveChatUsers(usersData));
				} else {
					dispatch(getUsersSolarQuery(usersData));
				}
			});
		},

	markToTrainAction:
		(message: IObjProps, index: number, errorCb: any) => (dispatch: any, getState: () => RootState) => {
			let answer = '';
			let isAnswerAvailable = false;
			let resolveQuery = false;
			const messages: Array<IObjProps> = getState().Message.userMessages.data.map((el: IObjProps) => ({
				...el,
			}));

			for (let i = index + 1; i < messages.length && !isAnswerAvailable; i++) {
				if (messages[i].sender !== MESSAGE_SENDER_MAP.user) {
					isAnswerAvailable = true;
					if (messages[i].messagetype === 'TEXT') {
						answer = messages[i].message;
					} else if (messages[i].messagetype === 'IMAGE') {
						answer = 'Image Upload';
					}
					if (messages[i].is_agent_reply) {
						resolveQuery = true;
					}
				}
			}

			const params = {
				targetApi: 'markForTrain',
			};
			const body = {
				bot_id: getState().Bot.data.bot_ref,
				user_id: getState().Message.selectedUser.user_id,
				query: message.message,
				answer,
				agent_id: getState().User.botAdmin.data.uid,
				timestamp: message.timestamp,
				resolve_query: resolveQuery,
			};
			messageOverviewAPI.markToTrain(params, body).then((response: any) => {
				if (response.data && response.data.status && response.data.status.code === 1000) {
					messages[index].marked_by = getState().User.botAdmin.data.uid;
					dispatch(setUserMessages(messages));
					// TODO:Theja - success
				} else {
					errorCb && errorCb();
				}
			});
		},
	resolveConversationAction:
		(botAdminData: IObjProps, selectedUser: IObjProps, successCb: any, errorCb: any) =>
		(dispatch: any, getState: () => RootState) => {
			const { selectedVideoUser } = getState().Jitsi;
			const feedbackOnResolutionFromBotConfig = getState().Bot?.generalBotConfigData?.feedbackOnResolution;
			const {
				email: botAdminEmail,
				uid: botAdminUid,
				cid: botAdminCid,
				name: botAdminName,
				feedback_on_resolution: feedbackOnResolutionFallback,
			} = botAdminData;

			const userDataList: {
				user_id: any;
				conversation_id: any;
				user_bot_ref: any;
				platform: any;
				user_code: any;
			}[] = [];

			selectedUser.forEach((data: any) => {
				const userData = {
					user_id: data.user_id,
					conversation_id: data.conversation_id,
					user_bot_ref: data.bot_ref,
					platform: data.platform,
					user_code: data.id,
				};
				userDataList.push(userData);
				if (
					selectedVideoUser &&
					!selectedVideoUser.is_group_conversation &&
					data.conversation_id === selectedVideoUser.conversation_id
				) {
					dispatch(jitsiActions.closeVideoCallAction(botAdminData));
				}
			});

			const params = {
				targetApi: 'markChatAsResolved',
			};
			const data = {
				botId: '{{botRef}}',
				agentEmail: botAdminEmail,
				agentId: botAdminUid,
				agentName: botAdminName,
				customerId: botAdminCid,
				feedbackOnResolution: feedbackOnResolutionFromBotConfig ?? feedbackOnResolutionFallback,
				liveChatProvider: 'DEFAULT',
				statusChangeRequests: userDataList,
			};

			messageOverviewAPI.resolveConversation(params, data).then((response: any) => {
				if (response.data && response.data.status && response.data.status.code === 1000) {
					localStorage.removeItem(`key_${selectedUser.user_id}_response`);
					localStorage.removeItem(`key_${selectedUser.conversation_id}_response`);

					if (
						selectedVideoUser &&
						selectedVideoUser.is_group_conversation &&
						selectedUser.conversation_id === selectedVideoUser.conversation_id
					) {
						dispatch(jitsiActions.clearAllJitsiData());
					}
					dispatch(removeNotificationAction('USER_ID', selectedUser.user_id));
					successCb();
				} else {
					errorCb();
				}
				dispatch(liveChatStatusChange());
			});
		},
	setStateMessageOverviewAction:
		(data: { data?: Array<IObjProps>; error: boolean; loading: boolean; key: 'userMessages' }) =>
		(dispatch: any) => {
			dispatch(setStateMessageOverview(data));
		},
	pendingResolutionAction:
		(botAdminData: IObjProps, selectedUser: IObjProps, tab: string, successCb: any, errorCb: any) =>
		(dispatch: any, getState: () => RootState) => {
			const { cid: botAdminCid, uid: botAdminUid, email: botAdminEmail } = botAdminData;
			const { user_id: userId, conversation_id: conversationId, bot_ref: botRef } = selectedUser;
			const isSimplifiedOneViewEnabled = getState().User.botAdmin.data.is_simplified_one_view_enabled;

			const params = {
				platform: selectedUser.platform,
				live_chat_provider: 'DEFAULT',
				targetApi: 'markAsPendingResolution',
			};
			const data = {
				customer_id: botAdminCid,
				agent_id: botAdminUid,
				agent_email: botAdminEmail,
				user_id: userId,
				conversation_id: conversationId,
				bot_id: '{{botRef}}',
				user_bot_ref: botRef,
			};

			messageOverviewAPI.pendingResolution(params, data).then((response: any) => {
				if (response.data && response.data.status && response.data.status.code === 1000) {
					dispatch(removeNotificationAction('USER_ID', selectedUser.user_id));
					if (
						!isSimplifiedOneViewEnabled
							? tab === MESSAGE_TAB_HEADERS_MAP.active
							: selectedUser.current_state === MESSAGE_STATE_MAP.ENGAGING
					) {
						const updatedUser = { ...selectedUser };
						updatedUser.current_state = 'PENDING_RESOLUTION';

						dispatch(updateSelectedUserInUserListAction(updatedUser));
					}
					// TODO: Remove notifications based on uid
					successCb(
						!isSimplifiedOneViewEnabled
							? tab === MESSAGE_TAB_HEADERS_MAP.new
							: selectedUser.current_state === MESSAGE_STATE_MAP.ASSIGNED
					);
				} else {
					errorCb();
				}
			});
		},
	sendEmailMessageAction:
		(
			emailConfig: IObjProps,
			emailText: string,
			attachments: Array<IEmailAttachmentsData>,
			token: string,
			ccList: Array<any>,
			successCB: any,
			errorCB: any,
			selectedUser: any,
			messages: any
		) =>
		(dispatch: any, getState: () => RootState) => {
			const botAdminData: any = getState().User.botAdmin.data;
			const botName: any = getState().Bot.data.bot_name;
			const { bot_ref, conversation_id, platform, user_id } = selectedUser;

			setFirstTimeConversation(selectedUser);

			let userLastConversation: any = null;
			let emailData: any = null;
			let emailNotificationData: any = null;
			let userEmail: any = null;
			let supportEmail: any = null;
			let bcc: any = null;
			let conversationId: any = null;
			let to: any = null;
			let messageId: any = null;
			let msgIdReferences: any = null;
			let inReplyTo: any = null;
			let subject: any = null;

			for (let i = messages.length - 1; i >= 0; i--) {
				if (messages[i].sender === MESSAGE_SENDER_MAP.user) {
					userLastConversation = messages[i];
					emailData = userLastConversation?.emailData;
					userEmail = selectedUser.email;
					supportEmail = emailConfig?.sender_address;
					bcc = emailData?.bcc;
					conversationId = emailData?.conversationId;
					to = emailData?.to;
					messageId = emailData?.messageId;
					msgIdReferences = emailData?.msgIdReferences;
					inReplyTo = emailData?.inReplyTo;
					subject = emailData?.subject;
					break;
				} else if (messages[i].sender === MESSAGE_SENDER_MAP.bot && messages[i].messagetype === 'EMAIL') {
					userLastConversation = messages[i];
					emailNotificationData = userLastConversation?.emailNotificationData;
					userEmail = selectedUser.email;
					supportEmail = emailConfig?.sender_address;
					bcc = emailNotificationData?.bcc || [];
					conversationId = emailNotificationData?.conversationId;
					to = emailNotificationData?.toEmails;
					messageId = emailNotificationData?.conversationId;
					subject = emailNotificationData?.subject;
					msgIdReferences = [];
					break;
				}
			}
			const message = {
				senderEmail: userEmail,
				supportEmail,
				bcc,
				contentToken: token,
				cc: ccList,
				to,
				conversationId,
				msgId: messageId,
				msgReferences: msgIdReferences,
				inReplyTo,
				senderName: emailConfig?.sender_name,
				subject,
				emailAttachments: attachments,
				emailSnippet: emailText.substr(0, 100),
				brandingKey: botAdminData.branding_key,
			};
			const responseType = MESSAGE_TYPE_MAP.email;
			const userIdParams = {
				userId: botAdminData.uid,
			};

			const sendEmailMessageActionSuccessCB = (agentId: any) => {
				const body: any = {
					user_id,
					users_bot_ref: bot_ref,
					message,
					type: responseType,
					is_new: false,
					conversation_id,
					cs_request_id: selectedUser.cs_request_id,
					category_id: selectedUser.category_id,
					message_id: `${bot_ref}_${uuidGenerator()}`,
					agent_msg_details: {
						agent_id: agentId,
						agent_email: botAdminData.email,
						event_timestamp: new Date().toISOString(),
						agent_avatar:
							botAdminData.profileImg ||
							initialsCanvasData({
								username: botAdminData.name,
								id: botAdminData.uid,
							}),
					},
					is_human_agent_tag_required: false,
				};

				if (localStorage.getItem(`key_${selectedUser.conversation_id}_response`) === 'true') {
					body.is_new = true;
				}

				const params = {
					agent_email: '{{email}}',
					platform,
					targetApi: 'liveChatRespondV2',
					live_chat_provider: 'DEFAULT',
					customerId: '{{customerId}}',
					userId: '{{userId}}',
					botRef: '{{botRef}}',
				};

				messageOverviewAPI.sendMessage(params, body).then((response: any) => {
					if (response.status !== 200) {
						dispatch(openErrorSavingModalAction(response.status));
						errorCB();

						return;
					}
					const messageData = appendEmailMessage(attachments, body);
					if (conversation_id === getState().Message.selectedUser.conversation_id) {
						dispatch(addUserMessage(messageData));
					}
					successCB();
				});
			};

			sendEmailMessageActionSuccessCB(getState().Message.agentId);
		},

	checkForTranslationAndSendMessageAction:
		(message: any, quotedMessageDetails: IObjProps, isVideoUserMessage?: boolean, successCB?: any) =>
		(dispatch: any, getState: () => RootState) => {
			const { isTranslateIntegrationEnabled } = getState().Message.autoTranslationConfigs;
			const isAutoTranslateEntitlementEnabled = getState().Bot.configData.enable_auto_translation;
			const { translationStatusOfAgent } = getState().Message.autoTranslationConfigs;
			const { isAutoTranslateEnabledInLiveChat } = getState().Message.autoTranslationConfigs;
			const selectedUserLanguage = getState().Message.autoTranslationConfigs.endUserLanguage;
			const { selectedUser } = getState().Message;
			const messagesToTranslateList: any = [];

			if (typeof message === 'object' && message.optionDTOS) {
				const { title } = message;
				messagesToTranslateList[0] = title;
				const options = message.optionDTOS;
				options.map((option: any, index: number) => {
					messagesToTranslateList[index + 1] = option.text;
				});
			} else {
				messagesToTranslateList[0] = message;
			}

			setFirstTimeConversation(selectedUser);

			if (
				isTranslateIntegrationEnabled &&
				isAutoTranslateEntitlementEnabled &&
				translationStatusOfAgent &&
				isAutoTranslateEnabledInLiveChat &&
				selectedUser.platform !== 'email'
			) {
				dispatch(
					updateMessagesWithTransaltedTextAction(
						messagesToTranslateList,
						selectedUserLanguage || DEFAULT_LANGUAGE_CODE,
						(translatedMessagesList: any) => {
							let translatedMessage = JSON.parse(JSON.stringify(message));

							if (typeof message === 'object' && message.optionDTOS) {
								translatedMessage.title = translatedMessagesList[0]['translatedText'];
								for (let index = 1; index < translatedMessagesList.length; index++) {
									translatedMessage.optionDTOS[index - 1]['text'] =
										translatedMessagesList[index]['translatedText'];
								}
							} else {
								translatedMessage = translatedMessagesList[0]['translatedText'];
							}

							dispatch(
								messageOverviewActions.sendMessageAction(
									message,
									quotedMessageDetails,
									selectedUser,
									isVideoUserMessage,
									successCB,
									translatedMessage
								)
							);
						}
					)
				);
			} else {
				dispatch(
					messageOverviewActions.sendMessageAction(
						message,
						quotedMessageDetails,
						selectedUser,
						isVideoUserMessage,
						successCB
					)
				);
			}
		},

	setAgentIdAction: (agentId: any) => (dispatch: any) => {
		dispatch(setAgentId(agentId));
	},

	sendMessageAction:
		(
			message: any,
			quotedMessageDetails: IObjProps,
			selectedUser: any,
			isVideoUserMessage?: boolean,
			successCB?: any,
			translatedMessage?: any
		) =>
		(dispatch: any, getState: () => RootState) => {
			const curentSelectedUser = isVideoUserMessage ? getState().Jitsi.selectedVideoUser : selectedUser;
			const botAdminData: any = getState().User.botAdmin.data;
			const validFileType = [INPUT_MSG_TYPE.FILE, INPUT_MSG_TYPE.VIDEO, INPUT_MSG_TYPE.AUDIO];
			const {
				bot_ref,
				user_id: userId,
				conversation_id: conversationId,
				cs_request_id: csRequestId,
				category_id: categoryId,
				platform,
			} = curentSelectedUser;
			const isWhiteLabelPartner = !getState().User.botAdmin.data.is_engati_brand;

			if (typeof message === 'object' || (message.length && message.trim().length)) {
				let responseType = 'TEXT';
				let targetWaNumber: string;
				if (typeof message !== 'object') {
					responseType = 'TEXT';
				} else if (message.type === 'FLOW') {
					responseType = message.type;
				} else {
					if (message.url) {
						responseType = message.type;
					}
					if (message.optionDTOS) {
						responseType = 'OPTIONS';
					}
				}

				if (message.type && message.type === TEMPLATE_MESSAGE) {
					responseType = message.type;
					delete message.type;
					targetWaNumber = message.number != '' ? message.number : '';
					delete message.number;
					message = JSON.stringify(message);
				}
				const userIdParams = {
					userId: botAdminData.uid,
				};

				const sendMessageActionSuccessCB = (agentId: any) => {
					const indata: any = {
						user_id: userId,
						users_bot_ref: bot_ref,
						message: translatedMessage || message,
						type: responseType,
						is_new: false,
						conversation_id: conversationId,
						cs_request_id: csRequestId,
						category_id: categoryId,
						message_id: `${bot_ref}_${uuidGenerator()}`,
						target_wa_number: targetWaNumber,
						agent_msg_details: {
							agent_id: agentId,
							agent_name: botAdminData.name,
							agent_email: botAdminData.email,
							event_timestamp: new Date().toISOString(),
							agent_avatar:
								botAdminData.profileImg ||
								initialsCanvasData({
									username: botAdminData.name,
									id: botAdminData.uid,
								}),
						},
						is_human_agent_tag_required:
							CHANNELS_WITH_7_DAYS_CHAT_WINDOW_LIMIT.includes(platform) && !isWhiteLabelPartner,
					};
					if (quotedMessageDetails?.messageId) {
						const linked_response = {
							linked_event_id: quotedMessageDetails.messageId,
							linked_response_data: quotedMessageDetails.message,
						};
						indata['linked_response'] = linked_response;
					}
					if (responseType === 'IMAGE') {
						if (selectedUser.platform !== CHANNELS.WEB && selectedUser.platform !== CHANNELS.DIALOG360) {
							const liveChatRespondList: any = [];
							message.data.map((data: any, index: number) => {
								const msg: any = {};
								msg['type'] = message.type;
								msg['message'] = data.url;
								msg['fileName'] = data.name;
								msg['fileSize'] = data.size;
								msg['message_id'] = `${bot_ref}_${uuidGenerator()}`;
								liveChatRespondList.push(msg);
							});
							if (!isEmpty(message.messageText)) {
								const caption: IObjProps = {};
								caption['type'] = 'TEXT';
								caption['message'] = message.messageText;
								caption['message_id'] = `${bot_ref}_${uuidGenerator()}`;
								liveChatRespondList.push(caption);
							}
							if (message.data.length > 1) {
								indata['group_id'] = uuidGenerator();
							}
							indata['live_chat_respond'] = liveChatRespondList;
							delete indata.message_id;
							delete indata.message;
						} else {
							const liveChatRespondList: any = [];
							message.data.map((data: any, index: number) => {
								const msg: any = {};
								msg['type'] = message.type;
								msg['message_text'] =
									index === 0 && message.messageText != undefined ? message.messageText : '';
								msg['message'] = data.url;
								msg['fileName'] = data.name;
								msg['fileSize'] = data.size;
								if (message.data.length > 1) {
									indata['group_id'] = uuidGenerator();
								}
								liveChatRespondList.push(msg);
							});
							indata['live_chat_respond'] = liveChatRespondList;
							delete indata.message_id;
							delete indata.message;
						}
					}

					if (validFileType.includes(responseType)) {
						indata['message'] = message.url;
						indata['message_text'] = message.messageText || '';
						indata['fileName'] = message.filename;
						indata['fileSize'] = message.size;
					}

					const params = {
						agent_email: '{{email}}',
						platform,
						targetApi: 'liveChatRespondV2',
						live_chat_provider: 'DEFAULT',
						customerId: '{{customerId}}',
						userId: '{{userId}}',
						botRef: '{{botRef}}',
					};
					if (platform !== 'email' && localStorage.getItem(`key_${userId}_response`) === 'true') {
						indata['is_new'] = true;
					}

					if (message.type === 'FLOW') {
						indata['is_resolution_flow'] = message.is_resolution_flow;
						indata['message'] = message.flow_key;
						delete indata['is_new'];
						delete indata['message_id'];
					}

					const sendMessageApi = () => {
						messageOverviewAPI.sendMessage(params, indata).then((response: any) => {
							if (response.status !== 200) {
								dispatch(openErrorSavingModalAction(response.status));
							}
							if (
								responseType === INPUT_MSG_TYPE.IMAGE ||
								responseType === INPUT_MSG_TYPE.FILE ||
								responseType === INPUT_MSG_TYPE.VIDEO ||
								responseType === INPUT_MSG_TYPE.AUDIO
							) {
								successCB();
							}

							const currentTime = new Date().toISOString();
							if (isEmptyString(message.flow_key)) {
								const { prepend, append } = getState().Message.messagePosition;
								// for non swtich to bot scenario only
								if (translatedMessage) {
									indata['message'] = message;
									indata['isTranslated'] = true;
									indata['translatedMessage'] = translatedMessage;
									dispatch(
										updateLastTranslatedMessageStateAction(TRANSLATED_LAST_MESSAGE_STATE.TO_PROCESS)
									);
								}
								prepend && dispatch(setMessagePositionPrepend(false));
								append && dispatch(setMessagePositionAppend(false));
								const processedMessages = processMessageByType(indata, platform);
								processedMessages.forEach((processedMessage: any) => {
									const sentMessageDetails: IObjProps = appendMessageByType(
										processedMessage.messageType,
										currentTime,
										processedMessage.messageData
									);
									if (
										(isVideoUserMessage
											? getState().Jitsi.selectedVideoUser
											: getState().Message.selectedUser.conversation_id) === conversationId
									) {
										dispatch(addUserMessage(sentMessageDetails));
									}

									dispatch(
										updateLastUserMessage({
											id: conversationId,
											message: processLastSentMessage(sentMessageDetails),
											lastActiveAt: getState().Message?.lastMessage[conversationId]?.lastActiveAt,
										})
									);
								});
							}
						});
					};

					if (message.type === 'FLOW' && message.flow_key) {
						dispatch(
							messageOverviewActions.switchToBotOrAgent(
								() => {},
								function () {
									setTimeout(() => {
										sendMessageApi();
									}, 3000); // delay to wait for backend to update
								}
							)
						);
					} else {
						sendMessageApi();
					}
				};
				sendMessageActionSuccessCB(getState().Message.agentId);
			}
		},
	startConversationAction:
		(tab: string, history: NavigateFunction, onErrorCB: any, onClose?: any, onSuccesCB?: any, categoryId?: any) =>
		(dispatch: any, getState: () => RootState) => {
			const adminName = getState().User.botAdmin.data.name;
			let botId = getState().Bot.data.bot_ref;
			const masterBotRef = getState().Bot.data.master_bot_ref;
			const { mode } = getState().Bot.data;
			const { uid } = getState().User.botAdmin.data;
			const user = getState().Message.selectedUser;
			const isNeutralScreenEnabled = getState().User.botAdmin.data.enable_neutral_screen;
			const isMaskingEnabled =
				getState().User.botAdmin.data.enable_masking &&
				getState().User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT;

			const { bot_ref, category_id, conversation_id, user_id, platform, current_state, global_user_id } = user;
			const { all: ALL_TAB, new: NEW_TAB, unassigned: UNASSIGNED_TAB } = MESSAGE_TAB_HEADERS_MAP;

			const { userList } = getState().Message;
			const setUserName = () => {
				const filters = {
					facets: {
						username: user.username,
						email: null,
						managedByFilter: null,
					},
					sort: 'lastActiveAt,desc',
				};
				userList.pagination.page > 1 && dispatch(setFacetsSortInLs('active', filters));
			};

			if (tab === NEW_TAB) {
				const conversationId = conversation_id || user_id;

				const isNumeric = (value: string) => /^\d+$/.test(value);

				const idToUse = isMaskingEnabled && isNumeric(conversationId) ? global_user_id : conversation_id;

				const data = {
					user_id,
					user_bot_ref: bot_ref,
					target_state: 'ENGAGING',
					conversation_id: conversationId,
					customer_id: '{{customerId}}',
					agent_id: '{{userId}}',
					agent_email: '{{email}}',
					bot_id: '{{botRef}}',
					platform,
					category_id: categoryId || category_id || null,
				};

				messageOverviewAPI.startConversation(tab, data).then((response: any) => {
					if (response.data && response.data.status && response.data.status.code === 1000) {
						dispatch(removeNotificationAction('USER_ID', user_id));
						dispatch(messageListActions.updateAllUsersCountAction('remove'));
						onClose && onClose();
						setUserName();
						localStorage.setItem('active_username', user.username);
						history(
							`${
								getState().User.botAdmin.data.is_simplified_one_view_enabled
									? '/allmessages/'
									: '/messages/active/'
							}${isNeutralScreenEnabled ? `${idToUse}/?neutralScreen=false` : idToUse}`,
							{ replace: true }
						);
						localStorage.setItem('conversationId', conversation_id);
						onSuccesCB && onSuccesCB();
					} else {
						onErrorCB && onErrorCB();
						onClose && onClose();
					}
				});
			} else if (tab === ALL_TAB) {
				botId = mode === BOT_MODE.LIVE && masterBotRef ? masterBotRef : botId;
				const instanceBotRef = Number(getState().Message.userAttributes.data.bot_ref);
				const isNumeric = (value: string) => /^\d+$/.test(value);

				const idToUse = isMaskingEnabled && isNumeric(conversation_id) ? global_user_id : conversation_id;

				const data = {
					agent_id: uid,
					user_id,
					bot_id: botId,
					state: 'ENGAGING',
					initiator: 'AGENT',
					priority: 'LOW',
					initiator_name: adminName,
					user_bot_ref:
						user?.platform === CHANNELS.INSTAGRAM || user?.platform === CHANNELS.FACEBOOK
							? instanceBotRef
							: bot_ref,
					platform,
					conversation_id,
					category_id: categoryId || category_id || null,
					user_code: user?.id,
				};

				messageOverviewAPI.startConversation(tab, data).then((response: any) => {
					if (response.data && response.data.status && response.data.status.code === 1000) {
						dispatch(removeNotificationAction('USER_ID', user_id));
						if (current_state === MESSAGE_STATE_MAP.REQUESTED) {
							dispatch(messageListActions.updateAllUsersCountAction('remove', {}, UNASSIGNED_TAB));
						}
						onClose && onClose();
						history(
							`${
								getState().User.botAdmin.data.is_simplified_one_view_enabled
									? '/allmessages/'
									: '/messages/active/'
							}${isNeutralScreenEnabled ? `${idToUse}/?neutralScreen=false` : idToUse}`,
							{ replace: true }
						);
						onSuccesCB && onSuccesCB();
					} else {
						onErrorCB && onErrorCB();
						onClose && onClose();
					}
				});
			}
		},
	switchAction: (onSuccesCB: any, onErrorCB: any) => (dispatch: any, getState: () => RootState) => {
		const isMessagingEnabled = getState().Message.selectedUser.is_enable;
		const liveChatSwitchEntitlement = getState().Bot.configData.livechat_switch_actions;
		const { selectedUser } = getState().Message;
		const botRef = getState().Bot.data.bot_ref;

		const user = getState().Message.selectedUser;

		const { user_id } = user;

		if (liveChatSwitchEntitlement) {
			if (isMessagingEnabled) {
				const params = {
					target_url: 'getOngoingFlow',
					params: { botUserId: user_id },
				};

				messageOverviewAPI.switchToAgent(params).then((response: any) => {
					if (
						response.data &&
						response.data.response_obj.status &&
						response.data.response_obj.status.code === 1000
					) {
						if (response.data.response_obj.responseObject.flowOngoing) {
							onSuccesCB();
						} else {
							dispatch(messageOverviewActions.switchToBotOrAgent(onErrorCB));
						}
					} else {
						dispatch(messageOverviewActions.switchToBotOrAgent(onErrorCB));
					}
				});
			}
		} else {
			dispatch(messageOverviewActions.switchToBotOrAgent(onErrorCB));
		}
	},
	switchToBotOrAgent: (onErrorCB: any, successCB?: any) => (dispatch: any, getState: () => RootState) => {
		const botId = getState().Bot.data.bot_ref;
		const isMessagingEnabled = getState().Message.selectedUser.is_enable;
		const user = getState().Message.selectedUser;
		const { roleName } = getState().User.profile.data;
		const { uid, name, email } = getState().User.botAdmin.data;
		const agentCAUserId = getState().Message.agentId;
		const isNewBannerEnabled = getState().User.botAdmin.data.enable_start_conversation_from_backend;
		const botRef = getState().Bot.data.bot_ref;
		const { selectedUser } = getState().Message;

		const { conversation_id, isNew, user_id, id } = user;
		const data = {
			is_new: isNew || false,
			state: !isMessagingEnabled,
			bot_ref: botId,
			users_bot_ref: botId,
			user_id,
			conversation_id,
			user_code: id,
			agent_name: name,
			agent_email: email,
		};
		const params = {
			targetApi: 'switchBot',
			agent_id: '{{userId}}',
		};

		messageOverviewAPI.switchToBotOrAgent(params, data).then((response: any) => {
			if (response?.data?.status?.code === 1000) {
				dispatch(
					setSelectedUser({
						...JSON.parse(JSON.stringify(user)),
						is_enable: !isMessagingEnabled,
						isNew: false,
					})
				);
				successCB && successCB();
				if (isNewBannerEnabled) {
					const newBannerApiParams = {
						botRef: botId,
						userId: user.global_user_id,
						loggedInUserRole: roleName,
						conversationId: user.conversation_id,
						commonAdminUid: uid,
						actorId: agentCAUserId,
					};
					if (!isMessagingEnabled) {
						const params = {
							...newBannerApiParams,
							isConversationSwitchedToBot: true,
						};
						messageListAPI.getSelectedUserData(params).then((response: any) => {
							if (isValidResponseObject(response)) {
								dispatch(setSelectedUserData(response.data.responseObject));
							}
							const updatedSelectedUser = { ...selectedUser };
							updatedSelectedUser.is_enable = true;
							updatedSelectedUser.is_live_chat_enable = true;

							dispatch(updateSelectedUserInUserListAction(updatedSelectedUser));
						});
					} else {
						setTimeout(() => {
							messageListAPI.getSelectedUserData(newBannerApiParams).then((response: any) => {
								if (isValidResponseObject(response)) {
									dispatch(setSelectedUserData(response.data.responseObject));
								}
								const updatedSelectedUser = { ...selectedUser };
								updatedSelectedUser.is_enable = false;
								updatedSelectedUser.is_live_chat_enable = true;

								dispatch(updateSelectedUserInUserListAction(updatedSelectedUser));
							});
						}, 2000);
					}
				}
			} else {
				onErrorCB && onErrorCB();
				dispatch(openErrorSavingModalAction());
			}
		});
	},
	transferChatToAgentAction:
		(
			botAdminData: IObjProps,
			selectedUser: IObjProps,
			selectedAgent: IObjProps,
			masterBotRef: any,
			botMode: any,
			successCb: any,
			errorCb: any,
			categoryId?: number
		) =>
		(dispatch: any) => {
			const { cid: botAdminCid, email: botAdminEmail, name: botAdminName, uid: botAdminUid } = botAdminData;
			const {
				bot_ref: botRef,
				cs_request_id: csRequestId,
				user_id: userId,
				id: selectedUserId,
				conversation_id: conversationId,
				platform,
			} = selectedUser;
			const { email: selectedAgentEmail, uid: selectedAgentUid, is_online: selectedAgentOnline } = selectedAgent;

			const params = {
				targetApi: 'transferChatConnect',
			};
			const data: any = {
				customer_id: botAdminCid,
				agent_id: botAdminUid,
				agent_email: botAdminEmail,
				bot_id: '{{botRef}}',
				master_bot_ref: masterBotRef,
				bot_mode: botMode,
				cs_request_id: csRequestId,
				user_id: userId,
				user_code: selectedUserId,
				conversation_id: conversationId,
				user_bot_ref: botRef,
				new_agent_email: selectedAgentEmail,
				new_agent_id: selectedAgentUid,
				is_new_agent_online: selectedAgentOnline,
				initiator_name: botAdminName,
				platform,
			};
			if (categoryId) {
				data['category_id'] = categoryId;
			}

			messageOverviewAPI.transferChat(params, data).then((response: any) => {
				if (response.data && response.data.status && response.data.responseObject) {
					dispatch(removeNotificationAction('USER_ID', selectedUser.user_id));
					successCb();
					dispatch(liveChatStatusChange());
				} else {
					errorCb();
				}
			});
		},

	getAgentsByCategoryAction: (categoryId: number) => (dispatch: any) => {
		dispatch(getAgentsByCategoryLoading());

		const params = {
			targetApi: 'getAgentsByCategory',
			categoryId,
		};
		const data = {
			onlineStatuses: [false, true],
		};

		messageOverviewAPI.getAgentsByCategory(params, data).then((response: any) => {
			if (
				response.data &&
				response.data.status &&
				response.data.status.code === 1000 &&
				response.data.responseObject
			) {
				const agentsList = response.data.responseObject;
				const autoAssignAgentObject = {
					email: '',
					is_online: false,
					profile_image: null,
					uid: null,
					user_name: 'Auto Assign',
				};
				agentsList.unshift(autoAssignAgentObject);

				dispatch(getAgentsByCategory(agentsList));
			}
		});
	},

	blockUserAction:
		(botAdminData: IObjProps, selectedUser: IObjProps, blockingStatus: boolean, successCb: any, errorCb: any) =>
		(dispatch: any) => {
			const { global_user_id: globalUserId, platform } = selectedUser;
			const params = {
				userId: globalUserId,
				banStatus: blockingStatus,
				platform,
				targetApi: 'blockUser',
			};

			messageOverviewAPI.blockUser(params).then((response: any) => {
				if (response.data && response.data.responseObject) {
					if (blockingStatus) {
						dispatch(resolveConversationAction(botAdminData, [selectedUser], successCb, errorCb));
					} else {
						successCb();
					}
				} else {
					errorCb();
				}
			});
		},

	setEmailTranscriptAction: (isEmailTranscriptEnabled: boolean) => (dispatch: any) => {
		dispatch(setEmailTranscript(isEmailTranscriptEnabled));
	},

	setTranslateIntegrationAction: (isTranslateIntegrationEnabled: boolean) => (dispatch: any) => {
		dispatch(setTranslateIntegration(isTranslateIntegrationEnabled));
	},

	updateUserNameAction: (name: string) => (dispatch: any, getState: () => RootState) => {
		const selectedUser = getState().Message.selectedUser || {};
		const allUsers: any = getState().Message.userList.data || [];
		const modifiedUsers = allUsers.map((user: any) =>
			user.username === selectedUser.username ? { ...user, username: name } : user
		);

		dispatch(
			setSelectedUser({
				...JSON.parse(JSON.stringify(selectedUser)),
				username: name,
			})
		);
		dispatch(setUserListAction(modifiedUsers));
	},

	joinConversationAction:
		(successCB?: Function, errorCB?: Function) => (dispatch: any, getState: () => RootState) => {
			const { uid } = getState().User.botAdmin.data;
			const { selectedUser } = getState().Message;
			const body = {
				csRequestId: selectedUser.cs_request_id,
				agentId: uid,
				platform: selectedUser.platform,
			};

			messageOverviewAPI.joinConversation(body).then((response: any) => {
				const { data } = response;
				if (data.status && data.status.code === 1000 && data.responseObject && data.responseObject.joined) {
					dispatch(liveChatStatusChange());
					successCB && successCB();
					dispatch(
						jitsiActions.setVideoConversationIdForUserAction(
							selectedUser.conversation_id,
							data.responseObject.videoConversationId
						)
					);
					toaster.positive(
						<ENGTToasterContainer
							title={i18nHelper('common:groupConversation')}
							description={i18nHelper('components:joinConversationModal.successToaster')}
						/>,
						{}
					);
				} else if (data.status && data.status.code === 1032) {
					// TODO:Theja groupConversationLimitReached
				} else {
					errorCB && errorCB();
					dispatch(openErrorSavingModalAction());
				}
			});
		},
	getLiveChatConfigAction: () => (dispatch: any) => {
		messageOverviewAPI.getLiveChatConfig().then((response: any) => {
			if (isValidResponseObject(response)) {
				dispatch(
					getLiveChatConfig({
						data: response.data.responseObject,
						error: false,
						loading: false,
					})
				);
			} else {
				dispatch(
					getLiveChatConfig({
						data: {},
						error: true,
						loading: false,
					})
				);
				toaster.negative(
					<ENGTToasterContainer
						title={i18nHelper('common:error')}
						description={i18nHelper('common:somethingWentWrong')}
					/>,
					{}
				);
			}
		});
	},
	setPathVariablesAction: (pathVariables: IObjProps) => (dispatch: any) => {
		dispatch(setPathVariables(pathVariables));
	},
	inviteToGroupConversationAction:
		(selectedAgents: Array<number>, isVideoInvite: boolean, successCallback?: any, errorCallback?: any) =>
		(dispatch: any, getState: () => RootState) => {
			const { selectedUser } = getState().Message;
			const { videoConversationId } = getState().Jitsi.videoCallInitialisationDetails;
			const { selectedVideoUser } = getState().Jitsi;
			const user = isVideoInvite ? selectedVideoUser : selectedUser;
			const { uid } = getState().User.botAdmin.data;

			const body = {
				csRequestId: user.cs_request_id,
				agentIds: selectedAgents,
				invitorAgentId: uid,
				platform: user.platform,
				videoConversationId: isVideoInvite ? videoConversationId : null,
			};

			messageOverviewAPI.inviteToGroupConversation(body).then((response: any) => {
				const { data } = response;
				if (data?.status && data?.status?.code === ENGATI_RESPONSE_CODE.SUCCESS) {
					successCallback && successCallback(data.responseObject);
				} else {
					errorCallback && errorCallback();
				}
			});
		},

	updateLastTranslatedMessageStateAction: (stateToUpdate: any) => (dispatch: any) => {
		dispatch(updateLastTranslatedMessageState(stateToUpdate));
	},

	updateMessagesPageNoAction: (pageNo: any) => (dispatch: any) => {
		dispatch(updatePageNo(pageNo));
	},

	updateLastVisitedRowAction: (rowId: any) => (dispatch: any) => {
		dispatch(updateLastVisitedRow(rowId));
	},

	resetMessagesPaginationDetailsActions: () => (dispatch: any) => {
		dispatch(updatePageNo(1));
		dispatch(updateLastVisitedRow(null));
		dispatch(updateFirstVisitedRow(null));
	},

	updateMessagesWithTransaltedTextAction:
		(messagesToTranslate: Array<string>, targetLanguage: string, successCallback: any, errorCallback?: any) =>
		(dispatch: any, getState: () => RootState) => {
			const body = {
				bot_ref: getState().Bot.data.bot_ref,
				cutomer_id: getState().User.botAdmin.data.cid,
				messages: messagesToTranslate,
				target_language: targetLanguage,
			};

			dispatch(setTranslationStatusOfMessagesAction(TRANSLATION_STATUS.IN_PROGRESS));

			messageOverviewAPI.getTranslatedMessages(body).then((response: any) => {
				if (isValidResponseObject(response)) {
					const translatedMessages = response.data.responseObject.messages;

					successCallback && successCallback(translatedMessages);
					dispatch(setTranslationStatusOfMessagesAction(TRANSLATION_STATUS.SUCCESS));
				} else {
					errorCallback && errorCallback();
					dispatch(setTranslationStatusOfMessagesAction(TRANSLATION_STATUS.FAILURE));
				}
			});
		},

	getTranslatedMessagesAction:
		(messages: Array<IObjProps>, fromIndex: number, toIndex: any) => (dispatch: any, getState: () => RootState) => {
			const {
				text: TEXT,
				messageReceived: MESSAGE_RECEIVED,
				options: OPTIONS,
				carousel: CAROUSEL,
				feedBack: FEEDBACK,
				postBack: POSTBACK,
				identity: IDENTITY,
				webView: WEB_VIEW,
				agentParticipation: AGENT_PARTICIPATION_STATUS,
				storyReceived: STORY_RECEIVED,
				WA_CONTACT,
				COMMENT_RECEIVED,
				COMMENT,
			} = MESSAGE_TYPE_MAP;
			const messageTypesWithoutParsing = [TEXT, MESSAGE_RECEIVED, POSTBACK, COMMENT, WA_CONTACT];
			const messageTypesWithParsing = [
				AGENT_PARTICIPATION_STATUS,
				OPTIONS,
				FEEDBACK,
				CAROUSEL,
				WEB_VIEW,
				STORY_RECEIVED,
				COMMENT_RECEIVED,
				WA_CONTACT,
			];

			const userMessages = [...messages];
			const targetLanguage = getState().User.profile.data.preferredLanguage || DEFAULT_LANGUAGE_CODE;
			const messageEventIdMap: IObjProps = new Map();

			for (let index = fromIndex; index <= toIndex; index++) {
				const selectedMessage = { ...userMessages[index] };
				const { event_id, message, messagetype, platform } = selectedMessage;

				if (messageTypesWithoutParsing.indexOf(messagetype) > -1) {
					messageEventIdMap.set(event_id, message);
				} else if (messageTypesWithParsing.indexOf(messagetype) > -1) {
					const parsedSelectedMessage = JSON.parse(message);

					if (messagetype === AGENT_PARTICIPATION_STATUS) {
						messageEventIdMap.set(event_id, parsedSelectedMessage.PARTICIPATION_MESSAGE);
					} else if (messagetype === OPTIONS) {
						parsedSelectedMessage.map((option: any, index: number) => {
							const key = `${event_id}||${index}`;
							messageEventIdMap.set(key, option['text']);
						});
					} else if (messagetype === FEEDBACK) {
						messageEventIdMap.set(event_id, parsedSelectedMessage.message);
					} else if (messagetype === CAROUSEL) {
						parsedSelectedMessage.map((option: any, index: number) => {
							const titleKey = `${event_id}||${index}||` + `title`;
							messageEventIdMap.set(titleKey, option['title']);

							if (option['subtitle']) {
								const subTitleKey = `${event_id}||${index}||` + `subtitle`;
								messageEventIdMap.set(subTitleKey, option['subtitle']);
							}
						});
					} else if (messagetype === WEB_VIEW) {
						messageEventIdMap.set(event_id, parsedSelectedMessage.url_prefix_msg);
					} else if (messagetype === STORY_RECEIVED) {
						const messageKey = `${event_id}||` + `message`;
						messageEventIdMap.set(messageKey, parsedSelectedMessage.message);

						if (parsedSelectedMessage.storyReplyText) {
							const storyReplyKey = `${event_id}||` + `storyReplyText`;
							messageEventIdMap.set(storyReplyKey, parsedSelectedMessage.storyReplyText);
						}
					} else if (messagetype === WA_CONTACT) {
						const messageKey = `${event_id}||` + `message`;
						messageEventIdMap.set(messageKey, parsedSelectedMessage.message);
					} else if (messagetype === COMMENT_RECEIVED) {
						const commentKey = `${event_id}||` + `comment`;
						messageEventIdMap.set(commentKey, parsedSelectedMessage.comment);

						if (parsedSelectedMessage.description) {
							const commentDescriptionKey = `${event_id}||` + `description`;
							messageEventIdMap.set(commentDescriptionKey, parsedSelectedMessage.description);
						}
					}
				} else if (messagetype === IDENTITY) {
					if (platform === 'web') {
						const parsedSelectedMessage = JSON.parse(message);
						messageEventIdMap.set(event_id, parsedSelectedMessage.title);
					} else {
						messageEventIdMap.set(event_id, message);
					}
				}

				userMessages[index] = { ...selectedMessage };
			}

			if (isEmpty(Array.from(messageEventIdMap.values()))) {
				return;
			}

			dispatch(
				updateMessagesWithTransaltedTextAction(
					Array.from(messageEventIdMap.values()),
					targetLanguage,
					(translatedMessages: any) => {
						const keysArray = Array.from(messageEventIdMap.keys());

						translatedMessages.map((element: any, index: number) => {
							const key = keysArray[index];
							messageEventIdMap.set(key, element['translatedText']);
						});

						const allUserMessages = [...messages];
						const preTranslatedMessages = allUserMessages.slice(fromIndex, toIndex + 1);

						// Replacing translated text in original message
						keysArray.forEach((key: any) => {
							const eventIdKey = key.split('||')[0];
							const indexOfMessageWithEventIdAsKey = preTranslatedMessages.findIndex(
								(message) => message.event_id === eventIdKey
							);

							if (indexOfMessageWithEventIdAsKey <= -1) {
								return;
							}
							const selectedMessage: IObjProps = {
								...preTranslatedMessages[indexOfMessageWithEventIdAsKey],
							};
							const { messagetype, platform } = selectedMessage;
							selectedMessage.isTranslated = true;

							if (messageTypesWithoutParsing.indexOf(messagetype) > -1) {
								selectedMessage['translatedMessage'] = messageEventIdMap.get(key);
							} else if (messageTypesWithParsing.indexOf(messagetype) > -1) {
								const parsedMessage = JSON.parse(selectedMessage['translatedMessage']);

								if (messagetype === AGENT_PARTICIPATION_STATUS) {
									parsedMessage['PARTICIPATION_MESSAGE'] = messageEventIdMap.get(key);
								} else if (messagetype === OPTIONS) {
									const optionIndex = key.split('||')[1];
									parsedMessage[optionIndex]['text'] = messageEventIdMap.get(key);
								} else if (messagetype === FEEDBACK) {
									parsedMessage['message'] = messageEventIdMap.get(key);
								} else if (messagetype === CAROUSEL) {
									const optionIndex = key.split('||')[1];
									const textToReplace = key.split('||')[2];
									parsedMessage[optionIndex][textToReplace] = messageEventIdMap.get(key);
								} else if (messagetype === WEB_VIEW) {
									parsedMessage['url_prefix_msg'] = messageEventIdMap.get(key);
								} else if (messagetype === STORY_RECEIVED) {
									const textToReplace = key.split('||')[1];
									parsedMessage[textToReplace] = messageEventIdMap.get(key);
								} else if (messagetype === COMMENT_RECEIVED) {
									const textToReplace = key.split('||')[1];
									parsedMessage[textToReplace] = messageEventIdMap.get(key);
								}

								selectedMessage['translatedMessage'] = JSON.stringify(parsedMessage);
							} else if (messagetype === IDENTITY) {
								if (platform === 'web') {
									const parsedMessage = JSON.parse(selectedMessage['translatedMessage']);
									parsedMessage['title'] = messageEventIdMap.get(key);
									selectedMessage['translatedMessage'] = JSON.stringify(parsedMessage);
								} else {
									selectedMessage['translatedMessage'] = messageEventIdMap.get(key);
								}
							}

							preTranslatedMessages[indexOfMessageWithEventIdAsKey] = { ...selectedMessage };
						});
						allUserMessages.splice(fromIndex, preTranslatedMessages.length, ...preTranslatedMessages);

						dispatch(getUserMessages(allUserMessages));
					},
					() => {
						const allUserMessages = [...messages];

						for (let index = fromIndex; index <= toIndex; index++) {
							const selectedMessage = { ...allUserMessages[index] };
							selectedMessage.isTranslated = false;

							allUserMessages[index] = { ...selectedMessage };
						}
						dispatch(getUserMessages(allUserMessages));
					}
				)
			);
		},
	setTranslationStatusOfMessagesAction: (translationStatus: string) => (dispatch: any) => {
		dispatch(setTranslationStatusOfMessages(translationStatus));
	},
	setTranslationStatusOfAgentAction: (translationStatusOfAgent: boolean) => (dispatch: any) => {
		dispatch(setTranslationStatusOfAgent(translationStatusOfAgent));
	},
	setEndUserTranslationLanguageAction: (language: string) => (dispatch: any) => {
		dispatch(setEndUserTranslationLanguage(language));
	},
	setUserMessagesAction: (data: any) => (dispatch: any) => {
		dispatch(setUserMessages(data));
	},
	setIsAutoTranslateEnabledInLiveChatAction: (isAutoTranslateEnabledInLiveChat: boolean) => (dispatch: any) => {
		dispatch(setIsAutoTranslateEnabledInLiveChat(isAutoTranslateEnabledInLiveChat));
	},
	markConversationStarredAndUnstarredAction:
		(selectedUser: IObjProps, isStarred: boolean) => (dispatch: any, getState: () => RootState) => {
			const { uid } = getState().User.botAdmin.data;
			let updateStarredValue = {};
			if (selectedUser.hasOwnProperty('is_starred_conversation')) {
				updateStarredValue = { is_starred_conversation: isStarred };
			} else {
				updateStarredValue = isStarred ? { starred_by: [uid] } : { starred_by: [] };
			}
			const updatedUser = { ...selectedUser, ...updateStarredValue };

			dispatch(starredConversationEmitter(selectedUser.conversation_id, isStarred));
			if (getState().Message.selectedUser.conversation_id === selectedUser.conversation_id) {
				dispatch(updateSelectedUserInUserListAction(updatedUser));
			} else {
				dispatch(updateUserInUserListAction(updatedUser));
			}
		},
	setSmartAssistEnablementAction: (isSmartAssistEnabled: boolean) => (dispatch: any) => {
		dispatch(setSmartAssistEnablement(isSmartAssistEnabled));
	},
	setSmartAssistOpenAction: (isSmartAssistOpened: boolean) => (dispatch: any) => {
		dispatch(setSmartAssistOpen(isSmartAssistOpened));
	},
	generateConversationSummaryAction: (selectedUser: IObjProps) => (dispatch: any, getState: () => RootState) => {
		const botKey = getState().Bot.data.bot_key;
		const botId = getState().Bot.data.bot_ref;
		const customerId = getState().User.botAdmin.data.cid;
		const isEsenseGptBrandingEnabled = getState().User.botAdmin.data.enable_esensegpt_branding;
		const data = {
			user_bot_ref: selectedUser.bot_ref,
			bot_key: botKey,
			latest_active_livechat_agent_common_admin_uid: selectedUser.actor_id,
			latest_active_livechat_agent_display_name: selectedUser.actor_email,
			user_id: selectedUser.user_id,
			conversation_id: selectedUser.conversation_id,
			channel: selectedUser.platform,
			customer_id: customerId,
			bot_ref: botId,
			cs_request_id: selectedUser.cs_request_id,
			is_esense_gpt_branding_enabled: isEsenseGptBrandingEnabled,
		};

		messageOverviewAPI.generateConversationSummary(data);
	},
	setDraftMessageByConversationId:
		(message: string, conversation_Id: any) => (dispatch: any, getState: () => RootState) => {
			const newData = { [conversation_Id]: message };
			const draftMessage = dispatch(getDraftMessage());
			const dataToBeStored = { ...draftMessage, ...newData };
			localStorage.setItem(
				`draft_message_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`,
				JSON.stringify(dataToBeStored)
			);
		},
	getDraftMessage: () => (dispatch: any, getState: () => RootState) => {
		const message = localStorage.getItem(
			`draft_message_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`
		);
		if (message && message !== 'undefined') {
			return JSON.parse(
				localStorage.getItem(
					`draft_message_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`
				) || '{}'
			);
		}
	},
	removeDraftMessageByConversationId: (conversation_Id: any) => (dispatch: any, getState: () => RootState) => {
		const draftMessage = dispatch(getDraftMessage());
		if (draftMessage) {
			delete draftMessage[conversation_Id];
			localStorage.setItem(
				`draft_message_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`,
				JSON.stringify(draftMessage)
			);
		}
	},
	getUserAttributesAction:
		(cancelToken?: any, successCB?: Function) => (dispatch: any, getState: () => RootState) => {
			dispatch(setUserAttributesLoading());
			const payload = {
				botRef: '{{botRef}}',
				userId: getState().Message.selectedUser.id,
				additionalAttributes: [
					'customer',
					'filter_faq_category',
					'user.zendesk',
					'user.gorgias',
					'user.freshdesk',
					'user.language',
					'Live chat category',
					'user.first_name',
				],
			};
			messageDetailsAPI
				.getUserDetails(payload, {
					cancelToken,
				})
				.then((response: any) => {
					if (isValidResponseObjectForUserAPI(response)) {
						const content = response.data.data;
						dispatch(setUserAttributes(content));
						if (content.hasOwnProperty(['user.language'])) {
							dispatch(setEndUserTranslationLanguageAction(content['user.language']));
						}
						successCB && successCB();
					} else {
						dispatch(setUserAttributesError());
					}
				})
				.catch(() => {
					dispatch(setUserAttributesError());
				});
		},
	setUserAttributesLoading: () => (dispatch: any) => {
		dispatch(setUserAttributesLoading());
	},
	setUserAttributesError: () => (dispatch: any) => {
		dispatch(setUserAttributesError());
	},
	setUserAttributesAction: (data: IObjProps) => (dispatch: any) => {
		dispatch(setUserAttributes(data));
	},
};
