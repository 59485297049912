import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { PLACEMENT } from 'baseui/popover';
import { toaster } from 'baseui/toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import FilterSelectionDot from 'components/FilterSelectionDot/FilterSelectionDot';
import ChannelFilter from 'components/shared/BroadcastRevamp/ChannelFilter';
import DateFilter from 'components/shared/BroadcastRevamp/DateFilter';
import DefaultDatePicker from 'components/UI/ENGTDatePicker/DefaultDatePicker';
import ENGTSelect from 'components/UI/ENGTSelect/ENGTSelect';
import ENGTToasterContainer from 'components/UI/ENGTToaster/ENGTToasterContainer';
import ENGTTooltip from 'components/UI/ENGTTooltip/ENGTTooltip';
import FilterDropdown from 'components/UI/FilterDropdown/FilterDropdown';
import FormLabel from 'components/UI/Form/FormLabel/FormLabel';
import ModalWithSections from 'components/UI/Modal/ModalWithSections/ModalWithSections';

import { AGGREGATED_BROADCASTS } from 'shared/consts/broadcast/constants';
import { circularButtonCss, ENGATI_RESPONSE_CODE } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { BROADCAST_STATUS } from 'shared/enum';
import { isEmpty } from 'shared/helpers';
import { getChannel, getChannelValueBasedOnVersion, isChannelsArray } from 'shared/helpers/broadcastHelpers';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';
import ArrowIcon from 'shared/icons/Broadcast/ArrowIcon';
import FilteredIcon from 'shared/icons/FilteredIcon';

import {
	isAggregatedBroadcastUserAnalyticsEntitlementEnabled,
	isBroadcastV2EntitlementEnabled,
	isDialog360ConfiguredAsProvider,
	isWhatsappAcceleratorTrialEnabled,
	isWhatsAppConfiguredSelector,
	marketingCampaignsAnalyticsSelector,
} from 'store/App/User/selectors';
import { botRefSelector, configuredChannelsForBot } from 'store/Bot/selectors';
import { getPublishedBroadcastAction } from 'store/Broadcast';
import { API } from 'store/Broadcast/api';
import { publishedBroadcastList, publishedFilters } from 'store/Broadcast/selectors';
import { getAggregatedAnalyticsAction } from 'store/Download/actions';
import { customerIdSelector, userId } from 'store/Users/selectors';

function BroadcastFilter() {
	const [css, theme]: [any, any] = useStyletron();
	const dispatch: any = useDispatch();
	const { t } = useTranslation(['common', 'pages']);

	const broadcastData = useSelector(publishedBroadcastList);
	const { data } = broadcastData;
	const cid = useSelector(customerIdSelector);
	const uid = useSelector(userId);
	const botRef = useSelector(botRefSelector);
	const filename = `BroadcastsReport_${botRef}.csv`;
	const workflow = 'AGGREGATED_ANALYTICS';

	const { sort, pagination: broadcastPagination } = useSelector(publishedBroadcastList);
	const { startDate, endDate, channels } = useSelector(publishedFilters);
	const configuredChannels = useSelector(configuredChannelsForBot);
	const { size, page } = broadcastPagination;
	const [currentChannel, setCurrentChannel] = useState<string>('');
	const isWhatsappConfigured = useSelector(isWhatsAppConfiguredSelector);
	const isDialog360Configured = useSelector(isDialog360ConfiguredAsProvider);
	const isBroadcastV2Enabled = useSelector(isBroadcastV2EntitlementEnabled);
	const isWhatsappAcceleratorTrial = useSelector(isWhatsappAcceleratorTrialEnabled);
	const [isExportUserDatePickerModel, toggleExportUserDatePickerModel] = useState(false);

	const { isSmallDesktopScreen } = useResponsiveSize();
	interface ISelectValue {
		id: string;
		label: string;
	}
	enum DATE_FILTER_OPTION {
		LAST_24_HOURS = 'last24Hours',
		LAST_7_DAYS = 'last7Days',
		LAST_30_DAYS = 'last30Days',
		CUSTOM_DAYS = 'customDays',
	}
	enum EXPORT_ON_OPTION {
		BROADCASTS = 'Broadcasts',
		BROADCASTS_AND_USERS = 'Broadcasts and Users',
	}
	const dateFilters = [
		{ id: DATE_FILTER_OPTION.LAST_24_HOURS, label: t('pages:broadcast.analytics.aggregatedBroadcast.last24Hours') },
		{ id: DATE_FILTER_OPTION.LAST_7_DAYS, label: t('pages:broadcast.analytics.aggregatedBroadcast.last7Days') },
		{ id: DATE_FILTER_OPTION.LAST_30_DAYS, label: t('pages:broadcast.analytics.aggregatedBroadcast.last30Days') },
		{ id: DATE_FILTER_OPTION.CUSTOM_DAYS, label: t('pages:broadcast.analytics.aggregatedBroadcast.customDays') },
	];
	const exportOnFilter = [
		{
			id: EXPORT_ON_OPTION.BROADCASTS,
			label: t('pages:broadcast.analytics.aggregatedBroadcast.broadcasts'),
		},
		{
			id: EXPORT_ON_OPTION.BROADCASTS_AND_USERS,
			label: t('pages:broadcast.analytics.aggregatedBroadcast.broadcastsAndUsers'),
		},
	];
	const [dateSelected, setDateSelected] = React.useState<Array<ISelectValue>>([dateFilters[2]]);
	const [exportTypeSelected, setExportTypeSelected] = useState<Array<IObjProps>>([exportOnFilter[0]]);
	const [customFilter, setCustomFilter] = React.useState<any>([]);
	const [isCustomFilter, setIsCustomFilter] = useState(false);
	const [filterStartDate, setFilterStartDate] = useState<Date>();
	const [filterEndDate, setFilterEndDate] = useState<Date>();
	const isAggregatedBroadcastUserAnalyticsEnabled = useSelector(isAggregatedBroadcastUserAnalyticsEntitlementEnabled);
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const [minDate, setMinDate] = useState<Date>();
	const [maxDate, setMaxDate] = useState<Date>();

	useEffect(() => {
		setMaxDate(new Date());
		const date = new Date();
		date.setDate(date.getDate() - 90);
		setMinDate(date);
	}, []);

	const getEarliestDate = (data: any[]) => {
		const earliestDate = data
			.map((broadcast) => broadcast.publishedOn)
			.sort(
				(publishedPrevious, publishedNext) =>
					new Date(publishedPrevious).getTime() - new Date(publishedNext).getTime()
			)[0];

		return earliestDate;
	};

	const fetchBroadcastData = (startDate: string, endDate: string, channels: string | string[] | null) => {
		const params: IObjProps = {
			targetApi: isBroadcastV2Enabled ? 'broadcastHistoryV2' : 'broadcastHistory',
			status: BROADCAST_STATUS.BATCHING,
			page: 0,
			size,
			sort,
			startDate,
			endDate,
			channels,
		};

		dispatch(getPublishedBroadcastAction(params, sort));
	};

	const onApplyDateFilter = (date: string, filterIndex: number) => {
		const channel = getChannel(isChannelsArray(channels), configuredChannels, isBroadcastV2Enabled);

		const modifiedStartDate = filterIndex === 0 ? date : startDate;
		const modifiedEndDate = filterIndex === 1 ? date : endDate;
		fetchBroadcastData(modifiedStartDate, modifiedEndDate, channel);
	};

	const onApplyChannelFilter = (channel: string) => {
		const channelSelected = getChannelValueBasedOnVersion(channel, isBroadcastV2Enabled);
		setCurrentChannel(channel);
		fetchBroadcastData(startDate, endDate, channelSelected);
	};

	const earliestDateValue = getEarliestDate(data);

	const exportData = (data: any) => {
		const exportData = {
			customerId: cid,
			userId: uid,
			fileName: filename,
			workflow,
			botRef,
			rowId: 0,
			startDate: isAggregatedBroadcastUserAnalyticsEnabled ? filterStartDate : startDate || earliestDateValue,
			endDate: isAggregatedBroadcastUserAnalyticsEnabled ? filterEndDate : endDate || new Date(),
			broadcastVersion: isBroadcastV2Enabled ? 'V2' : 'V1',
		};

		API.exportData(exportData)
			.then((resp: any) => {
				if (resp?.data?.status) {
					if (resp.data.status.code === ENGATI_RESPONSE_CODE.SUCCESS) {
						const rowId = resp.data.responseObject;
						dispatch(getAggregatedAnalyticsAction(rowId));
						toaster.positive(
							<ENGTToasterContainer
								title={t('pages:analytics.livechat.summary.download.toasterInProgressHeading')}
								description={t(
									'pages:analytics.livechat.summary.download.toasterInProgressDescription'
								)}
							/>,
							{}
						);
					} else {
						toaster.negative(
							<ENGTToasterContainer
								title={t('common:error')}
								description={t('pages:broadcast.analytics.downloadingFailed')}
							/>,
							{}
						);
					}
				}
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			});
	};

	const exportAggregatedData = () => {
		const exportData = {
			customerId: cid,
			userId: uid,
			fileName: filename,
			workflow: AGGREGATED_BROADCASTS[1],
			botRef,
			rowId: 0,
			startDate: filterStartDate,
			endDate: filterEndDate,
			broadcastVersion: isBroadcastV2Enabled ? 'V2' : 'V1',
		};
		API.exportAggregatedBroadcastUsersAnalytics(exportData)
			.then((resp: any) => {
				if (resp?.data?.status) {
					if (resp?.data?.responseObject === -1) {
						toaster.negative(
							<ENGTToasterContainer
								title={t('common:error')}
								description={t('pages:broadcast.analytics.noBroadcastForDateRange')}
							/>,
							{}
						);
					} else if (resp.data.status.code === ENGATI_RESPONSE_CODE.SUCCESS) {
						toaster.positive(
							<ENGTToasterContainer
								title={t('pages:analytics.livechat.summary.download.toasterInProgressHeading')}
								description={t(
									'pages:analytics.livechat.summary.download.toasterInProgressDescription'
								)}
							/>,
							{}
						);
					} else {
						toaster.negative(
							<ENGTToasterContainer
								title={t('common:error')}
								description={t('pages:broadcast.analytics.downloadingFailed')}
							/>,
							{}
						);
					}
				}
			})
			.catch(() => {
				toaster.negative(
					<ENGTToasterContainer title={t('common:error')} description={t('common:somethingWentWrong')} />,
					{}
				);
			});
	};

	useEffect(() => {
		if (dateSelected?.[0]?.id === DATE_FILTER_OPTION.CUSTOM_DAYS) {
			setIsCustomFilter(true);
		} else {
			setIsCustomFilter(false);
			const currentDate: Date = new Date();
			const fromDate: Date = new Date();
			switch (dateSelected?.[0]?.id) {
				case DATE_FILTER_OPTION.LAST_24_HOURS: {
					fromDate.setDate(currentDate.getDate() - 1);
					break;
				}
				case DATE_FILTER_OPTION.LAST_7_DAYS: {
					fromDate.setDate(currentDate.getDate() - 7);
					break;
				}
				case DATE_FILTER_OPTION.LAST_30_DAYS: {
					fromDate.setDate(currentDate.getDate() - 30);
					break;
				}
			}
			setFilterStartDate(fromDate);
			setFilterEndDate(currentDate);
		}
	}, [dateSelected]);

	const updateFilter = (custom: Array<any>) => {
		setCustomFilter(custom);
		setFilterStartDate(custom[0]);
		let customEndDate = custom[1];
		if (custom[1] !== null) {
			customEndDate = new Date(custom[1]);
			customEndDate.setDate(customEndDate.getDate() + 1);
		}
		setFilterEndDate(customEndDate);
	};

	useEffect(() => {
		if (customFilter[1] === null && customFilter[0] !== null) {
			const toDate = new Date(customFilter[0]);
			toDate.setDate(toDate.getDate() + 30);
			if (toDate < new Date()) {
				setMaxDate(toDate);
			}
		}
		if (customFilter[1] !== null) {
			setMaxDate(new Date());
		}
	}, [customFilter]);

	const exportUserDatePickerComponent = () => (
		<ModalWithSections
			confirmBtnLabel={t('pages:broadcast.analytics.aggregatedBroadcast.export')}
			isOpen={isExportUserDatePickerModel}
			heading={t('pages:broadcast.analytics.aggregatedBroadcast.heading')}
			onClose={() => {
				toggleExportUserDatePickerModel(false);
			}}
			isConfirmBtnDisabled={!dateSelected?.[0]?.id}
			onConfirm={() => {
				if (exportTypeSelected[0].id === EXPORT_ON_OPTION.BROADCASTS) {
					exportData(data);
				} else {
					exportAggregatedData();
				}
				toggleExportUserDatePickerModel(false);
			}}
			modalBodyCssOverride={{
				marginLeft: '0rem !important',
				marginRight: '0rem !important',
				marginBottom: '0.5rem !important',
			}}
			footerCss={{
				display: 'flex',
				justifyContent: 'space-between',
				marginTop: 'auto !important',
				marginBottom: '1rem !important',
				paddingTop: '1rem !important',
				paddingBottom: '1rem !important',
				marginRight: '2rem !important',
				marginLeft: '2rem !important',
			}}
			headerCss={{
				fontSize: '1.25rem !important',
				fontWeight: '700 !important',
				lineHeight: '2.06rem !important',
				textAlign: 'left',
				marginTop: '1.5rem !important',
				color: theme.modalTextColor,
				marginLeft: '2rem !important',
				marginRight: '2rem !important',
			}}
			width='26.375rem'
			cancelButtonCss={{
				marginRight: '0rem !important',
				width: '10.938rem',
			}}
			actionButtonCss={{
				width: '10.938rem',
			}}
		>
			<>
				<FormLabel id='exportData' label={t('pages:broadcast.analytics.aggregatedBroadcast.export')} />
				<ENGTSelect
					onChange={setExportTypeSelected}
					options={exportOnFilter}
					clearable={false}
					placeholder={t('components:usersMenu.select')}
					popupIndex={theme.zIndex400}
					selectedValue={exportTypeSelected}
				/>
				<div
					className={css({
						marginTop: '0.5rem',
						lineHeight: '1.65rem',
						fontSize: '0.875rem',
						fontWeight: 'normal',
						color: theme.modalTextColor,
					})}
				>
					{t('components:usersMenu.dateRange')}
				</div>
				<ENGTSelect
					multi={false}
					clearable={false}
					onChange={setDateSelected}
					options={dateFilters}
					placeholder={t('components:usersMenu.select')}
					popupIndex={theme.zIndex400}
					selectedValue={dateSelected}
				/>
				{isCustomFilter && (
					<Block display='flex' justifyContent='space-around' paddingBottom='0.5rem' marginTop='1.5rem'>
						<DefaultDatePicker
							value={customFilter[0]}
							startDate={customFilter[0]}
							endDate={customFilter[1]}
							isPickerRanged
							onChange={(dates: any) => {
								updateFilter(dates);
							}}
							inputWidth='90%'
							maxDate={maxDate}
							minDate={minDate}
						/>
					</Block>
				)}
			</>
		</ModalWithSections>
	);

	const broadcast = !isEmpty(data);

	const broadcastFilter = () => (
		<div
			className={css({
				display: 'flex',
				justifyContent: 'start',
				gap: marketingCampaignsAnalyticsType ? '0.6rem' : '1rem',
				flexFlow: 'wrap',
			})}
		>
			{!isWhatsappAcceleratorTrial && <ChannelFilter applyFilters={onApplyChannelFilter} />}
			<DateFilter applyFilters={onApplyDateFilter} filterIndex={0} />
			<DateFilter applyFilters={onApplyDateFilter} filterIndex={1} />
			{isWhatsappConfigured &&
				isDialog360Configured &&
				(currentChannel === 'whatsapp' || currentChannel === 'all_channels' || currentChannel === '') &&
				broadcast && (
					<ENGTTooltip placement={PLACEMENT.top} content={t('pages:broadcast.analytics.exporttooltip')}>
						<Button
							size={SIZE.default}
							shape='circle'
							kind={KIND.secondary}
							overrides={{
								BaseButton: {
									style: circularButtonCss,
								},
							}}
							onClick={() => {
								if (isAggregatedBroadcastUserAnalyticsEnabled) {
									toggleExportUserDatePickerModel(true);
								} else {
									exportData(data);
								}
							}}
						>
							<ArrowIcon />
						</Button>
					</ENGTTooltip>
				)}
			{isExportUserDatePickerModel && exportUserDatePickerComponent()}
		</div>
	);

	const broadcastFilterForSmallScreen = () => (
		<FilterDropdown
			headingLabel={t('pages:analytics.filter.title')}
			isOpen
			openOnClick
			popoverMargin={5}
			dismissOnClickOutside
			dismissOnEsc={false}
			rootButton={
				<div className={css({ cursor: 'pointer', position: 'relative' })}>
					{FilteredIcon}
					<FilterSelectionDot />
				</div>
			}
			placement={PLACEMENT.auto}
			ignoreBoundary
			className={css({
				zIndex: theme.colors.zIndex100,
			})}
		>
			<Block padding='1rem'>{broadcastFilter()}</Block>
		</FilterDropdown>
	);

	return isSmallDesktopScreen ? broadcastFilterForSmallScreen() : broadcastFilter();
}

export default BroadcastFilter;
