import { useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Location, useLocation } from 'react-router-dom';

import NavItem from 'components/UI/NavItem/NavItem';

import { ACCOUNT_TYPES, DIVIDER, ENGATI_ROLES } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES } from 'shared/enum';
import useLocationEffect from 'shared/hooks/common/useLocationEffect';

import { engagementTriggersEntilement, isWooCommerceEnabledSelector } from 'store/App/Shopify/selectors';
import { isAcceleratorEcomSelector } from 'store/App/User';
import {
	isDialog360ConfiguredAsProvider,
	isEmailTemplatesEnabledSelector,
	isShopifySegmentsEnabled,
	isWhatsAppCloudConfiguredAsProvider,
	isWhatsAppConfiguredSelector,
	isWhatsAppPaymentEnabledSelector,
	marketingCampaignsAnalyticsSelector,
} from 'store/App/User/selectors';
import { isLLMEntitlementEnabled } from 'store/Bot/selectors';
import { RootState } from 'store/rootReducer';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';
import { LEFT_PANEL_ACCESS_ROUTES } from 'router/ProtectedRouter/protectedRoute';

import AdvancedHorizontalDivider from './AdvancedHorizontalDivider';

const ShopifyNavigation = (props: { page: string }) => {
	const { page } = props;
	const location = useLocation();
	const [css, theme]: any = useStyletron();
	const { t } = useTranslation(['common']);
	const [pathInUrl, setCurrentPath] = useState(location.pathname);
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);
	const isDialog360Configured = useSelector(isDialog360ConfiguredAsProvider);
	const isAgent = useSelector(
		(state: RootState) => state.User.profile.data.roleName === ENGATI_ROLES.ROLE_CONNECT_AGENT
	);
	const isSystemSegmentsEnabled = useSelector(isShopifySegmentsEnabled);
	const shopifyProductDiscoveryEntitlement = useSelector(
		(state: RootState) => state.User.botAdmin.data.enable_shopify_product_discovery
	);
	const engagementTriggersEnabled = useSelector(engagementTriggersEntilement);
	const brandName: string = useSelector((state: RootState) => state.User.botAdmin.data?.branding_platform_name);
	const leftPanelsV2Data: any = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2_keys || []);
	const leftPanelsV2: any = useSelector((state: RootState) => state.User.botAdmin.data.left_panels_v2);
	const isWhatsAppCloudConfigured = useSelector(isWhatsAppCloudConfiguredAsProvider);
	const isWoocommerceEnabled = useSelector(isWooCommerceEnabledSelector);
	const isLLMEnabled: boolean = useSelector(isLLMEntitlementEnabled);
	const isWhatsAppConfigured = useSelector(isWhatsAppConfiguredSelector);
	const isWaPaymentEnabled = useSelector(isWhatsAppPaymentEnabledSelector);
	const isAcceleratorEcom = useSelector(isAcceleratorEcomSelector);
	const isAdminMode = useSelector((state: RootState) => state.User.botAdmin.data.is_admin_mode);
	const isEmailTemplatesEnabled = useSelector(isEmailTemplatesEnabledSelector);
	const isMasterAdmin = localStorage.getItem('loggedInUserAccountType') === ACCOUNT_TYPES.MASTER_ADMIN;

	const {
		purchaseFunnelLink,
		productAnalyticsLink,
		exportHistoryLink,
		storeSetupLink,
		storeCustomersLink,
		storeProductsLink,
		storeConversionTriggersLink,
		broadcastEmailTemplates,
	} = ThreeColumnRouteLinks;

	const NavOptions: IObjProps = {
		train: [
			{
				Overview: '/train/overview',
				FAQs: '/train/faq',
				Documents: '/train/cognitive-search',
				'Smart Response Setup': '/train/settings',
			},
			{
				'Tabular Input': '/train/tabular-input',
				Entities: '/train/entities',
				'Response Training': '/train/responses',
			},
		],
		configure: [
			{
				Overview: '/configure/overview',
				'Bot Configuration': '/configure/bot-details',
				'Web Display': '/configure/display',
				'External Live Chat': '/configure/external-livechat',
				'Live Chat': '/configure/live-chat',
				Integrations: '/configure/integrations',
				'Channel Deployment': '/configure/deployment',
			},
			{
				Attributes: '/configure/attributes',
				Languages: '/configure/languages',
				'Voice Integrations': '/configure/advanced/voice',
				Formatting: '/configure/advanced/formatting',
				Security: '/configure/advanced/security',
			},
		],
		analytics: [
			{
				Overview: '/analytics/overview',
				'Bot Engagement': '/analytics/bot/engagement',
				'Bot Responses': '/analytics/bot/response',
				'User Retention Analysis': '/analytics/user/retention',
				'Live Chat Summary': '/analytics/livechat/summary',
				Agents: '/analytics/livechat/agents',
				Channels: '/analytics/bot/channels',
				'Agent Performance': '/analytics/livechat/agent-performance',
				'Agent Conversions': '/analytics/livechat/agent-conversions',
				'Purchase Funnel': '/analytics/livechat/purchase-funnel',
				'Product Analytics': '/analytics/livechat/product-analytics',
				'New Agent Performance': '/analytics/livechat/new-agent-performance',
				'Conversation Overview': '/analytics/livechat/conversation-overview',
				'Agent Assistance FeedBack': '/analytics/livechat/agent-assistance-feedback',
			},
		],
		integrations: [
			{
				'Cloud Integrations': '/integrations/cloud-integrations',
				...(isAdminMode || !isAcceleratorEcom ? { 'BrandName API': '/integrations/platform-api' } : {}),
				'C2E Bridge': '/integrations/c2e-bridge',
				'API Logs': '/integrations/api-logs',
				'Channel Logs': '/integrations/channel-logs',
				'Payment Logs': '/integrations/payment-logs',
			},
		],
		whatsapp: [
			{
				Numbers: '/whatsApp/numbers',
				Flows: '/whatsApp/flows',
			},
		],
		accountSettings: [
			{
				'Profile Settings': '/account-settings/profile',
				'Region Settings': '/account-settings/region',
				'Theme Settings': '/account-settings/theme',
				'Account Details': '/account-settings/account',
				'Usage Details': '/account-settings/usage-details',
				'Team Members': '/account-settings/team-members',
				'Billing Details': '/account-settings/billing',
				'Security Settings': '/account-settings/security',
				'Notification Settings': '/account-settings/notification',
				'Password Settings': '/account-settings/password',
			},
		],
		broadcast: [
			{
				...(marketingCampaignsAnalyticsType
					? {
							[t('common:locations.navigation.analytics')]: DIVIDER,
							[t('common:locations.navigation.execution')]: '/broadcast/execution',
							[t('common:locations.navigation.engagement')]: '/broadcast/engagement',
							...(marketingCampaignsAnalyticsType ===
								MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES.CONVERSION_ENABLED && {
								[t('common:locations.navigation.conversions')]: '/broadcast/conversion',
							}),
							[t('common:setup')]: DIVIDER,
						}
					: { Campaigns: '/broadcast/campaigns', 'Broadcast History': '/broadcast/published' }),
				...(!marketingCampaignsAnalyticsType && {
					'Click to WhatsApp Ads': '/broadcast/clickToWhatsappAds',
				}),
				'Message Templates': '/broadcast/templates',
				'Email Templates': '/broadcast/email-templates',
				'Scheduled Broadcasts': '/broadcast/scheduled',
				'Draft Broadcasts': '/broadcast/draft',
				...(marketingCampaignsAnalyticsType ? { [t('common:locations.navigation.general')]: DIVIDER } : {}),
				'Export History': '/broadcast/exportHistory',
				Help: '/broadcast/help',
			},
		],
		store: [
			{
				Segments: '/store/segments',
				Products: '/store/products',
				'Conversion Triggers': '/store/conversion',
				'Feature Configurator': '/store/feature-configurator',
				Setup: '/store/setup',
			},
		],
	};

	const hasAccessFor = (route: string) => {
		if (route === '/analytics/livechat/agent-performance') {
			return leftPanelsV2.dashboard.agentPerformance || false;
		}
		if (route === '/integrations/payment-logs') {
			return isWhatsAppConfigured && isWaPaymentEnabled;
		}
		if (route === '/analytics/livechat/new-agent-performance') {
			return leftPanelsV2.enable_livechat_analytics_v2 || false;
		}
		if (route === purchaseFunnelLink && isAcceleratorEcom) {
			return false;
		}
		if (route === productAnalyticsLink && isAcceleratorEcom) {
			return false;
		}
		if (
			route === ThreeColumnRouteLinks.broadcastMessageTemplates &&
			!(isDialog360Configured || isWhatsAppCloudConfigured)
		) {
			return false;
		}

		if (route === NavOptions.store[0].Segments && (!isSystemSegmentsEnabled || isAgent)) {
			return false;
		}
		if (route === NavOptions.store[0].Products && !shopifyProductDiscoveryEntitlement) {
			return false;
		}
		if (route === NavOptions.store[0]['Conversion Triggers'] && !engagementTriggersEnabled) {
			return false;
		}
		if (
			isWoocommerceEnabled &&
			(route === NavOptions.analytics[0]['Purchase Funnel'] ||
				route === NavOptions.analytics[0]['Product Analytics'])
		) {
			return false;
		}
		if (
			isAgent &&
			(route === NavOptions.analytics[0]['Agent Conversions'] ||
				route === NavOptions.analytics[0]['Purchase Funnel'] ||
				route === NavOptions.analytics[0]['Product Analytics'])
		) {
			return false;
		}
		if (route === storeSetupLink && isAcceleratorEcom && !isMasterAdmin) {
			return false;
		}
		if (route === storeCustomersLink && isAcceleratorEcom) {
			return false;
		}
		if (route === storeProductsLink && isAcceleratorEcom) {
			return false;
		}
		if (route === storeConversionTriggersLink && isAcceleratorEcom) {
			return false;
		}
		if (route === broadcastEmailTemplates) {
			return isEmailTemplatesEnabled;
		}

		return leftPanelsV2Data.indexOf(LEFT_PANEL_ACCESS_ROUTES[route]) > -1 || LEFT_PANEL_ACCESS_ROUTES[route] === '';
	};

	const getLabel = (item: string) => {
		if (item === 'New Agent Performance') {
			if (!leftPanelsV2.dashboard.agentPerformance && leftPanelsV2.enable_livechat_analytics_v2) {
				return 'Agent Performance';
			}
		}
		if (item.indexOf('BrandName') > -1) {
			return brandName ? item.replace('BrandName', brandName) : item.replace('BrandName', '');
		}
		if (item === 'Documents') {
			return isLLMEnabled
				? t('common:locations.navigation.generativeAI')
				: t('common:locations.navigation.documents');
		}

		return item;
	};

	const hasAccessToAnyOfTheMenuItems = (options: any) => {
		for (const key in options) {
			if (hasAccessFor(options[key])) {
				return true;
			}
		}

		return false;
	};

	useLocationEffect((location: Location) => setCurrentPath(location.pathname));

	return NavOptions[page] ? (
		NavOptions[page].map((options: any, index: number) => (
			<Block key={`secondary-nav-${index}`}>
				{index && hasAccessToAnyOfTheMenuItems(options) ? (
					<AdvancedHorizontalDivider item={t('common:advanced')} />
				) : null}
				{Object.keys(options).map((item: string) =>
					options[item] === DIVIDER ? (
						<AdvancedHorizontalDivider item={item} />
					) : (
						hasAccessFor(options[item]) && (
							<NavItem
								key={item}
								label={getLabel(item)}
								to={options[item]}
								isSelected={pathInUrl.indexOf(options[item]) > -1}
								className={css({
									marginBottom: '-0.125rem',
									paddingTop: '0.5rem',
								})}
								dataQa={item}
							/>
						)
					)
				)}
			</Block>
		))
	) : (
		<></>
	);
};

export default ShopifyNavigation;
