import BaseService from 'services/baseService';
import { API_GATEWAY_URL } from 'shared/consts/consts';
import { serialize } from 'shared/helpers';

const generalProxy = '/v2/genericProxyRequest/';
const getAvailableAgentsUrl = `${generalProxy}${serialize(
	{
		targetApi: 'getAgentsOnline',
	},
	'?'
)}`;
const allSwitchToBotFlowsUrl = '/api/v1/getpathList';
const apiGatewayRequest = new BaseService(API_GATEWAY_URL);

const getAvailableAgentsApi = new BaseService(getAvailableAgentsUrl);
const generalProxyApi = new BaseService(generalProxy);
const allSwitchToBotFlowsApi = new BaseService(allSwitchToBotFlowsUrl);

export const messageListAPI = {
	getAllUsersCount(query = '') {
		const params = {
			agent_id: '{{userId}}',
			bot_ref: '{{botRef}}',
			targetApi: 'getConversationUserCounts',
			solr_query: query,
		};

		return generalProxyApi.getRequest(serialize(params, '?'));
	},
	getAvailableAgents(data) {
		return getAvailableAgentsApi.postRequest(data);
	},
	getLiveChatUsers(params, data, cancelSignalToken) {
		const url = `${generalProxy}${serialize(params, '?')}`;
		const getLiveChatUsersApi = new BaseService(url, cancelSignalToken);

		return getLiveChatUsersApi.postRequest(data);
	},
	sendNewConversationEmail(data) {
		const params = {
			targetApi: 'sendNewConversationEmail',
		};
		const url = `${generalProxy}${serialize(params, '?')}`;
		const sendNewConversationEmailApi = new BaseService(url);

		return sendNewConversationEmailApi.postRequest(data);
	},
	getUsersSolarQuery: (params, cancelToken) => {
		const getSolrQueryApi = new BaseService(generalProxy, cancelToken);

		return getSolrQueryApi.getRequest(serialize(params, '?').replace('+1', '%2B1'));
	},
	getGlobalUsersBySolrQuery: (data) => {
		const params = {
			targetApi: 'getSearchUsersAndMessages',
		};
		const url = `${generalProxy}${serialize(params, '?')}`;
		const sendGlobalUsersRequest = new BaseService(url);

		return sendGlobalUsersRequest.postRequest(data);
	},
	getSearchUsersAndMessages: (params) => {
		const getSolrQueryApi = new BaseService(generalProxy);

		return getSolrQueryApi.getRequest(serialize(params, '?'));
	},
	getCsRequest: (params) => generalProxyApi.getRequest(serialize(params, '?')),
	getAttributeValueForUsers: (usersList, attributeName) => {
		const params = {
			targetApi: 'getAttributeValueForUsers',
		};
		const data = {
			userIds: usersList,
			name: attributeName,
		};

		return generalProxyApi.postRequest(data, serialize(params, '?'));
	},
	getTags: () => {
		const params = {
			targetApi: 'getLabelsPerBot',
			labelTypes: 'TAGS',
		};

		return generalProxyApi.getRequest(serialize(params, '?'));
	},
	createUser: (data) => {
		const params = {
			targetApi: 'createUser',
			customerId: '{{customerId}}',
			userId: '{{userId}}',
		};
		const body = {
			phoneNo: data.phoneNumber,
			platform: data.platform,
			firstName: data.firstName || '',
			lastName: data.lastName || '',
			username: data.username || '',
			email: data.email || '',
			title: data.title || '',
			organization: data.account || '',
			userId: data.userId || '',
			categoryId: data.categoryId || '',
		};

		return generalProxyApi.postRequest(body, serialize(params, '?'));
	},
	getAllSwitchToBotFlows(params) {
		return allSwitchToBotFlowsApi.getRequest(serialize(params, '?'));
	},
	getAgentOnlineStatus: () => {
		const params = {
			targetApi: 'getAgentOnlineStatus',
			agentId: '{{userId}}',
			botRef: '{{botRef}}',
		};

		return generalProxyApi.getRequest(serialize(params, '?'));
	},
	setAgentOnlineStatus: (params, data) => generalProxyApi.postRequest(data, serialize(params, '?')),
	getActiveCSRequestsByConversationIds(params) {
		const apiParams = {
			targetApi: 'getActiveCSRequestsByConversationIds',
			...params,
		};

		return generalProxyApi.getRequest(serialize(apiParams, '?'));
	},
	getOnlineAgents(params) {
		return generalProxyApi.getRequest(serialize(params, '?'));
	},
	addCustomFilterGroup(data) {
		const params = {
			targetApi: 'addCustomFilterGroup',
			...data,
		};

		return generalProxyApi.postRequest(data, serialize(params, '?'));
	},
	getCustomFilterGroup: () => {
		const params = {
			targetApi: 'getCustomFilterGroup',
		};

		return generalProxyApi.getRequest(serialize(params, '?'));
	},
	deleteCustomFilterGroup: (id) => {
		const params = {
			targetApi: 'deleteCustomFilterGroup',
			replaceable_params: {
				filterId: id,
			},
		};

		return generalProxyApi.deleteRequest(serialize(params, '?'));
	},
	getSelectedUserData(data) {
		const params = {
			targetApi: 'getConversationControlData',
		};

		return generalProxyApi.postRequest(data, serialize(params, '?'));
	},
	updateCategoryId(data) {
		const params = {
			targetApi: 'updateCategoryId',
		};
		return apiGatewayRequest.putRequest(data, serialize(params, '?'));
	},
};
