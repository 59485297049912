import {
	ENGATI_PLATFORM_CONNECT,
	MESSAGE_STATE_MAP,
	MESSAGE_TAB_HEADERS_MAP,
	ONE_VIEW_LITE,
} from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { isEmpty } from 'shared/helpers';

import { addNotificationAction } from 'store/App/Notifications';
import { playSoundNotification } from 'store/App/Preferences';
import {
	getFacetsSortFromLs,
	getLiteFacetsSortFromLs,
	getLiteQuickFiltersFromLS,
	getLiveChatUsersAction,
	getUsersSolarQueryAction,
	updateAllUsersCountAction,
} from 'store/Message';
import { RootState } from 'store/rootReducer';

import { isOwner, notificationActionTitles, SOCKET_EVENTS } from '../consts';
import { currentTab } from '../helper';

export const liveChatStatusChangeForAllBots =
	(socket: any, liveChatStatusChange: () => void) => (dispatch: any, getState: any) => {
		const allBotsDetail: Array<IObjProps> = getState().BotList.data;
		const userDetail = getState().User.botAdmin.data;
		if (allBotsDetail?.length !== 0) {
			const liveChatEmitStatusObj: any = {
				uid: userDetail.uid,
				is_supervisor_owner: isOwner(userDetail?.admin_mode_access?.role),
				bot_details: [],
			};
			allBotsDetail.forEach((bot: IObjProps) => {
				liveChatEmitStatusObj.bot_details.push({
					bot_key: bot.bot_key,
					category: bot.bot_category,
					bot_ref: bot.bot_ref,
				});
			});
			if (userDetail?.admin_mode_access?.role) {
				socket && socket.emit(SOCKET_EVENTS.LIVECHAT_STATUS_CHANGE, liveChatEmitStatusObj);
			}
		} else {
			dispatch(liveChatStatusChange());
		}
	};

export const newLiveChatRequestListener =
	(socket: any, liveChatStatusChange: () => void) => (dispatch: any, getState: () => RootState) => {
		socket.removeAllListeners(SOCKET_EVENTS.NEW_LIVECHAT_REQUEST);

		socket.on(SOCKET_EVENTS.NEW_LIVECHAT_REQUEST, (d: any) => {
			const data: any = JSON.parse(d);
			const {
				uid,
				active_module: activeModule,
				is_simplified_one_view_enabled: isSimplifiedOneViewEnabled,
				is_message_search_enabled: isMessageSearchEnabled,
			} = getState().User.botAdmin.data;
			const notificationBotRef = data.bot_ref;
			const userDetail = getState().User.botAdmin.data;
			const { bot_ref } = getState().Bot.data;
			const sameBotRef = bot_ref === notificationBotRef;
			const userListData = getState().Message.userList;

			if ((sameBotRef || !userListData?.data?.length) && isSimplifiedOneViewEnabled) {
				const quickFilters: any = dispatch(getLiteQuickFiltersFromLS());
				if (!isEmpty(quickFilters) && currentTab() === ONE_VIEW_LITE) {
					const defaultFilters: any = dispatch(getLiteFacetsSortFromLs());
					const filters = {
						facets: defaultFilters.facets,
						sort: defaultFilters.sort,
					};
					const currentPage = JSON.parse(localStorage.getItem(`oneviewlite_current_page`) || '1');
					if (!quickFilters?.myConversations) {
						const isSearchQueryPresent = JSON.parse(
							localStorage.getItem(`isSearchQueryPresent_${bot_ref}_${uid}`) || 'false'
						);
						if (isMessageSearchEnabled) {
							const message_search_query = localStorage.getItem(`message_search_query_${bot_ref}_${uid}`);
							!isSearchQueryPresent &&
								message_search_query &&
								dispatch(getUsersSolarQueryAction(filters, currentPage, false, ''));
						} else {
							!isSearchQueryPresent &&
								dispatch(getUsersSolarQueryAction(filters, currentPage, false, ''));
						}
					}
				}
			} else {
				if (sameBotRef && currentTab() === MESSAGE_TAB_HEADERS_MAP.unassigned) {
					const filters: any = dispatch(getFacetsSortFromLs(MESSAGE_TAB_HEADERS_MAP.unassigned));
					const currentPage = JSON.parse(
						localStorage.getItem(`${MESSAGE_TAB_HEADERS_MAP.unassigned}_current_page`) || '1'
					);
					dispatch(getLiveChatUsersAction(filters, currentPage, MESSAGE_TAB_HEADERS_MAP.unassigned));
				}
				const location = window.location.pathname;
				if (location.indexOf('/messages/') > -1) {
					if (sameBotRef && data.is_newly_unassigned) {
						dispatch(updateAllUsersCountAction('add', data, MESSAGE_TAB_HEADERS_MAP.unassigned));
						dispatch(updateAllUsersCountAction('add', data, MESSAGE_TAB_HEADERS_MAP.all));
					}
				}
			}
			dispatch(liveChatStatusChange());

			if (
				!isSimplifiedOneViewEnabled &&
				activeModule === ENGATI_PLATFORM_CONNECT &&
				isOwner(userDetail.admin_mode_access.role)
			) {
				if (isOwner(userDetail.admin_mode_access.role)) {
					let url;

					url = `/messages/unassigned/${data.conversation_id}`;

					const currentTime = new Date().toISOString();

					dispatch(playSoundNotification({ sound: 'request' }));
					dispatch(
						addNotificationAction({
							title: notificationActionTitles.newConversationRequest,
							description: 'Click here to respond',
							url,
							type: SOCKET_EVENTS.NEW_LIVECHAT_REQUEST,
							timestamp: currentTime,
							userId: data.user_id,
							conversationId: data.conversation_id,
							botRef: notificationBotRef,
						})
					);
				}
			}
		});
	};
export const newLiveChatRequestAssignedListener =
	(socket: any, liveChatStatusChange: () => void) => (dispatch: any, getState: () => RootState) => {
		socket.removeAllListeners(SOCKET_EVENTS.NEW_LIVECHAT_ASSIGNED);

		socket.on(SOCKET_EVENTS.NEW_LIVECHAT_ASSIGNED, (d: any) => {
			const data = JSON.parse(d);
			const {
				uid,
				active_module: activeModule,
				is_simplified_one_view_enabled: isSimplifiedOneViewEnabled,
				is_message_search_enabled: isMessageSearchEnabled,
			} = getState().User.botAdmin.data;
			const notificationBotRef = data.bot_ref;
			const userDetail = getState().User.botAdmin.data;
			const userListData = getState().Message.userList;
			const { bot_ref } = getState().Bot.data;
			const sameBotRef = bot_ref === notificationBotRef;

			let url = '';

			if ((sameBotRef || !userListData?.data?.length) && isSimplifiedOneViewEnabled) {
				const quickFilters: any = dispatch(getLiteQuickFiltersFromLS());
				if (!isEmpty(quickFilters) && currentTab() === ONE_VIEW_LITE) {
					const defaultFilters: any = dispatch(getLiteFacetsSortFromLs());
					const filters = {
						facets: defaultFilters.facets,
						sort: defaultFilters.sort,
					};
					const currentPage = JSON.parse(localStorage.getItem(`oneviewlite_current_page`) || '1');
					if (quickFilters?.myConversations) {
						dispatch(getLiveChatUsersAction(filters, currentPage));
					} else {
						const isSearchQueryPresent = JSON.parse(
							localStorage.getItem(`isSearchQueryPresent_${bot_ref}_${uid}`) || 'false'
						);
						if (isMessageSearchEnabled) {
							const message_search_query = localStorage.getItem(`message_search_query_${bot_ref}_${uid}`);
							!isSearchQueryPresent &&
								message_search_query &&
								dispatch(getUsersSolarQueryAction(filters, currentPage, false, ''));
						} else {
							!isSearchQueryPresent &&
								dispatch(getUsersSolarQueryAction(filters, currentPage, false, ''));
						}
					}
				}
			} else {
				if ((sameBotRef || !userListData?.data?.length) && currentTab() === MESSAGE_TAB_HEADERS_MAP.new) {
					const filters: any = dispatch(getFacetsSortFromLs(MESSAGE_TAB_HEADERS_MAP.new));
					const currentPage = JSON.parse(
						localStorage.getItem(`${MESSAGE_TAB_HEADERS_MAP.new}_current_page`) || '1'
					);
					dispatch(getLiveChatUsersAction(filters, currentPage, MESSAGE_TAB_HEADERS_MAP.new));
				}

				const location = window.location.pathname;
				const usersList = getState().Message.userList.data;
				const userFound = usersList?.find((user: IObjProps) => user.conversation_id === data.conversation_id);

				if (location.indexOf('/messages/') > -1) {
					if (sameBotRef && userFound === undefined) {
						dispatch(updateAllUsersCountAction('add', data, MESSAGE_TAB_HEADERS_MAP.new));
						dispatch(updateAllUsersCountAction('add', data, MESSAGE_TAB_HEADERS_MAP.all));
					}
				}
			}
			dispatch(liveChatStatusChange());

			if (activeModule === ENGATI_PLATFORM_CONNECT && userDetail.left_panels.connect.conversation) {
				if (isSimplifiedOneViewEnabled) {
					url = `/allmessages/${data.conversation_id}`;
				} else {
					url = `/messages/new/${data.conversation_id}`;
				}

				const currentTime = new Date().toISOString();

				dispatch(playSoundNotification({ sound: 'request' }));
				dispatch(
					addNotificationAction({
						title: notificationActionTitles.newConversationRequest,
						description: 'Click here to respond',
						url,
						type: SOCKET_EVENTS.NEW_LIVECHAT_ASSIGNED,
						timestamp: currentTime,
						userId: data.user_id,
						conversationId: data.conversation_id,
						botRef: notificationBotRef,
					})
				);
			}
		});
	};

export const liveChatTransferListener =
	(socket: any, liveChatStatusChange: () => void) => (dispatch: any, getState: () => RootState) => {
		socket.removeAllListeners(SOCKET_EVENTS.LIVECHAT_TRANSFER);

		socket.on(SOCKET_EVENTS.LIVECHAT_TRANSFER, (d: any) => {
			const data = JSON.parse(d);
			const { to_state: toState, group_conversation: groupConversation } = data;
			if (groupConversation) {
				return;
			}
			const { active_module: activeModule, is_simplified_one_view_enabled: isSimplifiedOneViewEnabled } =
				getState().User.botAdmin.data;
			const userDetail = getState().User.botAdmin.data;
			const conversationId = data.conversation_id;
			const notificationBotRef = data.bot_ref;
			const currentTime = new Date().toISOString();
			let url = '';
			if (activeModule === ENGATI_PLATFORM_CONNECT && userDetail.left_panels.connect.conversation) {
				if (isSimplifiedOneViewEnabled) {
					url = `/allmessages/${conversationId}`;
					const quickFilters: any = dispatch(getLiteQuickFiltersFromLS());
					if (!isEmpty(quickFilters) && currentTab() === ONE_VIEW_LITE) {
						const defaultFilters: any = dispatch(getLiteFacetsSortFromLs());
						const filters = {
							facets: defaultFilters.facets,
							sort: defaultFilters.sort,
						};
						const currentPage = JSON.parse(localStorage.getItem(`oneviewlite_current_page`) || '1');
						if (quickFilters?.myConversations) {
							dispatch(getLiveChatUsersAction(filters, currentPage));
						} else {
							dispatch(getUsersSolarQueryAction(filters, currentPage, false, ''));
						}
					}
				} else if (toState === MESSAGE_STATE_MAP.ENGAGING || toState === MESSAGE_STATE_MAP.PENDING_RESOLUTION) {
					url = `/messages/active/${conversationId}`;
					if (currentTab() === MESSAGE_TAB_HEADERS_MAP.active) {
						const filters: any = dispatch(getFacetsSortFromLs(MESSAGE_TAB_HEADERS_MAP.active));
						const currentPage = JSON.parse(
							localStorage.getItem(`${MESSAGE_TAB_HEADERS_MAP.active}_current_page`) || '1'
						);
						dispatch(getLiveChatUsersAction(filters, currentPage, MESSAGE_TAB_HEADERS_MAP.active));
					} else {
						dispatch(updateAllUsersCountAction('add', data, MESSAGE_TAB_HEADERS_MAP.active));
					}
				} else if (toState === MESSAGE_STATE_MAP.ASSIGNED) {
					url = `/messages/new/${conversationId}`;
					if (currentTab() === MESSAGE_TAB_HEADERS_MAP.new) {
						const filters: any = dispatch(getFacetsSortFromLs(MESSAGE_TAB_HEADERS_MAP.new));
						const currentPage = JSON.parse(
							localStorage.getItem(`${MESSAGE_TAB_HEADERS_MAP.new}_current_page`) || '1'
						);
						dispatch(getLiveChatUsersAction(filters, currentPage, MESSAGE_TAB_HEADERS_MAP.new));
					} else {
						dispatch(updateAllUsersCountAction('add', data, MESSAGE_TAB_HEADERS_MAP.new));
					}
				}

				dispatch(playSoundNotification({ sound: 'request' }));
				dispatch(
					addNotificationAction({
						title: notificationActionTitles.newConversationAssigned,
						description: `Assigned By - ${data.assigned_by}`,
						url,
						type: SOCKET_EVENTS.LIVECHAT_TRANSFER,
						timestamp: currentTime,
						assignedBy: data.assigned_by,
						userId: data.user_id,
						conversationId: data.conversation_id,
						botRef: notificationBotRef,
					})
				);
				dispatch(liveChatStatusChange());
			}
		});
	};

export const newLiveChatRequestEngagedListener = (socket: any, liveChatStatusChange: () => void) => (dispatch: any) => {
	socket.removeAllListeners(SOCKET_EVENTS.NEW_LIVECHAT_REQUEST_ENGAGED);

	socket.on(SOCKET_EVENTS.NEW_LIVECHAT_REQUEST_ENGAGED, (d: any) => {
		const data = JSON.parse(d);
		if (currentTab() === MESSAGE_TAB_HEADERS_MAP.active) {
			const filters: any = dispatch(getFacetsSortFromLs(MESSAGE_TAB_HEADERS_MAP.active));
			const currentPage = JSON.parse(
				localStorage.getItem(`${MESSAGE_TAB_HEADERS_MAP.active}_current_page`) || '1'
			);
			dispatch(getLiveChatUsersAction(filters, currentPage, MESSAGE_TAB_HEADERS_MAP.active));
			dispatch(liveChatStatusChange());
		}
	});
};
