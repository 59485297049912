import { setLanguageForApp } from 'i18n';

import { ACCOUNT_TYPES, ENGATI_RESPONSE_CODE } from 'shared/consts/consts';
import { IObjProps } from 'shared/consts/types';
import { WA_ACCELERATOR_JOURNEY_PHASE, WA_ACCELERATOR_PQL_STAGES } from 'shared/enum';
import { convertNestedKeysToArray, isEmpty, isValidResponseObject } from 'shared/helpers';

import { messageOverviewAPI } from 'store/Message';
import { messageOverviewActions } from 'store/Message/messageOverview/actions';
import { API as onboardingAPI } from 'store/OnBoarding/api';
import { RootState } from 'store/rootReducer';

import { API } from './api';
import { actions } from './store';

const {
	getBotAdmin,
	getBotAdminError,
	getCategories,
	getProfileDetails,
	getProfileDetailsError,
	getSecurityConfigDetails,
	getSecurityDetailsError,
	getPermittedPlatformsForCustomer,
	getIntegratedAccounts,
	resetAllUserData,
	setInstaInboxCommentsEnablement,
	setGeoLocationData,
	setJounrneyPhaseData,
	setJounrneyPhaseLoading,
	setJounrneyPhaseError,
} = actions;

export const checkForMasterAdmin = (data: IObjProps) => () => {
	const { MASTER_ADMIN, RESELLER, AFFILIATE } = ACCOUNT_TYPES;

	if (data.reseller_login && [MASTER_ADMIN, RESELLER, AFFILIATE].indexOf(data.customer_account_type) > -1) {
		if (!localStorage.getItem('loggedInUsercontext')) {
			localStorage.setItem('adminMode', 'false');
			localStorage.setItem('loggedInUsercontext', data.email);
			localStorage.setItem('loggedInUserAccountType', data.customer_account_type);
			localStorage.setItem('enableOwnerSwap', data.admin_mode_access.enable_owner_swap);
		}
	}
};

export const getIdFromUserIdAction = (uid: any) => (dispatch: any, getState: () => RootState) => {
	const userIdParams = {
		userId: uid,
	};
	messageOverviewAPI.getIdFromUserId(userIdParams).then((response: any) => {
		if (response.status === ENGATI_RESPONSE_CODE.STATUS_200) {
			dispatch(messageOverviewActions.setAgentIdAction(response?.data?.id));
		}
	});
};

export const getBotAdminAction = () => (dispatch: any) =>
	API.fetchBotAdmin().then((resp: any) => {
		if (resp.data) {
			const { data } = resp;
			data['left_panels_v2_keys'] = convertNestedKeysToArray(data.left_panels_v2);
			dispatch(checkForMasterAdmin(data));
			dispatch(getBotAdmin(data));
			dispatch(getIdFromUserIdAction(data.uid));
		} else {
			dispatch(getBotAdminError());
		}

		return resp;
	});

export const getProfileDetailsAction = () => (dispatch: any) =>
	API.fetchProfileDetails().then((resp: any) => {
		if (resp.data) {
			setLanguageForApp(resp);
			dispatch(getProfileDetails(resp.data));
		} else {
			dispatch(getProfileDetailsError());
		}

		return resp;
	});

export const getCategoriesAction = () => (dispatch: any) => {
	const params = {
		targetApi: 'getCategory',
		page: 0,
		size: 200,
	};
	API.getCategories(params).then((response: any) => {
		if (isValidResponseObject(response)) {
			const result = response.data.responseObject.content;
			const categoryList = result.map((category: IObjProps) => ({
				id: category.id,
				name: category.name,
				isDefault: category.isDefault,
			}));
			dispatch(getCategories(categoryList));
		}
	});
};

export const setCategoriesAction = (categoriesList: Array<IObjProps>) => (dispatch: any) => {
	dispatch(getCategories(categoriesList));
};

export const getSecurityConfigDetailsAction = () => (dispatch: any) =>
	API.fetchSecurityConfigDetails().then((resp: any) => {
		if (isValidResponseObject(resp)) {
			dispatch(getSecurityConfigDetails(resp.data.responseObject));
		} else {
			dispatch(getSecurityDetailsError());
		}
	});

export const updateSecurityConfigDetailsAction = (data: IObjProps) => (dispatch: any) =>
	dispatch(getSecurityConfigDetails(data));

export const getPermittedPlatformsForCustomerAction = (successCB?: Function) => (dispatch: any) => {
	API.getPermittedPlatformsForCustomer().then((response: any) => {
		if (response.data) {
			dispatch(getPermittedPlatformsForCustomer(response.data.platforms));
			successCB && successCB();
		}
	});
};

export const setPermittedPlatformsForCustomerAction = (permittedPlatforms: Array<IObjProps>) => (dispatch: any) => {
	dispatch(getPermittedPlatformsForCustomer(permittedPlatforms));
};

export const getIntegrationAccountDetails = (param: any, handleCallback?: any) => (dispatch: any) => {
	API.getIntegrationAccountDetails(param).then((response: any) => {
		handleCallback?.(response);
		if (response.data && response.data.integration_account_details) {
			dispatch(getIntegratedAccounts(response.data.integration_account_details));
		}
	});
};

export const resetAllUserDataAction = () => (dispatch: any) => {
	dispatch(resetAllUserData());
};

export const userFilterActions = {
	setFacetsInLs: (filters: IObjProps) => (dispatch: any, getState: () => RootState) => {
		`${getState().Bot.data.bot_ref}` &&
			`${getState().User.botAdmin.data.uid}` &&
			localStorage.setItem(
				`userFilter_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`,
				JSON.stringify(filters)
			);
	},
	getFacetsFromLs: () => (dispatch: any, getState: () => RootState) =>
		`${getState().Bot.data.bot_ref}` &&
		`${getState().User.botAdmin.data.uid}` &&
		JSON.parse(
			localStorage.getItem(`userFilter_${getState().Bot.data.bot_ref}_${getState().User.botAdmin.data.uid}`) ||
				'{}'
		),
};

export const setInstaInboxCommentsEnablementAction = (shouldEnable: boolean) => (dispatch: any) => {
	dispatch(setInstaInboxCommentsEnablement(shouldEnable));
};

export const getGeoLocationData = () => (dispatch: any) =>
	onboardingAPI
		.getCityCountry()
		.then((resp) => resp.json())
		.then((resp: IObjProps) => {
			if (!isEmpty(resp)) {
				dispatch(setGeoLocationData(resp));
			}
		});

export const getJouneryPhaseData = () => (dispatch: any, getState: () => RootState) => {
	const onApiError = () => dispatch(setJounrneyPhaseError());
	const isWAAcceleratorEnabled = getState().User.botAdmin.data.enable_whatsapp_accelerator;
	const botRef = getState().Bot.data.bot_ref;
	const botKey = getState().Bot.data.bot_key;

	dispatch(setJounrneyPhaseLoading());
	const params = {
		targetApi: 'getJourneyPhaseData',
		isWAAcceleratorEnabled,
		...(botKey && { botKey }),
		...(botRef && { botRef }),
	};

	return API.getJourneyPhaseData(params)
		.then((resp: IObjProps) => {
			if (isValidResponseObject(resp)) {
				dispatch(setJounrneyPhaseData(resp.data.responseObject));

				return resp;
			}
			onApiError();
		}, onApiError)
		.catch(onApiError);
};

export const updateJourneyPhaseData = (data: IObjProps) => (dispatch: any) => {
	const onApiError = () => dispatch(setJounrneyPhaseError());

	dispatch(setJounrneyPhaseLoading());

	return API.updateJourneyPhaseData(data)
		.then((resp: IObjProps) => {
			if (isValidResponseObject(resp)) {
				dispatch(setJounrneyPhaseData(resp.data.responseObject));
			} else {
				onApiError();
			}
		}, onApiError)
		.catch(onApiError);
};

export const updateFirstMessageJourneyData = () => (dispatch: any, getState: () => RootState) => {
	const { STARTED_TRIAL_CONVERSATION } = WA_ACCELERATOR_JOURNEY_PHASE;
	const journeyData = getState().User.journey.data;
	const { email } = getState().User.botAdmin.data;

	if (journeyData[STARTED_TRIAL_CONVERSATION]) {
		return;
	}

	const updateSalesforceObject = () => {
		const data = {
			OPPORTUNITY: {
				pqlStage: journeyData[WA_ACCELERATOR_JOURNEY_PHASE.FIRST_BROADCAST_SENT]
					? WA_ACCELERATOR_PQL_STAGES.BROADCAST_AND_LIVECHAT
					: WA_ACCELERATOR_PQL_STAGES.LIVECHAT_INITIATED,
			},
		};
		onboardingAPI.updateSalesforceObject(data, email);
	};

	dispatch(
		updateJourneyPhaseData({
			[STARTED_TRIAL_CONVERSATION]: true,
		})
	).then(() => {
		updateSalesforceObject();
	});
};

export const updateFirstBroadcastJourneyData = () => (dispatch: any, getState: () => RootState) => {
	const { FIRST_BROADCAST_SENT, VISITED_BROADCAST_HISTORY } = WA_ACCELERATOR_JOURNEY_PHASE;
	const { data, loading, error } = getState().User.journey;

	if (!loading && !error && data[FIRST_BROADCAST_SENT] && !data[VISITED_BROADCAST_HISTORY]) {
		dispatch(
			updateJourneyPhaseData({
				[VISITED_BROADCAST_HISTORY]: true,
			})
		);
	}
};

export const updateAgentAnalyticsVisitJourneyData = () => (dispatch: any, getState: () => RootState) => {
	const { STARTED_TRIAL_CONVERSATION, VISITED_AGENT_PERFORMANCE } = WA_ACCELERATOR_JOURNEY_PHASE;
	const { data, loading, error } = getState().User.journey;

	if (!loading && !error && data[STARTED_TRIAL_CONVERSATION] && !data[VISITED_AGENT_PERFORMANCE]) {
		dispatch(
			updateJourneyPhaseData({
				[VISITED_AGENT_PERFORMANCE]: true,
			})
		);
	}
};

export const updateTemplateLibraryVisitJourneyData = () => (dispatch: any, getState: () => RootState) => {
	const { VISITED_TEMPLATE_LIBRARY } = WA_ACCELERATOR_JOURNEY_PHASE;
	const { data, loading, error } = getState().User.journey;

	if (!loading && !error && !data[VISITED_TEMPLATE_LIBRARY]) {
		dispatch(
			updateJourneyPhaseData({
				[VISITED_TEMPLATE_LIBRARY]: true,
			})
		);
	}
};

export const updateQuickGuideJourneyData = () => (dispatch: any, getState: () => RootState) => {
	const { COMPLETED_QUICK_GUIDE_JOURNEY } = WA_ACCELERATOR_JOURNEY_PHASE;
	const { data, loading, error } = getState().User.journey;

	if (!loading && !error && !data[COMPLETED_QUICK_GUIDE_JOURNEY]) {
		dispatch(
			updateJourneyPhaseData({
				[COMPLETED_QUICK_GUIDE_JOURNEY]: true,
			})
		);
	}
};

export const updateMessageListJourneyData = () => (dispatch: any, getState: () => RootState) => {
	const { COMPLETED_MESSAGE_LIST_JOURNEY } = WA_ACCELERATOR_JOURNEY_PHASE;
	const { data, loading, error } = getState().User.journey;

	if (!loading && !error && !data[COMPLETED_MESSAGE_LIST_JOURNEY]) {
		dispatch(
			updateJourneyPhaseData({
				[COMPLETED_MESSAGE_LIST_JOURNEY]: true,
			})
		);
	}
};

export const updateCapturedLeadsJourneyData = () => (dispatch: any, getState: () => RootState) => {
	const { CAPTURED_LEADS } = WA_ACCELERATOR_JOURNEY_PHASE;
	const { data, loading, error } = getState().User.journey;

	if (!loading && !error && !data[CAPTURED_LEADS]) {
		dispatch(
			updateJourneyPhaseData({
				[CAPTURED_LEADS]: true,
			})
		);
	}
};
