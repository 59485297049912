import { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { KIND, SIZE } from 'baseui/button';
import { Tab } from 'baseui/tabs';
import { FILL } from 'baseui/tabs-motion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Layout, { LayoutContent, LayoutHeader } from 'components/Common/Layout';
import CreateBroadcastBtn from 'components/shared/CreateBroadcastBtn/CreateBroadcastBtn';
import CreateClampaignBtn from 'components/shared/CreateCampaignBtn/CreateCampaignBtn';
import CreateClickToWAAdBtn from 'components/shared/CreateClickToWAAdBtn/CreateClickToWAAdBtn';
import ENGTButton from 'components/UI/ENGTButton/ENGTButton';
import ENGTHeader from 'components/UI/ENGTHeader/ENGTHeader';
import ENGTTabs, { tabsDefaultOverride } from 'components/UI/ENGTTabs/ENGTTabs';
import PageHeader, { PageSubHeading } from 'components/UI/PageHeader/PageHeader';

import { renderWhatsappTemplateNote } from 'shared/consts/broadcast/constants';
import { writeToLs } from 'shared/consts/localStorageConsts';
import { IObjProps } from 'shared/consts/types';
import { CHANNELS, EXECUTION_TAB, LOCAL_STORAGE_VALUES, MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES } from 'shared/enum';
import useAccountStatusHook from 'shared/hooks/feature/useAccountStatusHook';
import useResponsiveSize from 'shared/hooks/useResponsiveSize';

import {
	isCTWAEntitlementEnabledSelector,
	isSandboxConfiguredSelector,
	isSandboxEntitlementEnabled,
	isWhatsAppConfiguredSelector,
	marketingCampaignsAnalyticsSelector,
} from 'store/App/User/selectors';
import { configuredChannelsForBot } from 'store/Bot/selectors';
import { isEngatiBrandSelector } from 'store/Users/selectors';

import { ThreeColumnRouteLinks } from 'router/links/ThreeColumnRouteLinks';

import BroadcastHistory from '../BroadcastHistory/BroadcastHistory';
import useBroadcastListHook from '../BroadcastHistory/hooks/useBroadcastListHook';
import CampaignsV2 from '../Campaigns/CampaignsV2';
import AdDetailsTab from '../ClickToWhatsappAds/components/AdDetailsTab';

const Execution = ({ tab }: { tab: EXECUTION_TAB }) => {
	const { t } = useTranslation(['common', 'pages']);
	const { isSmallDesktopScreen } = useResponsiveSize();
	const [css, theme]: [any, any] = useStyletron();

	const [fetchAccountStatus] = useAccountStatusHook({
		publishedPath: 'published',
	});

	const broadcastHelpers = useBroadcastListHook();

	const isCTWAEntitlementEnabled = useSelector(isCTWAEntitlementEnabledSelector);
	const isWhatsAppConfigured = useSelector(isWhatsAppConfiguredSelector);
	const configuredChannels = useSelector(configuredChannelsForBot);
	const isSandboxEnabled = useSelector(isSandboxEntitlementEnabled);
	const isWhatsappSandboxConfigured = useSelector(isSandboxConfiguredSelector);
	const isEngatiBrand = useSelector(isEngatiBrandSelector);
	const marketingCampaignsAnalyticsType = useSelector(marketingCampaignsAnalyticsSelector);

	const [campaignModal, toggleCampaignModal] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [hasDialog360Provider, setHasDialog360Provider] = useState<boolean>(false);
	const [selectedCampaign, setSelectedCampaign] = useState<IObjProps>({});
	const navigate = useNavigate();

	useEffect(() => {
		broadcastHelpers.getWhatsappConfigration(setHasDialog360Provider);
	}, []);

	const showSandboxButton =
		!configuredChannels.includes(CHANNELS.WHATSAPP) &&
		hasDialog360Provider &&
		isSandboxEnabled &&
		!isWhatsappSandboxConfigured;

	const tabOptions = [
		t(`pages:marketingCampaigns.execution.broadcast`),
		t(`pages:marketingCampaigns.execution.CTWA`),
		t(`pages:marketingCampaigns.execution.campaignAutomation`),
	];

	const broadcastTab = () => (
		<Tab title={tabOptions[0]} key={EXECUTION_TAB.BROADCAST}>
			<BroadcastHistory />
		</Tab>
	);

	const ctwaTab = () => (
		<Tab title={tabOptions[1]} key={EXECUTION_TAB.CTWA}>
			<AdDetailsTab />
		</Tab>
	);

	const campaignAutomationTab = () => (
		<Tab title={tabOptions[2]} key={EXECUTION_TAB.CAMPAIGN_AUTOMATION}>
			<CampaignsV2
				campaignModal={campaignModal}
				toggleCampaignModal={toggleCampaignModal}
				isEdit={isEdit}
				setIsEdit={setIsEdit}
				selectedCampaign={selectedCampaign}
				setSelectedCampaign={setSelectedCampaign}
			/>
		</Tab>
	);

	const tabElements = () =>
		isCTWAEntitlementEnabled &&
		isWhatsAppConfigured &&
		marketingCampaignsAnalyticsType !== MARKETING_CAMPAIGNS_ENTITLEMENT_VALUES.PARTNER
			? [broadcastTab, ctwaTab, campaignAutomationTab]
			: [broadcastTab, campaignAutomationTab];

	const tabChangedHandler = (selectedTab: string) => {
		if (selectedTab !== tab) {
			navigate(`${ThreeColumnRouteLinks.executionLink}/${selectedTab}`);
		}
	};

	const createSandbox = () =>
		showSandboxButton ? (
			<ENGTButton
				onClick={() => {
					writeToLs(LOCAL_STORAGE_VALUES.SET_DIALOG360_ENABLED, 'true');
					writeToLs(LOCAL_STORAGE_VALUES.SET_SANDBOX_ENABLED, 'true');
					navigate(ThreeColumnRouteLinks.configureDepWhatsappLink);
				}}
				label={t('pages:landing.overview.accountNotConnected.connectToSandbox')}
				size={SIZE.compact}
				kind={KIND.primary}
				className={css({
					padding: '0.5rem',
					alignSelf: 'start',
				})}
			/>
		) : (
			<></>
		);

	const executionPageButtons = (
		<div className={css({ display: 'flex', gap: '1rem', alignItems: 'center', flexWrap: 'wrap' })}>
			{tab === EXECUTION_TAB.BROADCAST && (
				<>
					{createSandbox()}
					<CreateBroadcastBtn onClick={fetchAccountStatus} />
				</>
			)}
			{tab === EXECUTION_TAB.CTWA && <CreateClickToWAAdBtn />}
			{tab === EXECUTION_TAB.CAMPAIGN_AUTOMATION && (
				<CreateClampaignBtn
					onClick={() => {
						setSelectedCampaign({});
						toggleCampaignModal(true);
						setIsEdit(false);
					}}
				/>
			)}
		</div>
	);

	const executionPageHeader = () => (
		<>
			<Block
				display='flex'
				justifyContent={['flex-end', 'flex-end', 'space-between']}
				alignItems='flex-end'
				flexWrap
				gridGap={['0rem', '0rem', '0rem', '2rem']}
			>
				<ENGTHeader>
					<PageHeader heading={t('pages:marketingCampaigns.execution.execution')} />
				</ENGTHeader>
				<Block display='flex' gridGap='1rem' marginRight={isSmallDesktopScreen ? '0.438rem' : '0rem'} flexWrap>
					{executionPageButtons}
				</Block>
			</Block>
			{isWhatsAppConfigured && (
				<PageSubHeading
					subHeading={renderWhatsappTemplateNote(isEngatiBrand, t)}
					cssOverrides={{
						marginBottom: '1rem',
					}}
				/>
			)}
		</>
	);

	return (
		<Layout>
			<LayoutHeader>{executionPageHeader()}</LayoutHeader>
			<LayoutContent>
				<ENGTTabs
					activeTab={tab}
					fill={FILL.intrinsic}
					onChange={tabChangedHandler}
					tabOverrides={tabsDefaultOverride({
						Tab: {
							style: {
								maxWidth: '13rem',
							},
						},
						TabPanel: {
							style: {
								width: '100%',
							},
						},
						...(isSmallDesktopScreen && {
							TabList: {
								style: {
									marginBottom: '0.5rem',
								},
							},
						}),
					})}
				>
					{tabElements().map((tab: Function) => tab())}
				</ENGTTabs>
			</LayoutContent>
		</Layout>
	);
};

export default Execution;
